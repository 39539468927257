import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { PopupModule } from '@ciri/ngx-popup';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile/profile.component';

import {
  ProgressbarModule,
  ProgressbarConfig,
} from 'ngx-bootstrap/progressbar';
import { VerifyProductComponent } from './verify-product/verify-product.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { KidProfileComponent } from './kid-profile/kid-profile.component';
import { AddressComponent } from './address/address.component';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { MyPointsComponent } from './my-points/my-points.component';
import { LevelMemberComponent } from './level-member/level-member.component';
import { ProcessValidationComponent } from './process-validation/process-validation.component';
import { BlockerComponent } from './blocker/blocker.component';
import { RedeemComponent } from './redeem/redeem.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ERedeemComponent } from './e-redeem/e-redeem.component';
import { ClipboardModule } from 'ngx-clipboard';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    ProfileComponent,
    VerifyProductComponent,
    EditProfileComponent,
    MyProfileComponent,
    KidProfileComponent,
    AddressComponent,
    LevelMemberComponent,
    MyPointsComponent,
    ProcessValidationComponent,
    BlockerComponent,
    RedeemComponent,
    ERedeemComponent,
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedModule,
    ProgressbarModule,
    PopupModule,
    NgxPaginationModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    // ProgressbarConfig,
    ModalModule.forRoot(),
    ClipboardModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfileModule { }
