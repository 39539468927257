<app-header></app-header>
<section class="globalContainer pt70 px15 termsPage">
    <app-back text="Kembali"></app-back>

    <div>
    <img class="img-fluid" [src]="termsHeading?.associatedfile">
    </div>
    <!-- <h3 class="primaryTitle">{{termsHeading?.heading}}</h3>
    <p class="blackText" [innerHTML] = "termsHeading?.contentvalue | safe: 'html'"></p> -->
    <!-- <section class="faqContainer termsContainer mb-5 mt-4">
    <div class="questionsBlock mt-2">
        <div class="accordion" id="accordionExample">
            <div class="card mb-2" [hidden]="!faq?.orderid" *ngFor="let faq of termsData; let i = index">
                <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block  collapsed collapseBtn" type="button"
                            data-toggle="collapse" [attr.data-target]="'#collapse'+faq?.orderid"  aria-expanded="false"
                            aria-controls="collapseOne">
                            {{faq?.questions}}
                        </button>
                    </h2>
                </div>

                <div id="collapse{{faq?.orderid}}" class="collapse" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body">
                        <div [innerHTML] = "faq?.answers | safe: 'html'"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<div class="privacy-policy-main termsPage">
    <div class="privacyContent" [innerHTML]="termsData?.termscontent | safe: 'html'">
    </div>
</div>
<div class="mt-4 pb-5"></div>




</section>
<footer class="">
    <app-footer></app-footer>
</footer>
