import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqPrivacyTncRoutingModule } from './faq-privacy-tnc-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoyaltyComponent } from './loyalty/loyalty.component';
import { SafePipeModule } from 'safe-pipe';
import { ContactComponent } from './contact/contact.component';



@NgModule({
  declarations: [FaqComponent, TermsConditionsComponent, PrivacyPolicyComponent, LoyaltyComponent, ContactComponent],
  imports: [
    CommonModule,
    FaqPrivacyTncRoutingModule,
    SharedModule,
    SafePipeModule
  ]
})
export class FaqPrivacyTncModule { }
