<app-header></app-header>
<section class="globalContainer _h100vh pt70 position-relative">
  <section class="otpContainer px15">
    <img
      class="img-fluid gifImg"
      src="../../../assets/images/otpverifyhand.gif"
    />
    <!-- <h5>{{ "OTP.VERIFICATION_CODE" | translate }}</h5> -->
    <h5>Verifikasi kode OTP</h5>
    <div class="row justify-content-center">
      <div class="col-12">
        <!-- <p class="textLightGrey otpContent">{{'OTP.ENTER_FOUR_DIGIT_CODE' | translate}}</p> -->
        <p class="textLightGrey otpContent">
          Masukkan 4 digit kode verifikasi yang kami SMS ke nomer HP-mu +62{{
            this.mobileno
          }}. Anda hanya punya 3 kali kesempatan untuk verifikasi per hari.
        </p>
      </div>
    </div>
    <form [formGroup]="registerOtpForm" (ngSubmit)="onSubmitRegOtpForm()">
      <div *ngIf="lock" class="lockfield pt-2 pb-2 text-center">
        <p class="text-white mb-0">
          <img class="img-fluid" src="../../../assets/images/lock.png" />
          23:12:59
        </p>
      </div>
      <ng-otp-input
        *ngIf="!lock"
        (onInputChange)="onOtpChange($event)"
        [config]="{ length: 6, placeholder: '0', allowNumbersOnly: true }"
        formControlName="otp"
        [ngClass]="{
          'is-invalid':
            (submitRegOtpForm && (otp == undefined || otp.length < 6)) ||
            invalidOtp
        }"
        #ngOtpInput
      >
      </ng-otp-input>

      <div
        *ngIf="submitRegOtpForm && regOtpFormVal.otp.errors.required"
        class="text-danger pl-0 mt-2"
      >
        <div *ngIf="!activeClassButton">
          {{ "OTP.OTP_REQUIRED" | translate }}
        </div>
      </div>
      <div *ngIf="invalidOtp" class="text-danger pl-0 fs12">
        <div>Kode yang Anda masukkan salah</div>
      </div>
      <div class="text-danger pl-0 mt-2 fs12" *ngIf="lock">
        Maaf, Anda telah melebihi batas kesempatan untuk verifikasi. Mohon
        tunggu hingga 7x24 jam dan lakukan verifikasi ulang.
      </div>

      <!-- <p *ngIf="!showResendOtp" class="colorGrey mt-4">{{ 'REG_OTP_MODAL.LINK_TEXT_LEFT' | translate }}
                <span class="primaryBlue font-weight-bold" *ngIf="beginTimer">
                    <countdown #cd [config]="{leftTime: 60,format: 'ss',notify: [ 2, 5 ]}"
                        (event)="handleEvent($event)"></countdown> {{ 'REG_OTP_MODAL.LINK_TEXT_RIGHT' | translate }}
                </span>
            </p>

            <p *ngIf="showResendOtp" class="mt-4 underlinedText lightBlue" (click)="resendOTP()" (click)="cd.restart()">
                {{ 'FORGOT_OTP_MODAL.LINK_TEXT' | translate }}
            </p> -->

      <button type="submit" *ngIf="!lock" class="btn btnPurple br10 mt-4">
        submit
      </button>
    </form>

    <div class="bottomContent fs14" *ngIf="!lock">
      <p class="mt-4 mb-0">Belum menerima kode verifikasi?</p>
      <p>
        <span *ngIf="!showResendOtp">
          Kirim ulang kode dalam waktu (<span
            class="contentSmall"
            *ngIf="beginTimer"
          >
            <countdown
              #cd
              [config]="{ leftTime: 60, format: 'mm:ss', notify: [2, 5] }"
              (event)="handleEvent($event)"
            ></countdown> </span
          >)<span
            >( <span> {{ this.count }} </span>)
          </span>

          <br />
        </span>
      </p>

      <a class="resend textPurple" *ngIf="showResendOtp"
        ><span (click)="resendOTP()"> Kirim ulang kode </span>(<span>
          {{ this.count }} </span
        >)
      </a>
    </div>
  </section>
</section>
