import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../../environments/environment';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  editProfileForm: FormGroup;
  imageChangedEvent: any = '';
  containWithinAspectRatio = false;
  canvasRotation = 0;
  @ViewChild('confirmationModal', { static: true }) confirmationModal: ModalDirective;
  transform: ImageTransform = {};
  visible = false;
  fileupload: any;
  isMobillePresent: boolean = false;
  isEmailPresent: boolean = false;
  finalUserImage: any;
  submitted = false;
  customerData: any;
  profileImage: any;
  croppedImage: any = '';
  fileInput;
  showLastQuestion = false;
  registerForm: {
    root: {
      customer: {
        mobile: any;
        email: any;
        firstname: any;
        lastname: any;
        custom_fields: { field: { name: string; value: any }[] };
        extended_fields: { field: { name: string; value: any }[] };
      }[];
    };
  };
  showCropper: boolean = false;
  dob: any;
  profileUpdate: boolean = false;
  maxDate: any;
  minDate: any
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private localeService: BsLocaleService, 
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 })
    enGbLocale.invalidDate = 'Pilih tanggal lahir Anda';
    defineLocale('custom locale', enGbLocale);
    this.localeService.use('custom locale');
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.maxDate = moment();
    this.maxDate = new Date(this.maxDate)
    this.minDate = moment().subtract(21, 'years')
    this.minDate = new Date(this.minDate);
    this.editProfileForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^((\\+63-?)|0)?[0-9]{9,12}$'),
        ],
      ],
      email: ['', Validators.required],
      dob: ['', [Validators.required, this.ValidateDOB.bind(this)]],
      product_tried: [''],
      producttime_tried: [''],
    });
    this.getCustomer();
    console.log(this.finalUserImage);
  }
  get editProfileFormVal() {
    return this.editProfileForm.controls;
  }

  getCustomer() {
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    let getcustresp;
    this.spinner.show();
    this.apiService
      .getCapCustomer(getMobile, getAuthKey)
      .pipe(first())
      .subscribe((res) => {
        let temp = {
          tried: '',
          time_tried: '',
        };
        getcustresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter(filt => {
            return filt.name == 'block_count'
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            sessionStorage.clear();
            this.router.navigate(['/blocker']);
          }
          this.customerData = res['customers']['customer'][0];
          if (this.customerData.mobile) {
            this.isMobillePresent = true;
          }
          if (this.customerData.email) {
            this.isEmailPresent = true;
          }
          this.customerData.custom_fields.field.map((ele) => {
            if (ele.name == 'product_tried') {
              temp['tried'] = ele.value;
            }
            if (ele.name == 'producttime_tried') {
              temp['time_tried'] = ele.value;
            }
          });
          this.customerData.extended_fields.field.forEach((ele) => {
            if (ele.name == "dob") {
              this.dob = moment(ele.value).format('DD/MM/YYYY');
            }
          });


          this.editProfileForm.patchValue({
            firstname: this.customerData?.firstname,
            lastname: this.customerData?.lastname,
            phoneNumber: this.customerData?.mobile.substring(env?.substring),
            //phoneNumber: this.customerData?.mobile,
            email: this.customerData?.email,
            dob: this.dob,
            product_tried: temp.tried,
            producttime_tried: temp.time_tried,
          });
          let profilePic = res['customers']['customer'][0].custom_fields.field;
          let image = profilePic.filter((res) => res.name == 'profileimage');
          this.profileImage = image[0]?.value;
          console.log("customer data: ", this.customerData);
          this.spinner.show();
          this.apiService
            .getSavedImageFinal(getMobile, getAuthKey, this.profileImage)
            .pipe(first())
            .subscribe((res: any) => {
              this.spinner.hide();
              this.finalUserImage = res.file_path;

              this.visible = false;
            }, err=>{
              this.spinner.hide();
              this.bsService.notifyError(err);
            });
        }
      },err=>{
        this.spinner.hide();
        this.bsService.notifyError(err, getAuthKey);
      });

  }

  cancelledSuccessModal() {
    this.confirmationModal.hide();
  }

  ValidateDOB(control: AbstractControl): { [key: string]: any } | null {
    var birthday = moment(control.value, "DD.MM.YYYY")
    var age = moment.duration(moment().diff(birthday)).asYears();
    if (age < 18) {
      return { 'minimumAgeIs18': true };
    }
    return null;
  }

  confirmSuccessModal() {
    this.submitted = true;
    this.confirmationModal.hide();
    if (this.editProfileForm.invalid) {
      return;
    } else {
      let userData = this.editProfileForm.value;
      console.log("userData: ", userData);
      let filledFormValues = this.getuserForm(userData);

      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      this.spinner.show();
      if (
        this.customerData?.email !==
        filledFormValues?.root?.customer[0]?.email &&
        filledFormValues?.root?.customer[0]?.email !== ''
      ) {
        // this.spinner.show();
        // this.apiService
        //   .checkExistingEmail(encodeURIComponent(userData.email))
        //   .pipe(first())
        //   .subscribe((res: any) => {
        //     if (res.result.statusCode == 200) {
        //       this.spinner.hide();
        //       if (res.result.message === 'User not found') {
        this.spinner.show();
        let updateEmail = {
          root: {
            customer: [
              {
                identifier: 'email',
                old_value: this.customerData?.email,
                new_value: filledFormValues?.root?.customer[0]?.email,
              },
            ],
          },
        };
        let updateidentyresp;
        this.apiService
          .updateCapCustomerIdentity(updateEmail, getMobile, token)
          .pipe(first())
          .subscribe(
            (res) => {
              updateidentyresp = res;
              console.log(res, '=== updated email ===');
              this.spinner.hide();
            },
            (err) => {
              console.log(err, 'err===');
              this.spinner.hide();
              this.bsService.notifyError(err, updateEmail);
            }
          );
        //     }

        //  }


        // });
      }
      this.spinner.show();
      let updatecustresp;
      this.apiService
        .updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            updatecustresp = res;
            this.spinner.hide();
            if (res['status'].code == 200) {
              // this.apiService.showToaster('Profil berhasil diperbarui')
              this.profileUpdate = true;
              setTimeout(() => {
                this.profileUpdate = false;
              }, 5000)
            }
          },
          (err) => {
            console.log('ss');
            this.spinner.hide();
            this.bsService.notifyError(err, filledFormValues);
            console.log(err, '=== customer update error =======');
          }
        );
    }
  }

  getuserForm(userData: any) {
    return (this.registerForm = {
      root: {
        customer: [
          {
            mobile: userData.phoneNumber,
            email: userData.email,
            firstname: userData.firstname,
            lastname: userData.lastname,
            custom_fields: {
              field: [
                {
                  name: 'house_number',
                  value: userData.houseNumber,
                },
                {
                  name: 'swine',
                  value: userData.swine,
                },
                {
                  name: 'building_or_village',
                  value: userData.buildingVillage,
                },
                {
                  name: 'alley',
                  value: userData.alley,
                },
                {
                  name: 'road',
                  value: userData.road,
                },
                {
                  name: 'postal_code',
                  value: userData.postalCode,
                },
                {
                  name: 'province',
                  value: userData.province,
                },
                {
                  name: 'county',
                  value: userData.country,
                },
                {
                  name: 'district',
                  value: userData.district,
                },
                {
                  name: 'product_tried',
                  value: userData.product_tried,
                },
                {
                  name: 'producttime_tried',
                  value: userData.producttime_tried,
                }
              ],
            },
            extended_fields: {
              field: [
                {
                  name: 'dob',
                  value: moment(userData.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
                  //"value": userData?.DOB ? moment(userData.DOB, "DD-MM-YYYY").format("YYYY-MM-DD") : '',
                }
              ]
            },

          },
        ],
      },
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      this.visible = true;
      this.fileupload = event.target.files[0];
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event, 'cropped');
    this.croppedImage = event.base64;
    this.fileInput = base64ToFile(this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
    this.visible = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  uploadImage() {
    this.visible = false;
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');

    const imageData = new FormData();
    imageData.append('image', this.fileInput);
    imageData.append('mobile', getMobile);
    imageData.append('cap_device_id', env.DEVICE_ID);

    this.spinner.show();
    let custimgupload;
    this.apiService
      .customerImageUpload(imageData, getAuthKey)
      .pipe(first())
      .subscribe(
        (res: any) => {
          custimgupload = res;
          this.spinner.hide();
          console.log(res, 'image upload');
          let uniqueId = res.result.image_id;
          let extension = res.result.image_extension;
          console.log(uniqueId, 'uniqueId', extension, 'extension')
          this.apiService
            .getSavedImage(getMobile, getAuthKey, uniqueId, extension)
            .pipe(first())
            .subscribe((res: any) => {
              this.spinner.hide();
              console.log(res.file_path, 'image get');
              this.finalUserImage = res.file_path;
              this.visible = false;
            }, err=>{
              this.spinner.hide();
              this.bsService.notifyError(err, getAuthKey);
            });
        },
        (err) => {
          if (err) {
            this.spinner.hide();
            this.bsService.notifyError(err, imageData);
          }
        }
      );
  }

  onSubmitEditProfileForm() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      window.scrollTo({ top: 0 })
      return;
    } else {
      this.confirmationModal.show()
      window.scrollTo({ top: 0 })
    }

  }
}
