<app-header></app-header>

<section class="globalContainer pt70 px15">
  <app-back text="Kembali"></app-back>

  <div class="tncContainer infoGameContainer">
    <button class="btn mb20 br10 fW600 btnBgImg" type="button">
      <span>Daftar Hadiah</span>
    </button>

    <section class="image-container">
      <div class="row">
        <div class="col-5">
          <img src="../../../assets/images/info-game/voucher-75-alfamart.png" />
        </div>
        <div class="col-7 d-flex align-items-center">
          <p>E - Voucher Alfamart <span>Rp. 75.000</span></p>
        </div>
      </div>

      <div class="row">
        <div class="col-5">
          <img src="../../../assets/images/info-game/voucher-250-blibli.png" />
        </div>
        <div class="col-7 d-flex align-items-center">
          <p>E - Voucher Blibli <span>Rp. 250.000</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <img
            src="../../../assets/images/info-game/voucher-150-tokopedia.png"
          />
        </div>
        <div class="col-7 d-flex align-items-center">
          <p>E - Voucher Tokopedia <span>Rp. 150.000</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <img
            src="../../../assets/images/info-game/voucher-250-tokopedia.png"
          />
        </div>
        <div class="col-7 d-flex align-items-center">
          <p>E - Voucher Tokopedia <span>Rp. 250.000</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <img src="../../../assets/images/info-game/voucher-50-blibli.png" />
        </div>
        <div class="col-7 d-flex align-items-center">
          <p>E - Voucher Blibli <span>Rp. 50.000</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <img
            src="../../../assets/images/info-game/voucher-200-grab-food.png"
          />
        </div>
        <div class="col-7 d-flex align-items-center">
          <p>E - Voucher GrabFood <span>Rp. 200.000</span></p>
        </div>
      </div>
    </section>
  </div>
</section>

<app-footer></app-footer>
