<app-header></app-header>
<section class="globalContainer contactContainer pt70 px15 mb-5">
    <app-back text="Kembali"></app-back>
    <div class="contact-page">
        <div class="text-center">
           <h1 class="contact-title">Hubungi Kami</h1>
        </div>
        <div class="contact-links my-4">
            <div class="text-center">
                <!-- <a href="https://api.whatsapp.com/send?phone=628119188369" target="_blank"><i class="mdi mdi-whatsapp"></i> whatsapp</a>
                 -->
                 <div class="contact-card text-center">
                    <img src="../../../assets/images/Whatsapp.png" />
                    <div class="contact-card-inner">
                        <p>Hubungi Customer Service Kami melalui Whatsapp</p>
                        <a href="https://api.whatsapp.com/send?phone=628119188369" target="_blank">Pergi ke Whatsapp</a>
                    </div>
                 </div>
            </div>
            <div class="contact-card text-center">
                <img src="../../../assets/images/abb-icon.png" />
                <div class="contact-card-inner">
                    <p>Hubungi Abbott Careline</p>
                    <a href="https://api.whatsapp.com/send?phone=628119188369" target="_blank">Telepon Sekarang</a>
                </div>
            </div>
        </div>
        <form class="contactForm">
            <div class="field form-group ">
                <label for="parent_name">Nama Anda</label>
                <input type="text" name="name" placeholder="Asri Asalwa" class="form-control" required/>
            </div>
            <!-- <div class="field form-group ">
                <label for="child_name">Nama Anak</label>
                <input type="text" name="child_name" class="form-control" required/>
            </div> -->
            <!-- <div class="field form-group ">
                <label for="dob">Tanggal Lahir</label>
                <input type="text" name="dob" class="form-control" placeholder="Tanggal Lahir" required />
            </div> -->
            <div class="field form-group ">
                <label for="phone">Nomor Handphone</label>
                <input type="tel" name="phone" class="form-control" placeholder="08xx-xxxx-xxxx"
                 pattern="\+?([ -]?\d+)+|\(\d+\)([ -]\d+)" required />
            </div>
            <div class="field form-group ">
                <label for="email">Email</label>
                <input type="email" name="email" class="form-control" placeholder="Asalwa12@gmail.com" required />
            </div>
            <!-- <div class="field form-group ">
                <label for="product">Produk yang dipakai</label>
                <select name="product" class="form-control" required>
                    <option>Pilih produk</option>
                    <option>Pediasure</option>
                    <option>Pediacomplete</option>
                </select>
            </div> -->
            <div class="field form-group ">
                <label for="comments">Tuliskan pertanyaan Anda</label>
                <textarea name="comments" placeholder="Apa yang ingin Anda tanyakan?" required></textarea>
            </div>
            <div class="field form-group mt-4 mb-4">
                <button type="submit" class="btn btnPurple w-100 btn-50">Kirim</button>
            </div>
        </form>
    </div>

</section>

<app-footer></app-footer>