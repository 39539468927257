import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressComponent } from './address/address.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { KidProfileComponent } from './kid-profile/kid-profile.component';
import { MyPointsComponent } from './my-points/my-points.component';
import { LevelMemberComponent } from './level-member/level-member.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ProfileComponent } from './profile/profile.component';
import { VerifyProductComponent } from './verify-product/verify-product.component';
import { ProcessValidationComponent } from './process-validation/process-validation.component';
import { BlockerComponent } from './blocker/blocker.component';
import { RedeemComponent } from './redeem/redeem.component';
import { ERedeemComponent } from './e-redeem/e-redeem.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent
  },
  {
    path: 'verify-product',
    component: VerifyProductComponent
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent
  },
  {
    path: 'my-profile',
    component: MyProfileComponent
  },
  {
    path: 'kid-profile',
    component: KidProfileComponent
  },
  {
    path: 'address',
    component: AddressComponent
  },
  {
    path: 'my-points',
    component: MyPointsComponent
  },
  {
    path: 'level-member',
    component: LevelMemberComponent
  },
  {
    path: 'process-validation',
    component: ProcessValidationComponent
  },
  {
    path: 'blocker',
    component: BlockerComponent
  },
  {
    path: 'redeem/:data',
    component: RedeemComponent
  },
  {
    path: 'e-redeem',
    component: ERedeemComponent
  },
  {
    path: 'e-redeem/:data',
    component: ERedeemComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
