<app-header></app-header>

<section class="globalContainer pt70 px15">
  <app-back text="Kembali"></app-back>

  <div class="tncContainer">
    <button class="btn mb20 br10 fW600 btnBgImg" type="button">
      <span>Tentang games</span>
    </button>

    <p class="fS14">
      Mainkan games dengan menggunakan 300 poin kamu untuk bisa dapatkan
      kesempatan menangkan hadiah!
    </p>

    <button class="btn mb20 br10 fW600 btnBgImg" type="button">
      <span>Syarat dan Ketentuan</span>
    </button>

    <div class="fS14 mb70">
      <ol class="pl20">
        <li>Dibutuhkan 300 Poin untuk bermain “Spin of the wheel”</li>
        <li>
          Hanya pengguna yang telah melengkapi data profile yang bisa bermain
        </li>
        <li>Spin of the wheel bersifat randomly</li>
        <li>Anda bisa terus bermain selama poin Anda masih mencukupinya</li>
      </ol>
    </div>
  </div>
</section>

<app-footer></app-footer>
