import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.scss']
})
export class TNCComponent implements OnInit {

  constructor() {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
  }

}
