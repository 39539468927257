<div
  class="
    globalContainer
    _h100vh
    d-flex
    align-items-center
    justify-content-center
  "
  *ngIf="showData"
>
  <Button
    class="btn btnPurple"
    (click)="onClickScanner()"
    >{{ "HOME.SCAN" | translate }}</Button
  >
  <!-- <Button class="btn btnPurple" (click)="onLoginBtn()" [hidden]="showScanner">{{
    "HOME.PRODINFO" | translate
  }}</Button> -->
</div>

<div [hidden]="!showScanner">
  <div class="video-row" fxFlex="100">
      <video width="100%" class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display:none">
      <canvas class="video" id="canvas" #canvas></canvas>
  </div>
  <button class="btn d-block m-auto btnPurple" (click)="closeScanner()">back</button>
</div>
