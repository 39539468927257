import { Component, OnInit, ViewChild, } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../environments/environment';
import { ApiService } from 'src/app/service/api.service';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { SocialAuthService, GoogleLoginProvider, SocialUser, FacebookLoginProvider } from 'angularx-social-login';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { get } from 'http';
import { BugsnagService } from 'src/app/service/bugsnag.service';
declare var $: any;
@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss'],
})
export class LoginSignupComponent implements OnInit {
  loginForm: FormGroup;
  mobNumFormGroup: FormGroup;
  submittedLoginForm = false;
  signUpForm: FormGroup;
  submitOtpForm: boolean;
  dob: any;
  beginTimer: boolean;
  submittedSignUpForm = false;
  prodCategory: any;
  signUpFormData: any;
  showResendOtp: boolean = false;
  regMobnum: any;
  scannedQRCode: any;
  emailExsistError: boolean = false;
  categoryCode: any;
  scanCode: any;
  checkQRCode2: any;
  invalidPassword: boolean = false;
  pointsEarned: any;
  withzeroMob: any;
  entermobNo: any;
  entermobNowithzero: any;
  storedMobNum: any;
  params: any;
  showpassword = false;
  showConfirmPassword = false;
  showSignUpPassword = false;
  showError = false;
  userNotRegistered: boolean = false;
  mobNum: any;
  oAuthData: any;
  isLoggedin: boolean;
  socialUser: any;
  otpForm: FormGroup;
  modalHide: any;
  socialLogin: boolean = false
  isEmailExists: boolean = false;
  @ViewChild('otpModal', { static: true }) otpModal: ModalDirective;
  invalidOtp: boolean;
  otp: any;
  otpLength: any;
  activeClassButton: boolean;
  salesforceData: any;
  sessionId: any;
  mobileNo: any;
  maxDate: any
  minDate: any
  timeLeft: any;
  count: number;
  lock: boolean = false;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  isMobileSalesForce: boolean = false;
  isEmailSalesForce: boolean = false;
  disabledLogin: boolean = false;
  hiddenBtn: boolean = false;
  //@ViewChild('completeProfileDataModel', { static: true })
  // completeProfileDataModel: ModalDirective;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private location: Location,
    private localeService: BsLocaleService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 })

    enGbLocale.invalidDate = 'Pilih tanggal lahir Anda';
    defineLocale('custom locale', enGbLocale);
    this.localeService.use('custom locale');

    this.modalHide = this.route.snapshot.params.data;

    console.log(this.modalHide, 'this.modalHide====')
    translate.addLangs(['en_US', 'my_MH']);
    translate.setDefaultLang('en_US');
    this.params = this.route.snapshot.params.id;
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|my_MH/) ? browserLang : 'en_US');

    //sessionStorage.removeItem('scanCode');
    let scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(scancodeData, scancodeData != null);
    if (scancodeData != null) {
      let iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
      this.prodCategory = scancodeData?.product?.categoryCode;
      //this.categoryCode = iSprintData?.product?.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
      this.scannedQRCode = sessionStorage.getItem('scanCode');
      this.checkQRCode2 = iSprintData?.uaidInfo?.type;
    }
    // this.oAuthData = {
    //   "key": "Frt24WejEG7t28czDw7sUmo24",
    //   "secret": "Ls7QQvRnKeD7TJR8YzjuFsPJD7DSPidyg4jvxlji"
    // }
    this.oAuthData = {
      "key": "maUV5KWkrrL6ae8wvrEn38C3V",
      "secret": "ejBB8tbGUbjdBA3vT71CUFfwekOKZdhKEVoAqHFw"
    }
  }

  ngOnInit(): void {
    this.maxDate = moment();
    this.maxDate = new Date(this.maxDate)
    this.minDate = moment().subtract(21, 'years')
    this.minDate = new Date(this.minDate);

    // $('#pills-tab li:last-child button').tab('show')
    // $('#pills-home').removeClass('show active')
    // $('#pills-profile').addClass('show active')

    // $('#pills-tab li:first-child button').tab('hide')
    //this.openProfileDataCompleteModal();
    sessionStorage.removeItem('userPassword');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('abbottUserId');
    //  sessionStorage.clear();
    this.mobNumFormGroup = this.formBuilder.group({
      mobNum: [
        '',
        [
          Validators.required,
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        ],
      ],
    })
    this.loginForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^((\\+63-?)|0)?[0-9]{9,12}$'),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$'),
        ],
      ],
    });
    this.signUpForm = this.formBuilder.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        dob: ['', [Validators.required, this.ValidateDOB.bind(this)]],
        email: ['', Validators.required],
        mobileNumber: [
          '',
          [
            Validators.required,
            Validators.pattern('^((\\+63-?)|0)?[0-9]{9,12}$'),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$'),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$'),
          ],
        ],
        termsConditions: [false, Validators.requiredTrue],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    })

    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;

      console.log(this.socialUser, 'socialUser====');
      this.isLoggedin = (user != null);
      if (this.socialLogin == true) {
        if (this.isLoggedin == true) {
          let socialUserId = this.socialUser.id
          sessionStorage.setItem('socialUserId', socialUserId);
          this.spinner.show();
          this.signUpForm.patchValue({
            email: this.socialUser.email,
            firstname: this.socialUser.firstName,
            lastname: this.socialUser.lastName,
            password: 'Test@1234',
            confirmPassword: 'Test@1234'
          })
          if (this.signUpForm.value.email) {
            this.isEmailExists = true;
          }
          let socialuser;
          this.apiService.getSocialLoginUser(socialUserId).subscribe((res: any) => {
            this.spinner.hide();
            socialuser = res;
            console.log(res, 'getSocialLoginUser resp');
            console.log(res.result.length)
            if (res.result.length == 0) {
              $('#pills-tab li:last-child button').tab('show')
              $('#pills-home').removeClass('show active')
              $('#pills-profile').addClass('show active')
            } else {
              console.log(res?.result[0]?.mobile, 'social success resp mobile');

              this.mobNum = res?.result[0]?.mobile;
              let oauthres;
              this.apiService.oAuthTokenGen(this.oAuthData).subscribe((res: any) => {
                oauthres = res;
                let accessToken = res.data.accessToken;
                console.log(res, 'o auth resp');
                if (res) {
                  let formData = {
                    identifierType: 'MOBILE',
                    identifierValue: this.mobNum,
                    deviceId: env.DEVICE_ID,
                    brand: env.BRAND,
                  };
                  let tokengetouath;
                  this.spinner.show();
                  this.apiService
                    .tokenGenerateSocialLogin(formData, accessToken)
                    .pipe(first()) //token generate api
                    .subscribe((res: any) => {
                      tokengetouath = res;
                      console.log(res.auth, 'resp tokenGenerateSocialLogin')
                      sessionStorage.setItem('authToken', res.auth.token);
                      sessionStorage.setItem('authKey', res.auth.key);
                      sessionStorage.setItem('mobileNo', this.mobNum);
                      //this.closeCompleteProfileDataModal();
                      // this.apiService.getCapCustomer(this.mobNum, sessionStorage.getItem('authToken')).pipe(first()).subscribe((res) => {

                      //   console.log(res, 'res 1 ======');
                      // })

                      this.router.navigate(['/profile'])

                    }, err => {
                      if (err) {
                        this.spinner.hide();
                        this.bsService.notifyError(err, formData);
                      }
                    });
                }
              },err=>{
                this.spinner.hide();
                this.bsService.notifyError(err, this.oAuthData);
              })

            }
          },err=>{
            this.spinner.hide();
            this.bsService.notifyError(err, socialUserId);
          })
          // this.openProfileDataCompleteModal()
        }
      }

      // if(this.modalHide == 'logout'){
      //   this.closeCompleteProfileDataModal()
      //   setTimeout(()=>{
      //     this.router.navigate(["login-signup"], {replaceUrl:true});
      //   },1000);
      //   // this.location.replaceState('login-signup')
      //   }

    });
  }

  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }
  onlyCharacters(ev) {
    let onlynum = /^[a-zA-Z .]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  getSocialLoginuser() {

  }

  get OtpFormVal() {
    return this.otpForm.controls;
  }

  ValidateDOB(control: AbstractControl): { [key: string]: any } | null {
    var birthday = moment(control.value, "DD.MM.YYYY")
    var age = moment.duration(moment().diff(birthday)).asYears();
    if (age < 18) {
      return { 'minimumAgeIs18': true };
    }
    return null;
  }

  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false
    this.otp = otp;
    console.log('---', otp.length);
    this.otpLength = otp.length
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }

  disabled: boolean = true;
  get f() {
    // if (this.mobNumFormGroup.controls.mobNum.value == ''
    //   || this.mobNumFormGroup.controls.mobNum.value.length != 10) {
    //   this.disabled = true;
    // } else {
    //   this.disabled = false
    // }
    if (this.mobNumFormGroup.controls.mobNum.value == '' ||
      JSON.stringify(this.mobNumFormGroup.controls.mobNum.value).length != 10) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    // console.log(this.mobNumFormGroup.controls.mobNum.value.length != 10);
    // console.log("disabled: ", this.disabled);
    return this.mobNumFormGroup.controls;
  }
  // openProfileDataCompleteModal() {
  //   this.completeProfileDataModel.show();
  // }
  // closeCompleteProfileDataModal() {
  //   this.completeProfileDataModel.hide();
  // }

  loginWithGoogle(): void {
    this.socialLogin = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  signInWithFB(): void {
    this.socialLogin = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  logOut(): void {
    this.socialAuthService.signOut();
  }

  eyepassword() {
    this.showpassword = !this.showpassword;
    this.showSignUpPassword = !this.showSignUpPassword;

  }

  eyeSignUppassword() {
    this.showSignUpPassword = !this.showSignUpPassword;
  }
  eyeSignUpConfirmpassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  checkSalesForce(ev) {
    console.log(ev.target.value, '000');
    let mobNo = ev.target.value;
    this.withzeroMob = ev.target.value;
    mobNo = mobNo.replace(/\b0+/g, '');
    this.entermobNo = mobNo;
    //this.entermobNo = parseInt(this.entermobNo)
    this.entermobNowithzero = ev.target.value;
    this.storedMobNum = this.entermobNowithzero;
    sessionStorage.setItem('storedMobNum', this.storedMobNum);
    console.log(this.entermobNo, 'this.entermobNo');
    console.log(this.entermobNowithzero, 'this.entermobNowithzero');
    this.spinner.show();
    this.mobileNo = mobNo
    this.apiService
      .getSalesForceData(mobNo)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this.count = 3;
            this.salesforceData = res['result'];
            let getMobile = this.mobileNo;
            let getAuthKey = sessionStorage.getItem('authToken');
            let formData = {
              identifierType: 'MOBILE',
              identifierValue: getMobile,
              deviceId: env.DEVICE_ID,
              brand: env.BRAND,
              password: 'test123',
              confirmPassword: 'test123',
            };
            let tokengenresp;
            this.apiService.tokenGenerate(formData).pipe(first()).subscribe(
              (res) => {
                tokengenresp = res;
                if (res['status'].code == 200) {
                  this.sessionId = res['user'].sessionId;
                  sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
                  let otpGenerateData = {
                    identifierType: 'MOBILE',
                    identifierValue: getMobile,
                    deviceId: env.DEVICE_ID,
                    brand: env.BRAND,
                    sessionId: this.sessionId,
                  };
                  this.showResendOtp = false;
                  let otpgenresp;
                  this.apiService.otpGenerate(otpGenerateData).pipe(first())
                    .subscribe(
                      (res) => {
                        otpgenresp = res;
                        if (res['status'].code == 200) {
                          this.spinner.hide();
                          this.otpModal.show();
                          this.beginTimer = true;
                        } else if (res['status'].code == 1534) {
                          this.spinner.hide();
                          this.apiService.showToaster(res['status'].message)
                        }
                      },err=>{
                        this.spinner.hide();
                        this.bsService.notifyError(err, otpGenerateData);
                      }
                    )
                }
              },err=>{
                    this.spinner.hide();
                    this.bsService.notifyError(err, formData);
              }
            )
          }
          // this.spinner.hide();
          // let salesforceData = res['result'];
          // this.signUpForm.patchValue({
          //   mobileNumber: salesforceData ? salesforceData?.Phone : '',
          //   firstname: salesforceData ? salesforceData?.Name : '',
          //   lastname: salesforceData ? salesforceData?.LastName : '',
          //   email: salesforceData ? salesforceData?.Email : '',
          // });
          // if (salesforceData?.Phone) {
          //   //this.registerForm.controls["mobileNumber"].disable();
          // }
          // this.signUpForm.controls['name']?.enable();
          // this.signUpForm.controls['email']?.enable();
        },
        (err) => {
          this.spinner.hide();
          this.bsService.notifyError(err, {mobNo});
          console.log(err, 'err--');
          this.signUpForm.controls['name']?.enable();
          this.signUpForm.controls['email']?.enable();
        }
      );
  }

  onClickCancel() {
    this.otpModal.hide()
  }

  onSubmitOtpForm() {
    this.submitOtpForm = true;
   
    if (this.otpLength != 6) {
      return;
    } else {
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": this.mobileNo,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId,
        "otp": this.otp
      }
      let otpValres;
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe(res => {
          otpValres = res;
          if (res['status'].code == 200) {
            this.otpModal.hide();
            this.beginTimer = false
            this.invalidOtp = false;
            this.signUpForm.patchValue({
              mobileNumber: this.salesforceData ? this.salesforceData?.Phone : '',
              firstname: this.salesforceData ? this.salesforceData?.Name : '',
              lastname: this.salesforceData ? this.salesforceData?.LastName : '',
              email: this.salesforceData ? this.salesforceData?.Email : '',
            });
            this.isMobileSalesForce = true;

            let emailPresentPresent = this.isEmpty(this.salesforceData?.Email);
            this.isEmailSalesForce = emailPresentPresent ? true : false;
            if (this.salesforceData?.Phone) {
              //this.registerForm.controls["mobileNumber"].disable();
            }
            this.signUpForm.controls['name']?.enable();
            this.signUpForm.controls['email']?.enable();
          }
          else {
            this.otpModal.show();
            this.invalidOtp = true;
            this.count = this.count - 1;
            if (this.count === 0) {
              this.lock = true;
            }

            this.count = this.count - 1;
          }


        }), err => {
          this.otpModal.show();
          this.spinner.hide();
          this.bsService.notifyError(err, otpSubmitData);
          this.invalidOtp = true;
        }

    }



  }

  isEmpty(email) {
    if (email == "" || email == undefined || email == null) {
      return false;
    } else {
      return true
    }
  }


  resendOTP() {
    this.ngOtpInput.setValue('');
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: 'Test@123',
      confirmPassword: 'Test@123',

    };


    this.spinner.show();
    let tokengeresp;
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        tokengeresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
        }
        this.showResendOtp = false;
        //this.ngOtpInput.setValue('');

        let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
        //this.tokenReGenerate();
        let otpGenerateData = {
          mobile: getMobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          sessionId: sessionIdValue,
        };
        let optGenresp;
        this.apiService
          .otpGenerate(otpGenerateData)
          .pipe(first())
          .subscribe(
            (res) => {
              optGenresp = res;
              //window.alert('OTP sent');
              this.spinner.hide();
              if (res['status'].code == 200) {
              } else if (res['status'].code == 401) {
                this.apiService.logout();
              } else {
                this.apiService.showToaster(res['status'].message);
              }
            },
            (err) => {
              this.spinner.hide();
              this.bsService.notifyError(err, otpGenerateData);
              console.log(err, '=== OTP gererate error list =======');
            }
          );
      },err=>{
        this.spinner.hide();
        this.bsService.notifyError(err, formData);
      });
  }


  get loginFormVal() {
    return this.loginForm.controls;
  }

  get signUpFormVal() {
    return this.signUpForm.controls;
  }

  phone_no;
  onSubmitLoginForm() {
    this.hiddenBtn = true;
    console.log(this.prodCategory, this.scannedQRCode);

    console.log(this.loginForm);
    this.submittedLoginForm = true;
    if (this.loginForm.invalid) {
      this.showError = true;
      this.hiddenBtn = false;
      return;
    } else {
      let mob1 = this.loginForm.value ? this.loginForm.value.mobileNumber : '';
      let password = this.loginForm.value ? this.loginForm.value.password : '';
      let mob = JSON.stringify(mob1).replace(/\b0+/g, '');
      sessionStorage.setItem('mobileNo', mob);
      console.log('dd', mob);
      this.phone_no = JSON.stringify(mob);
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: mob,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: password,
        confirmPassword: password,
      };
      this.spinner.show();
      let tokengeresp;
      this.apiService
        .tokenGenerate(formData)
        .pipe(first()) //token generate api
        .subscribe((res) => {
          tokengeresp = res;
          console.log(res);
          if (res['status'].code == 200) {
            let sessionId = res['user'].sessionId;
            sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
            if (res['user'].userRegisteredForPassword == false) {
              // this.userNotRegistered.show();
              this.spinner.hide();
              //this.apiService.showToaster(' Pengguna tidak terdaftar. Silakan daftarkan pengguna ');
              this.userNotRegistered = true;
              console.log(this.userNotRegistered);
              console.log('user is not registerred');
            } else if (
              res['user'].userRegisteredForPassword == true ||
              res['status'].code == 1515
            ) {
              // if user is present in CRM DB
              let formData = {
                identifierType: 'MOBILE',
                identifierValue: mob,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                sessionId: sessionId,
                password: password,
              };
                let passwordVal;
              this.apiService
                .passwordValidate(formData)
                .pipe(first())
                .subscribe((res) => {
                  passwordVal = res;
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    if(res['user'].userRegisteredForPassword){
                      let mobile = sessionStorage.getItem('mobileNo');
                      let tokenKey = res['auth'].token;
                      let getcustresp;
                      this.apiService.getCapCustomer(mobile, tokenKey).subscribe((customerResp: any) => {
                        getcustresp = customerResp;
                        if (customerResp.status.code == 200) {
                          sessionStorage.setItem('authToken', res['auth'].token);
                          sessionStorage.setItem('authTokenNew', res['auth'].token);
                          sessionStorage.setItem('authKey', res['auth'].key);
                          sessionStorage.setItem('userPassword', password);
                          this.router.navigate(['/profile']);
                        } else {
                          this.disabledLogin = true
                          this.router.navigate(['login-signup']);
                        }
                      }, err => {
                        console.log(err)
                        this.spinner.hide();
                        this.bsService.notifyError(err);
                      })
                    }else{
                      this.disabledLogin = true
                      this.router.navigate(['login-signup']);
                    }
                  } else if (res['status'].code == 1505) {
                    //invalid session
                    this.router.navigate(['/login-signup']);
                    console.log(res['status'].message);
                  } else if (res['status'].code == 1528) {
                    //password does not match
                    this.spinner.hide();
                    console.log(res['status'].message);
                    this.invalidPassword = true;
                    setTimeout(() => {
                      this.invalidPassword = false;
                    }, 5000);
                    console.log(this.invalidPassword, 'this.invalidPassword')
                  } else {
                    this.apiService.showToaster(res['status'].message);
                    console.log(res['status'].message);
                   
                    //other errors
                  }
                }, err => {
                  this.spinner.hide()
                  this.bsService.notifyError(err, formData);
                  if (err) {
                    this.spinner.hide()
                  }
                });
            }

            //user not registered call salesforce Api
            else {
              //this.spinner.show();
              // this.apiService.getSalesForceData(mob).pipe(first())
              // .subscribe((res:any)=>{
              //     this.spinner.hide();
              //     let salesforceData = res['result'];
              //       // Put the salesforce data object into storage
              //     sessionStorage.setItem('salesForceData', JSON.stringify(salesforceData));
              //     this.apiService.setSalesForceUser(true);
              //     this.router.navigateByUrl('/register')
              // },
              // err=>{
              //   this.spinner.hide();
              //   this.apiService.setSalesForceUser(false);

              console.log('user not registered !!!!');
              //   //this.userNotRegistered.show();
              //   console.log(err, 'err--')
              // })
            }
          }
        },err=>{
          this.spinner.hide();
          this.bsService.notifyError(err, formData);
        });
    }
  }

  emailInput(ev) {
    if (ev.keyCode == 8) {
      this.emailExsistError = false;
    }
  }

  onSubmitSignUpForm() {
    // this.dob = this.signUpForm.value.dob;
    // this.signUpForm.patchValue({
    //   dob: moment(this.dob).format('DD/MM/YYYY'),
    // });
    this.submittedSignUpForm = true;
    if (this.signUpForm.invalid) {
      return;
    }
    if (this.signUpForm.valid) {
      console.log('signupform: ', this.signUpForm.value);
      this.signUpFormData = this.signUpForm.value;
      this.regMobnum = this.signUpFormData.mobileNumber;
      let mobNo = this.regMobnum;
      sessionStorage.setItem('mobileNo', this.regMobnum);

      let formData = {
        identifierType: 'MOBILE',
        identifierValue: String(this.regMobnum),
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: this.signUpFormData.password,
        confirmPassword: this.signUpFormData.confirmPassword,
      };


      this.spinner.show();
      this.apiService
        .checkExistingEmail(encodeURIComponent(this.signUpFormData.email))
        .pipe(first())
        .subscribe(
          (res: any) => {
            if (res.result.statusCode == 200) {
              if (res.result.message === 'User not found') {
                  let tokengenresp;
                this.apiService
                  .tokenGenerate(formData)
                  .pipe(first()) //token generate api
                  .subscribe(
                    (res) => {
                      tokengenresp = res;
                      this.spinner.hide();
                      if (res['status'].code == 200) {
                        this.emailExsistError = false;
                        let sessionId = res['user'].sessionId;
                        sessionStorage.setItem(
                          'sessionID',
                          JSON.stringify(sessionId)
                        );
                        // if (res['user'].userRegisteredForPassword == true) { // if user is present in CRM DB
                        //   console.log('user already registered')
                        //   this.router.navigate(['/login']);
                        // }

                        let otpGenerateData = {
                          identifierType: 'MOBILE',
                          identifierValue: String(this.regMobnum),
                          deviceId: env.DEVICE_ID,
                          brand: env.BRAND,
                          sessionId: sessionId,
                        };
                        this.spinner.show();
                          let otpgenresp;
                        this.spinner.show();
                        this.apiService
                          .otpGenerate(otpGenerateData)
                          .pipe(first()) // otp generate api
                          .subscribe(
                            (res) => {
                              otpgenresp = res;
                              this.hiddenBtn = false;
                              console.log(
                                'signupform: ',
                                this.signUpForm.value
                              );
                              let formValue = this.signUpFormData;
                              formValue['reset'] = false;
                              const navigationExtras: NavigationExtras = {
                                state: { formValue: formValue },
                              };
                              console.log(
                                'signupform: ',
                                this.signUpForm.value
                              );
                              this.router.navigate(['otp'], navigationExtras);
                              this.spinner.hide();
                              if (res['status'].code == 200) {
                                console.log(res);
                               
                              } else {
                                console.log(res['status'].message);
                              }
                            },
                            (err) => {
                              this.spinner.hide();
                              this.bsService.notifyError(err, otpGenerateData);
                              //err in otp generate
                              this.spinner.hide();
                              console.log(
                                err,
                                '=== OTP gererate error list ======='
                              );
                            }
                          );
                      }
                    },
                    (err) => {
                      this.spinner.hide();
                      this.bsService.notifyError(err, formData);
                      // err in token generate
                      this.spinner.hide();
                      console.log(err, '=== token generate err=======');
                    }
                  );


              }
            }

          },
          (err) => {
            // err in email check api
            this.emailExsistError = true; //email arleady exsist
            console.log('emailExsistError: ', this.emailExsistError);
            console.log('email already exsist---');
            // alert('Email already existing');
            this.spinner.hide();
          }
        );

    }
  }

  trimEmail(event){
    event.target.value = event.target.value.trim();
    this.signUpForm.patchValue({
      email: event.target.value, 
    });
  }



}
//}

// }
