<app-header></app-header>

<section class="globalContainer pt70 px15 termsPage">
    <app-back text="Kembali"></app-back>

    <div class="text-left">
    <img class="img-fluid" [src]="aboutLoyality?.associatedfile">
    </div>

    <h3 class="primaryTitle mt-4 mb-3 black-color">{{aboutLoyality?.heading}}</h3>
    <div class="loyalty-content mb70" [innerHTML]="aboutLoyality?.contentvalue | safe: 'html'"></div>
</section>


<app-footer></app-footer>