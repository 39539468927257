import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { Router, NavigationExtras } from '@angular/router';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  forgotOtpForm: FormGroup;
  submitted = false;
  showResendOtp: boolean = false;
  showpassword = false;
  showSignUpPassword = false;
  showpasswordNew = false;
  sessionId: any;
  showOtpForm: boolean = false;
  invalidOtp: boolean;
  otp: any;
  otpLength: any;
  activeClassButton: boolean;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  submitOtpForm: boolean;
  mobileNo: any;
  beginTimer: boolean;
  count: number;
  lock: boolean;
  timeLeft: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
  ) { window.scrollTo({ top: 0 }) }

  ngOnInit(): void {
    this.count = 3;
    this.beginTimer = true;
    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$')]],
        confirmNewPassword: ['', [Validators.required, Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$')]],
      },
      {
        validator: MustMatch('newPassword', 'confirmNewPassword'),
      }
    );

    this.forgotOtpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    })
  }

  get OtpFormVal() {
    return this.forgotOtpForm.controls;
  }

  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false
    this.otp = otp;
    console.log('---', otp.length);
    this.otpLength = otp.length
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  get changePasswordFormVal() {
    return this.changePasswordForm.controls;
  }

  eyepassword() {
    this.showpassword = !this.showpassword;
  }

  eyepasswordNew() {
    this.showpasswordNew = !this.showpasswordNew;
  }




  onSubmitOtpForm() {
    this.submitOtpForm = true;
    if (this.otpLength != 6) {
      return;
    } else {
      this.sessionId = sessionStorage.getItem('sessionID');
      this.mobileNo = sessionStorage.getItem('mobileNo')
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": JSON.parse(this.mobileNo),
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": JSON.parse(this.sessionId),
        "otp": this.otp
      }
      console.log("otpSubmitData: ", otpSubmitData);
      this.spinner.show();
      let otpvalresp;
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe(res => {
          otpvalresp = res;
          this.spinner.hide();
          if (res['status'].code == 200) {
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            this.showOtpForm = false;
            this.beginTimer = false;
            if (res['status'].code == 200) {
              let token = sessionStorage.getItem('authToken');
              let formData =
              {
                "identifierType": "MOBILE",
                //  "identifierValue": this.NewMobileNo == null ? this.mobileNo : this.NewMobileNo,
                "identifierValue": this.mobileNo,
                "deviceId": env.DEVICE_ID,
                "brand": env.BRAND,
                "password": 'test123',
                "newPassword": this.changePasswordForm.value.newPassword,
                "confirmPassword": this.changePasswordForm.value.confirmNewPassword,
                "token": token
              }
              this.spinner.show();
              let changepassresp;
              this.apiService.changePassword(formData).pipe(first()).
                subscribe(res => {
                  changepassresp = res;
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    this.beginTimer = false;
                    console.log("RES: ", res);
                    this.router.navigate(['/profile'])
                    this.changePasswordForm.reset()
                  }
                },err=>{
                  this.spinner.hide();
                  this.bsService.notifyError(err, formData);
                }),
                err => {
                  console.log(err);
                }

            }
          } else {
            this.invalidOtp = true;
            this.count = this.count - 1;
            if (this.count === 0) {
              this.lock = true;
            }

            this.count = this.count - 1;
          }
        }, err => {
          console.log(err)
          this.spinner.hide();
          this.bsService.notifyError(err, otpSubmitData);
        })
    }
  }

  resendOTP() {
    this.ngOtpInput.setValue('');
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: this.changePasswordForm.value.newPassword,
      confirmPassword: this.changePasswordForm.value.confirmNewPassword,

    };


    this.spinner.show();
    let tokengenresp;
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        tokengenresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
        }
        this.showResendOtp = false;
        //this.ngOtpInput.setValue('');

        let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
        //this.tokenReGenerate();
        let otpGenerateData = {
          mobile: getMobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          sessionId: sessionIdValue,
        };
        let otpgenresp;
        this.apiService
          .otpGenerate(otpGenerateData)
          .pipe(first())
          .subscribe(
            (res) => {
              otpgenresp = res;
              //window.alert('OTP sent');
              this.spinner.hide();
              if (res['status'].code == 200) {
              } else if (res['status'].code == 401) {
                this.apiService.logout();
              } else {
                this.apiService.showToaster(res['status'].message);
              }
            },
            (err) => {
              this.spinner.hide();
              this.bsService.notifyError(err, otpGenerateData);
              console.log(err, '=== OTP gererate error list =======');
            }
          );
      },err=>{
        this.spinner.hide();
        this.bsService.notifyError(err, formData);
      });
  }


  onSubmitChangePasswordForm() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    // if (res['user'].userRegisteredForPassword == true) { // if user is present in CRM DB
    //   console.log('user already registered')
    //   this.router.navigate(['/login']);
    // }
    // let regMobnum = sessionStorage.getItem('mobileNo');
    // let formData = {
    //   identifierType: 'MOBILE',
    //   identifierValue: regMobnum,
    //   deviceId: env.DEVICE_ID,
    //   brand: env.BRAND,
    //   password: this.changePasswordForm.value.newPassword,
    //   confirmPassword: this.changePasswordForm.value.confirmNewPassword,
    // };
    // this.apiService
    //   .tokenGenerate(formData)
    //   .pipe(first()) //token generate api
    //   .subscribe((res) => {
    //     console.log(res);

    //     if (res['status'].code == 200) {
    //       let sessionId = res['user'].sessionId;
    //       sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
    //       let regMobnum = sessionStorage.getItem('mobileNo');

    //       let otpGenerateData = {
    //         identifierType: 'MOBILE',
    //         identifierValue: regMobnum,
    //         deviceId: env.DEVICE_ID,
    //         brand: env.BRAND,
    //         sessionId: sessionId,
    //       };
    //       this.apiService
    //         .otpGenerate(otpGenerateData)
    //         .pipe(first()) // otp generate api
    //         .subscribe(
    //           (res) => {
    //             if (res['status'].code == 200) {
    //               console.log(res);
    //               let formValue = this.changePasswordForm.value;
    //               const navigationExtras: NavigationExtras = {
    //                 state: { formValue: formValue },
    //               };
    //               console.log(navigationExtras);

    //               this.router.navigate(['otp'], navigationExtras);
    //             } else {
    //               console.log(res['status'].message);
    //             }
    //           },
    //           (err) => {
    //             console.log(err, '=== OTP gererate error list =======');
    //           }
    //         );
    //     }
    //   });
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: 'test123',
      confirmPassword: 'test123',
    };
    console.log(getMobile, 'mobile taken from localstorage---');

    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        if (res['status'].code == 200) {
          this.sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
          this.showResendOtp = false;
          //----------forgot password---------
          let formData = {
            identifierType: 'MOBILE',
            identifierValue: getMobile,
            deviceId: env.DEVICE_ID,
            brand: env.BRAND,
            sessionId: this.sessionId,
            password: 'test123',
            confirmPassword: 'test123',
          };
          let forgotresp;
          this.apiService
            .forgotPassword(formData)
            .pipe(first()) // forgot password api
            .subscribe(
              (res) => {
                forgotresp = res;
                if (res['status'].code == 200) {
                  let otpGenerateData = {
                    identifierType: 'MOBILE',
                    identifierValue: getMobile,
                    deviceId: env.DEVICE_ID,
                    brand: env.BRAND,
                    sessionId: this.sessionId,
                  };
                  this.apiService
                    .otpGenerate(otpGenerateData)
                    .pipe(first()) // otp generate api
                    .subscribe(
                      (res) => {
                        if (res['status'].code == 200) {
                          // this.forgotModalOne.hide();
                          // open otp form
                          this.showOtpForm = true;

                        } else {
                          // otp genearte else
                          //this.toastr.error(res['status'].message)
                        }
                      },
                      (err) => {
                        // otp genearte error
                        this.bsService.notifyError(err, otpGenerateData);
                        console.log(err, '=== OTP gererate error list =======');
                      }
                    );
                } else {
                  // forgot passsword else
                  console.log(res['status'].message);
                }
              },
              (err) => {
                //forgot passsword error
                this.spinner.hide();
                this.bsService.notifyError(err), formData;
                console.log(err);
              }
            );
        }
      }, err =>{
        this.bsService.notifyError(err, formData);
      });
  }
}
