import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  collapse: Boolean = true;
  faqData1:any;
  faqData2:any;
  faqData3:any;
  constructor(
    private spinner: NgxSpinnerService, 
    private route:ActivatedRoute, 
    private APIService: ApiService, 
    private bsService: BugsnagService
    ) { }

  ngOnInit(): void {
    console.log('----', this.collapse);
    window.scrollTo({top: 0})
    this.getFaq();
  }

  onClickTab(event) {
    console.log('-----',event);
  }

  getFaq() {
    this.spinner.show();
    this.APIService.getcontentBlocks().subscribe((res: any) => {
      this.spinner.hide();
      let faqData = res.data.filter(element => element.key == 'FAQ');
      this.faqData1 = faqData[0].mainfaq.filter(res=>res.active == '1');
      this.faqData2 = faqData[0].mainfaq.filter(res=>res.active == '2');
      this.faqData3 = faqData[0].mainfaq.filter(res=>res.active == '3');
    }, err=>{
      this.spinner.hide();
      this.bsService.notifyError(err);
    })
  }

}
