<div class="cameraContainer globalContainer">
  <div class="row no-gutters mb-3">
    <div class="col-3">
      <div class="pt-4">
        <a class="customClass" (click)="onClickBack()"><img src="../../../assets/images/backicon.png"></a>
      </div>
    </div>
  </div>
  <div class="text-center initialContent">
    <p class="textBlack mt-2 cameraTitle">Validation upload receipt</p>
    <p class="camerContent">Pastikan foto struk Anda terlihat jelas</p>
  </div>
  <div class="text-center captureContent" style="display: none">
    <p class="textBlack mt-2 cameraTitle">Keren!</p>
    <p class="camerContent">
      Cek struk yang telah Anda Unggah, pastikan sudah terlihat jelas agar dapat
      diproses.
    </p>
  </div>
  <div class="videoCam mt-4">
    <div class="snapshot" *ngIf="webcamImage">
      <img [src]="url" style="height: 390px" />
      <div class="confirmButtons mt-4 row">
        <div class="col-6 pr-0">
          <button class="actionBtn w-100 btn" (click)="retake()">
            Ambil Ulang
          </button>
        </div>
        <div class="col-6">
          <button (click)="uploadImage()" class="btn w-100 btnPurple">
            Validasi
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!webcamImage">
      <webcam
        [height]="500"
        [width]="600"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [imageQuality]="1"
        [mirrorImage]="never"
        (initError)="handleInitError($event)"
      ></webcam>
    </div>
  </div>
  <div class="camButtons row mt-4 mx-0 mb-4">
    <div class="uploadBtn col-2">
      <input
        id="upload"
        type="file"
        name="profile"
        accept="image/*"
        (change)="fileChangeEvent($event)"
      />
      <img class="" src="../../../../assets/images/uploadfile.png" />
    </div>
    <div class="captureBtn col-8 text-center" *ngIf="showCamera">
      <img
        class=""
        src="../../../../assets/images/capture.png"
        (click)="triggerSnapshot()"
      />
    </div>
    <div class="col-2">
      <!-- <img class="" src="../../../../assets/images/flashnew.png"> -->
    </div>
  </div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
  <li>{{ error | json }}</li>
</ul>
