<app-header></app-header>
<section class="globalContainer pt70 px15">
  <div class="mb-2 mt-3 successMessage" *ngIf="profileUpdate">
    <span><i class="mdi mdi-check"></i>Profil berhasil diperbarui</span>
  </div>
  <app-back text="Kembali"></app-back>
  <div class="profileImgRoundedBlockOut">
    <div class="imgBlock mt-4">
      <!-- <img class="" src="../../../assets/images/profilepersonsample.png" /> -->
      <span>
        <img *ngIf="finalUserImage" [src]="finalUserImage" />
        <img
          *ngIf="!finalUserImage"
          src="../../../assets/images/avataruserbgdark.png"
        />
      </span>
      <!-- <a class="changePic">Change</a> -->

      <div class="changePic mt-2">
        <div class="imageUploadWrap">
          <label class="imageUploadLabel" for="upload">
            Ganti foto profil
            <input
              id="upload"
              type="file"
              name="profile"
              (change)="fileChangeEvent($event)"
            />
          </label>
          <ngx-popup
            [(ngModel)]="visible"
            [ngModelOptions]="{ standalone: true }"
          >
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio"
              [aspectRatio]="3 / 3"
              [resizeToWidth]="256"
              [cropperMinWidth]="128"
              [onlyScaleDown]="true"
              [roundCropper]="false"
              [canvasRotation]="canvasRotation"
              [transform]="transform"
              [alignImage]="'left'"
              [style.display]="showCropper ? null : 'none'"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()"
            >
            </image-cropper>
            <button (click)="uploadImage()">Mengunggah</button>
          </ngx-popup>
        </div>
      </div>
    </div>
  </div>
  <section class="formContainer editProfileContainer">
    <h3 class="primaryBlackTitle editprofile-title">Data Diri</h3>
    <form [formGroup]="editProfileForm" (ngSubmit)="onSubmitEditProfileForm()">
      <div class="form-group">
        <label>Nama Depan</label>
        <input
          type="name"
          class="form-control hl-50"
          placeholder=" Nama Depan"
          [ngClass]="{
            'is-invalid': submitted && editProfileFormVal.firstname.errors
          }"
          formControlName="firstname"
        />
        <div
          *ngIf="submitted && editProfileFormVal.firstname.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editProfileFormVal.firstname.errors.required">
            Enter Nama Depan
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Nama Belakang</label>
        <input
          type="name"
          class="form-control hl-50"
          placeholder="Nama Belakang"
          [ngClass]="{
            'is-invalid': submitted && editProfileFormVal.lastname.errors
          }"
          formControlName="lastname"
        />
        <div
          *ngIf="submitted && editProfileFormVal.lastname.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editProfileFormVal.lastname.errors.required">
            Nama Belakang
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Nomor HP </label>
        <div class="position-relative">
          <input
            type="number"
            [readonly]="isMobillePresent"
            class="form-control hl-50 mobNumber"
            placeholder="{{ 'LOGIN_SIGNUP.ENTER_MOBILE_NUMBER' | translate }}"
            [ngClass]="{
              'is-invalid': submitted && editProfileFormVal.phoneNumber.errors
            }"
            formControlName="phoneNumber"
          />
          <span class="mobileCode">+62</span>
        </div>
        <div
          *ngIf="submitted && editProfileFormVal.phoneNumber.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editProfileFormVal.phoneNumber.errors.required">
            Enter Number
          </div>
          <div
            *ngIf="
              editProfileFormVal.phoneNumber.errors.pattern ||
              editProfileFormVal.phoneNumber.errors.pattern
            "
          >
            Invalid Number
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ "LOGIN_SIGNUP.EMAIL" | translate }}</label>
        <input
          type="email"
          [readonly]="isEmailPresent"
          class="form-control hl-50"
          placeholder="{{ 'LOGIN_SIGNUP.ENTER_EMAIL' | translate }}"
          [ngClass]="{
            'is-invalid': submitted && editProfileFormVal.email.errors
          }"
          formControlName="email"
        />
        <div
          *ngIf="submitted && editProfileFormVal.email.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editProfileFormVal.email.errors.required">
            Enter email
          </div>
          <div
            *ngIf="
              editProfileFormVal.email.errors.pattern ||
              editProfileFormVal.email.errors.pattern
            "
          >
            Invalid Number
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Tanggal Lahir</label>
        <input
          type="text"
          class="form-control hl-50"
          placeholder="Masukkan DOB"
          [ngClass]="{
            'is-invalid': submitted && editProfileFormVal.dob.errors
          }"
          formControlName="dob"
          [bsValue]="minDate"
          [maxDate]="maxDate"
          bsDatepicker
          [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }"
        />
        <div class="eyeIcon calendarIcon">
          <!-- <i class="mdi mdi-calendar"></i> -->
          <img src="../../../assets/images/calendar-icon.png" alt="calendar" />
        </div>
        <div
          *ngIf="submitted && editProfileFormVal.dob.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editProfileFormVal.dob.errors.required">Masukkan DOB</div>
          <div
            *ngIf="
              editProfileFormVal.dob.errors.pattern ||
              editProfileFormVal.dob.errors.pattern
            "
          >
            Invalid Number
          </div>

          <div *ngIf="editProfileFormVal.dob.errors.minimumAgeIs18">
            Usia minimal harus 18
          </div>
        </div>
      </div>
      <h3 class="primaryBlackTitle mt-5">Data Keluarga</h3>
      <ul class="editProfileListBlock mt-3">
        <!-- <li routerLink="/my-profile" [state]="{ customer: customerData }">
           <div class="row h-100 align-items-center">
            <div class="col-10">
              <p class="mb-0">Data si Kecil</p>
            </div>
            <div class="col-2 text-right">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div> 
        </li> -->
        <li routerLink="/kid-profile" [state]="{ customer: customerData }">
          <div class="row h-100 align-items-center">
            <div class="col-10">
              <p class="mb-0">Data si Kecil</p>
            </div>
            <div class="col-2 text-right">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
      </ul>
      <h3 class="primaryBlackTitle">Alamat</h3>
      <ul class="editProfileListBlock mt-3">
        <li routerLink="/address" [state]="{ customer: customerData }">
          <div class="row h-100 align-items-center">
            <div class="col-10">
              <p class="mb-0">Edit alamat</p>
            </div>
            <div class="col-2 text-right">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
      </ul>
      <h3 class="primaryBlackTitle">Keamanan</h3>
      <ul class="editProfileListBlock mt-3">
        <li routerLink="/change-password">
          <div class="row h-100 align-items-center">
            <div class="col-10">
              <p class="mb-0">Ganti Password</p>
            </div>
            <div class="col-2 text-right">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
      </ul>

      <h3 class="primaryBlackTitle">Lengkapi Data di Bawah</h3>
      <div class="editProfileRadioBtnBlock mt20">
        <p>
          Apakah Anda sudah pernah mencoba atau mengkonsumsi Abbott PediaSure?
        </p>
        <div>
          <input
            id="radio-1"
            class="radio-custom"
            formControlName="product_tried"
            type="radio"
            value="Ya, Sudah pernah"
            checked
          />
          <label for="radio-1" class="radio-custom-label"
            >Ya, Sudah pernah</label
          >
        </div>
        <div>
          <input
            id="radio-2"
            class="radio-custom"
            type="radio"
            value="Belum pernah"
            formControlName="product_tried"
          />
          <label for="radio-2" class="radio-custom-label">Belum pernah</label>
        </div>

        <div *ngIf="editProfileForm.value.product_tried">
          <div
            class="my-3"
            style="border: 1px solid #8f9295; background: #8f9295"
          ></div>
          <p>Berapa lama Anda mencoba/konsumsi produk susu Abbott PediaSure?</p>

          <div
            *ngIf="editProfileForm.value.product_tried == 'Ya, Sudah pernah'"
          >
            <div>
              <input
                id="radio-3"
                class="radio-custom"
                type="radio"
                formControlName="producttime_tried"
                value="0-3 Bulan"
                checked
              />
              <label for="radio-3" class="radio-custom-label">0-3 Bulan</label>
            </div>
            <div>
              <input
                id="radio-4"
                class="radio-custom"
                type="radio"
                value=">3 Bulan"
                formControlName="producttime_tried"
              />
              <label for="radio-4" class="radio-custom-label">>3 Bulan</label>
            </div>
          </div>

          <div *ngIf="editProfileForm.value.product_tried == 'Belum pernah'">
            <div>
              <input
                id="radio-5"
                class="radio-custom"
                type="radio"
                formControlName="producttime_tried"
                value="Dulu pernah, sekarang tidak konsumsi lagi"
              />
              <label for="radio-5" class="radio-custom-label"
                >Dulu pernah, sekarang tidak konsumsi lagi</label
              >
            </div>
            <div>
              <input
                id="radio-6"
                class="radio-custom"
                type="radio"
                value="Belum pernah mencoba"
                formControlName="producttime_tried"
              />
              <label for="radio-6" class="radio-custom-label">
                Belum pernah mencoba</label
              >
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btnPurple br10 btn-block my-5 btn-50">
        Simpan
      </button>
    </form>
  </section>
</section>

<div
  bsModal [config]="{backdrop: 'static'}"
  #confirmationModal="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body pt-3 text-center">
        <h3 class="pointsModalTilte text-center mt-3">
          Apakah Anda yakin ingin memperbarui profil?
        </h3>
        <div class="row mx-0 mt-4 mb-2 editProfPopBtn">
          <div class="col-6 px-1">
            <button
              class="btn btn cancelBtn br10 w-100 btn-50"
              (click)="cancelledSuccessModal()"
            >
              Batal
            </button>
          </div>
          <div class="col-6 px-1">
            <button
              class="btn btn btnPurple br10 w-100 btn-50"
              (click)="confirmSuccessModal()"
            >
              Yakin
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
