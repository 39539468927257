import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public translate: TranslateService) {
    translate.addLangs(['en_US', 'my_MH']);
    translate.setDefaultLang('en_US');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|my_MH/) ? browserLang : 'en_US');
   }

  ngOnInit(): void {
  }

}
