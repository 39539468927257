import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pediasure-traceability',
  templateUrl: './pediasure-traceability.component.html',
  styleUrls: ['./pediasure-traceability.component.scss']
})
export class PediasureTraceabilityComponent implements OnInit {
  iSprintData: any;
  productName: any;
  productImage: any;
  batchNumber: any;
  manufactureDate: any;
  expiryDate: any;
  isIsprint: any;
  flavor: any;
  description: any;
  size: any;
  sku: any;
  prodImage: any;
  routeData: any;
  // constructor() { }

  constructor(public translate: TranslateService, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private APIService: ApiService) {
    translate.addLangs(['en_US', 'my_MH']);
    translate.setDefaultLang('en_US');
    window.scrollTo({ top: 0 })
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|my_MH/) ? browserLang : 'en_US');
  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    this.routeData = this.route.snapshot.params.data;
    console.log(this.routeData);
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    this.sku = this.iSprintData.sku;
    this.APIService.getGpasProductImage(this.sku).subscribe((res: any) => {
      console.log(res.data, 'getGpasProductImage')
      this.prodImage = res.data[0]?.image
    })
    console.log(this.iSprintData, 'this.iSprintData')
    if (this.iSprintData) {
      this.productImage = this.iSprintData.product_image;
      this.batchNumber = this.iSprintData.batch_number;
      this.manufactureDate = this.iSprintData.manufacture_date;
      this.expiryDate = this.iSprintData.expiry_date;
      this.isIsprint = this.iSprintData.is_isprint;
      this.flavor = this.iSprintData.flavor;
      this.size = this.iSprintData.size;
      this.description = this.iSprintData.product_description
      if (this.isIsprint) {
        this.productName = this.parseFunction(this.iSprintData.product_name).en_US;
        console.log(this.productName, 'this.productName')
      } else {
        this.productName = this.iSprintData.brand;
      }
    }
  }

  onClickHome() {
    this.router.navigate(['/landing'])
  }

  onClickProfile() {
    this.router.navigate(['/profile'])
  }
}
