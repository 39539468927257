<app-header></app-header>
<section class="globalContainer pt70 px15 pb70">
  <!-- <app-back routerLink="/profile" text="Kembali"></app-back> -->
  <div class="backToPreviousLocation pt-4">
    <a routerLink="/profile"
      ><i class="mdi mdi-keyboard-backspace backIcon"></i> Kembali</a
    >
  </div>
  <!-- <i class="mdi mdi-filter-outline filterIcon"></i> -->
  <section class="myPointsContainer mt30">
    <h3 class="mt-4 pointsTitle">Point Anda</h3>
    <ul
      class="nav nav-pills mb-3"
      id="pills-tab"
      role="tablist"
      *ngIf="!status"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active btn-block"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          Status Poin
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link btn-block"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Riwayat Poin
        </button>
      </li>
    </ul>

    <div class="tab-content mb70 pb-2" *ngIf="!status">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <ng-template #noTransactions>
          <div class="no-transaction my-60 text-center">
            <img
              src="../../../assets/images/no-trnasaction.png"
              alt="No transaction"
            />
            <h5 class="noTrans">Anda belum memiliki aktivitas</h5>
          </div>
        </ng-template>
        <div *ngIf="productList?.length > 0; else noTransactions">
          <div
            class="giftInfo mt-4"
            *ngFor="
              let product of productList
                | paginate: { itemsPerPage: 8, currentPage: page }
            "
          >
            <div class="row">
              <div class="col-3">
                <img class="image-fluid w-100" [src]="product?.image" />
              </div>
              <div class="col-6 pl-0">
                <p>{{ product?.name }}</p>
                <div class="badge-box">
                  <span
                    class="badge"
                    [ngStyle]="{ 'background-color': product.color }"
                  >
                    <span *ngIf="product?.status == 'approved'">BERHASIL</span>
                    <span *ngIf="product?.status == 'success'">BERHASIL</span>
                    <span *ngIf="product?.status == 'rejected'"
                      >VALIDASI DITOLAK</span
                    >
                    <span *ngIf="product?.status == 'pending'"
                      >BUTUH VALIDASI</span
                    >
                    <span *ngIf="product?.status == 'awaiting'"
                      >DALAM PROSES</span
                    >
                  </span>
                  <span *ngIf="product.status"> </span>
                  <span class="badge-date">{{ product?.submissionDate }}</span>
                </div>
              </div>
              <div class="col-3 p-0 text-right">
                <p
                  class="mb-0 mt-3 fs12 mr-3"
                  [ngStyle]="{ color: product.color }"
                >
                  <span
                    *ngIf="
                      product.points != 'Validasi' && product.points != null
                    "
                    >+{{ product?.points }} Poin</span
                  >
                  <span
                    *ngIf="product.points == 'Validasi'"
                    (click)="openReceiptUploadPopup(product?.productCode)"
                    >{{ product.points }} ></span
                  >
                </p>
              </div>
            </div>

            <!-- <div class="row">
            <div class="col-3">
              <img
                class="image-fluid"
                src="../../../assets/images/Pediasure_MRI_Choco_400g 1.png"
              />
            </div>
            <div class="col-6 pl-0">
              <p>PediaSure Cokelat 400gr</p>
              <div class="badge-box">
                <span class="badge">Berhasil</span>
                <span>- 19/06/21</span>
              </div>
            </div>
            <div class="col-3 p-0 text-right">
              <p class="mb-0 mt-3 fs12 mr-3">+200 Poin</p>
            </div>
          </div> -->
          </div>

          <div
            class="text-center paginationCard"
            *ngIf="productList?.length > 4"
          >
            <pagination-template
              #p="paginationApi"
              (pageChange)="page = $event"
            >
              <span class="pagination-icon pagination-previous">
                <span [class.disabled]="p.isFirstPage()" (click)="p.previous()"
                  ><i class="mdi mdi-arrow-left"></i
                ></span>
              </span>

              <span class="page-number">
                <span>Halaman 1 dari {{ p.getLastPage() }}</span>
              </span>

              <span class="pagination-icon pagination-next">
                <span [class.disabled]="p.isLastPage()" (click)="p.next()"
                  ><i class="mdi mdi-arrow-right"></i
                ></span>
              </span>
            </pagination-template>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <ng-template #noGameTransactions>
          <div class="no-transaction my-60 text-center">
            <img
              src="../../../assets/images/no-trnasaction.png"
              alt="No transaction"
            />
            <h5 class="noTrans">Anda belum memiliki aktivitas</h5>
          </div>
        </ng-template>

        <div *ngIf="giftTransactions?.length || voucherList?.length; else noGameTransactions">
         
          <div
          *ngFor="
            let history of voucherList
              | paginate: { itemsPerPage: 8, currentPage: page }
          " 
        >
          <div class="row">
            <div class="col-3 text-center">
              <img class="history-image mb-2" [src]="history?.rewardDetails?.imageUrl" />
            </div>
            <div class="col-6 pl-0">
              <p class="mb-0">
               {{ history?.rewardDetails?.name }}
              </p>
              <p class="textGrey fs12"><strong>{{history?.quantity}}</strong>x Voucher - {{ history?.displayDate | date: 'dd MMM yyyy' }}</p>
            </div>
            <div class="col-3 p-0 text-right">
              <p class="mb-0 mt-3 fs12 mr-3 redColor">-{{ history?.total_points }} Poin</p>
              <span [routerLink]="['/e-redeem']" [state]="{voucherData: history}" class="voucherDetail">Lihat ></span>
            </div>
          </div>
        </div>
         
          <div
            *ngFor="
              let gift of giftTransactions
                | paginate: { itemsPerPage: 4, currentPage: page }
            "
          >
            <div class="row">
              <div class="col-3 text-center">
                <img class="history-image mb-2" [src]="gift?.giftImage" />
              </div>
              <div class="col-6 pl-0">
                <p class="mb-0" *ngIf="gift?.betterluck == 'true'">
                  Gamification - Tidak beruntung
                </p>
                <p class="mb-0" *ngIf="gift?.betterluck == 'false'">
                  Gamification - {{ gift.giftName }}
                </p>
                <p class="textGrey fs12">Voucher - {{ gift?.issuedDate }}</p>
              </div>
              <div class="col-3 p-0 text-right">
                <p class="mb-0 mt-3 fs12 mr-3 redColor">-{{ gift?.giftPoints }} Poin</p>
                <span *ngIf="gift?.betterluck == 'false'" class="voucherDetail" [routerLink]="['/e-redeem']" [state]="{voucherData: gift}">Lihat ></span>
              </div>
            </div>
          </div>


   

          <div class="text-center paginationCard" *ngIf="giftTransactions?.length > 4 || redeemRewards?.length > 4">
            <pagination-template
              #p="paginationApi"
              (pageChange)="page = $event"
            >
              <span class="pagination-icon pagination-previous">
                <span [class.disabled]="p.isFirstPage()" (click)="p.previous()"
                  ><i class="mdi mdi-arrow-left"></i
                ></span>
              </span>

              <span class="page-number">
                <span>Halaman 1 dari {{ p.getLastPage() }}</span>
              </span>

              <span class="pagination-icon pagination-next">
                <span [class.disabled]="p.isLastPage()" (click)="p.next()"
                  ><i class="mdi mdi-arrow-right"></i
                ></span>
              </span>
            </pagination-template>
          </div>
          <div class="text-center mt-5">
            <a href="https://pediasure.co.id/contact" class="btn btnPurple btn-50 br10 w-100">Ada kendala? Hubungi Kami</a>
          </div>
        </div>


      </div>
    </div>

    <!-- <div class="load-more mb-2 fs16">Muat lebih banyak</div> -->
    <ul
      class="nav nav-pills mb-3"
      id="pills-tab"
      role="tablist"
      *ngIf="status == 'pointProfile'"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active btn-block"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          Status Poin
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          [class.disabled]="status == 'pointProfile'"
          class="nav-link btn-block"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Riwayat Poin
        </button>
      </li>
    </ul>

    <div class="tab-content mb70 pb-2" *ngIf="status == 'pointProfile'">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <ng-template #noTransactions>
          <div class="no-transaction my-60 text-center">
            <img
              src="../../../assets/images/no-trnasaction.png"
              alt="No transaction"
            />
            <h5 class="noTrans">Anda belum memiliki aktivitas</h5>
          </div>
        </ng-template>
        <div *ngIf="productList?.length > 0; else noTransactions">
          <div
            class="giftInfo mt-4"
            *ngFor="
              let product of productList
                | paginate: { itemsPerPage: 8, currentPage: page }
            "
          >
            <div class="row">
              <div class="col-3">
                <img class="image-fluid w-100" [src]="product?.image" />
              </div>
              <div class="col-6 pl-0">
                <p>{{ product?.name }}</p>
                <div class="badge-box">
                  <span
                    class="badge"
                    [ngStyle]="{ 'background-color': product.color }"
                  >
                    <span *ngIf="product?.status == 'approved'">BERHASIL</span>
                    <span *ngIf="product?.status == 'success'">BERHASIL</span>
                    <span *ngIf="product?.status == 'rejected'"
                      >VALIDASI DITOLAK</span
                    >
                    <span *ngIf="product?.status == 'pending'"
                      >BUTUH VALIDASI</span
                    >
                    <span *ngIf="product?.status == 'awaiting'"
                      >DALAM PROSES</span
                    >
                  </span>
                  <span *ngIf="product.status"> </span>
                  <span class="badge-date">{{ product?.submissionDate }}</span>
                </div>
              </div>
              <div class="col-3 p-0 text-right">
                <p
                  class="mb-0 mt-3 fs12 mr-3"
                  [ngStyle]="{ color: product.color }"
                >
                  <span
                    *ngIf="
                      product.points != 'Validasi' && product.points != null
                    "
                    >+{{ product?.points }} Poin</span
                  >
                  <span
                    *ngIf="product.points == 'Validasi'"
                    (click)="openReceiptUploadPopup(product?.productCode)"
                    >{{ product?.points }} ></span
                  >
                </p>
              </div>
            </div>

            <!-- <div class="row">
          <div class="col-3">
            <img
              class="image-fluid"
              src="../../../assets/images/Pediasure_MRI_Choco_400g 1.png"
            />
          </div>
          <div class="col-6 pl-0">
            <p>PediaSure Cokelat 400gr</p>
            <div class="badge-box">
              <span class="badge">Berhasil</span>
              <span>- 19/06/21</span>
            </div>
          </div>
          <div class="col-3 p-0 text-right">
            <p class="mb-0 mt-3 fs12 mr-3">+200 Poin</p>
          </div>
        </div> -->
          </div>

          <div
            class="text-center paginationCard"
            *ngIf="productList?.length > 0"
          >
            <pagination-template
              #p="paginationApi"
              (pageChange)="page = $event"
            >
              <span class="pagination-icon pagination-previous">
                <span [class.disabled]="p.isFirstPage()" (click)="p.previous()"
                  ><i class="mdi mdi-arrow-left"></i
                ></span>
              </span>

              <span class="page-number">
                <span>Halaman 1 dari {{ p.getLastPage() }}</span>
              </span>

              <span class="pagination-icon pagination-next">
                <span [class.disabled]="p.isLastPage()" (click)="p.next()"
                  ><i class="mdi mdi-arrow-right"></i
                ></span>
              </span>
            </pagination-template>
          </div>
        </div>
      </div>
    </div>

    <ul
      class="nav nav-pills mb-3"
      id="pills-tab"
      role="tablist"
      *ngIf="status == 'gameProfile'"
    >
      <li class="nav-item" role="presentation">
        <button
          [class.disabled]="status == 'gameProfile'"
          class="nav-link btn-block"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          Status Poin
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active btn-block"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Riwayat Poin
        </button>
      </li>
    </ul>
    <div class="tab-content mb70 pb-2" *ngIf="status == 'gameProfile'">
      <div
        class="tab-pane fade show active"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <ng-template #noGameTransactions>
          <div class="no-transaction my-60 text-center">
            <img
              src="../../../assets/images/no-trnasaction.png"
              alt="No transaction"
            />
            <h5 class="noTrans">Anda belum memiliki aktivitas</h5>
          </div>
        </ng-template>


        <div *ngIf="giftTransactions?.length; else noGameTransactions">
          <div
            *ngFor="
              let gift of giftTransactions
                | paginate: { itemsPerPage: 8, currentPage: page }
            "
          >
            <div class="row">
              <div class="col-3 text-center">
                <img class="history-image mb-2" [src]="gift?.giftImage" />
              </div>
              <div class="col-6 pl-0">
                <p class="mb-0" *ngIf="gift?.betterluck == 'true'">
                  Gamification - Tidak beruntung
                </p>
                <p class="mb-0" *ngIf="gift?.betterluck == 'false'">
                  Gamification - {{ gift.giftName }}
                </p>
                <p class="textGrey fs12">Voucher - {{ gift?.issuedDate }}</p>
              </div>
              <div class="col-3 p-0 text-right">
                <p class="mb-0 mt-3 fs12 mr-3">-{{ gift?.giftPoints }} Poin</p>
                <span *ngIf="gift?.betterluck == 'false'" class="voucherDetail" [routerLink]="['/e-redeem']" [state]="{voucherData: gift}">Lihat ></span>
              </div>
            </div>
          </div>

          <div class="text-center paginationCard" *ngIf="giftTransactions?.length > 8">
            <pagination-template
              #p="paginationApi"
              (pageChange)="page = $event"
            >
              <span class="pagination-icon pagination-previous">
                <span [class.disabled]="p.isFirstPage()" (click)="p.previous()"
                  ><i class="mdi mdi-arrow-left"></i
                ></span>
              </span>

              <span class="page-number">
                <span>Halaman 1 dari {{ p.getLastPage() }}</span>
              </span>

              <span class="pagination-icon pagination-next">
                <span [class.disabled]="p.isLastPage()" (click)="p.next()"
                  ><i class="mdi mdi-arrow-right"></i
                ></span>
              </span>
            </pagination-template>
          </div>
          <div class="text-center mt-5">
            <button class="btn btnPurple btn-50 br10 w-100">Ada kendala? Hubungi Kami</button>
          </div>
        </div>


      </div>
    </div>

    <section>
      <h3 class="mt-4 pointsSubTitle mb-2">Cek promo menarik ini</h3>
      <div class="customCardsBlockOut">
        <div class="card promo1" routerLink="/faq/terms-conditions"></div>
        <div class="card" routerLink="/faq/terms-conditions"></div>
        <div class="card" routerLink="/faq/terms-conditions"></div>
        <div class="card" routerLink="/faq/terms-conditions"></div>
        <div class="card" routerLink="/faq/terms-conditions"></div>
      </div>
    </section>
  </section>
</section>
<app-footer></app-footer>

<div
  bsModal [config]="{backdrop: 'static'}"
  #receiptUploadTerms="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mt120 mx-auto">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center"></div>
        <div class="text-center">
          <h5 class="fs20">Syarat dan Ketentuan Unggah Struk</h5>

          <ul class="listBlock">
            <li>
              <p>
                Foto struk harus dalam bentuk struk komputerisasi dan tercantum
                informasi : Nama toko, tanggal dan waktu pembelian, nama dan
                jumlah produk yang dibeli serta harga produk
              </p>
            </li>
            <li>
              <p>
                Kami berhak melakukan pengecekkan setiap struk yang dikirim dan
                berhak membatalkan jika struk dikirimkan tidak valid
              </p>
            </li>
          </ul>
        </div>
        <button
          class="btn btnPurple br10 btn-block mt40"
          routerLink="/profile/process-validation"
          (click)="opencamera()"
        >
          Oke
        </button>
      </div>
    </div>
  </div>
</div>
