<app-header></app-header>
<section class="globalContainer px15 pt70">
  <app-back text="Kembali"></app-back>
  <section class="formContainer pt-4">
    <form
      *ngIf="!showOtpForm"
      [formGroup]="changePasswordForm"
      (ngSubmit)="onSubmitChangePasswordForm()"
    >
      <h3 class="primaryBlackTitle mb-3 color-black">Ganti Password</h3>
      <div class="form-group">
        <label class="mb-2">Masukkan password baru</label>
        <input
          [type]="showpassword ? 'text' : 'password'"
          class="form-control"
          placeholder="*********"
          formControlName="newPassword"
          [ngClass]="{
            'is-invalid': submitted && changePasswordFormVal.newPassword.errors
          }"
        />
        <div class="eyeIcon">
          <i
            class="mdi mdi-eye-off"
            *ngIf="!showpassword"
            (click)="eyepassword()"
          ></i>

          <i
            class="mdi mdi-eye"
            *ngIf="showpassword"
            (click)="eyepassword()"
          ></i>
        </div>
        <span class="passwdNote">*Password minimal 8 Karakter</span>
        <div
          *ngIf="submitted && changePasswordFormVal.newPassword.errors"
          class="invalid-feedback"
        >
          <div *ngIf="changePasswordFormVal.newPassword.errors.required">
            Enter New Password
          </div>
          <div *ngIf="changePasswordFormVal.confirmNewPassword.errors.pattern">
            *Kata Sandi harus memiliki minimal 8 karakter, dengan kombinasi
            minimal 1 karakter numerik dan 1 karakter khusus
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="mb-2">Konfirmasi password baru</label>
        <input
          [type]="showpasswordNew ? 'text' : 'password'"
          class="form-control"
          placeholder="*********"
          formControlName="confirmNewPassword"
          [ngClass]="{
            'is-invalid':
              submitted && changePasswordFormVal.confirmNewPassword.errors
          }"
        />
        <div class="eyeIcon">
          <i
            class="mdi mdi-eye-off"
            *ngIf="!showpasswordNew"
            (click)="eyepasswordNew()"
          ></i>

          <i
            class="mdi mdi-eye"
            *ngIf="showpasswordNew"
            (click)="eyepasswordNew()"
          ></i>
        </div>
        <div
          *ngIf="submitted && changePasswordFormVal.confirmNewPassword.errors"
          class="invalid-feedback"
        >
          <div *ngIf="changePasswordFormVal.confirmNewPassword.errors.required">
            Enter Password
          </div>
          <div *ngIf="changePasswordFormVal.confirmNewPassword.errors.pattern">
            *Kata Sandi harus memiliki minimal 8 karakter, dengan kombinasi
            minimal 1 karakter numerik dan 1 karakter khusus
          </div>
          <div
            *ngIf="changePasswordFormVal.confirmNewPassword.errors.mustMatch"
          >
            Password confirmation does not match
          </div>
        </div>
      </div>
      <button type="submit" class="btn btnPurple br10 btn-block btn-50 my-5">
        Simpan
      </button>
    </form>

    <form
      [formGroup]="forgotOtpForm"
      (ngSubmit)="onSubmitOtpForm()"
      *ngIf="showOtpForm"
      class="mt-3"
    >
      <h3 class="primaryBlackTitle mb-3 text-center textPurple">
        Masukkan kode OTP
      </h3>

      <div class="form-group text-center mt-3">
        <ng-otp-input
          (onInputChange)="onOtpChange($event)"
          [config]="{ length: 6, placeholder: '0', allowNumbersOnly: true }"
          formControlName="otp"
          [ngClass]="{
            'is-invalid':
              (submitOtpForm && (otp == undefined || otp.length < 6)) ||
              invalidOtp
          }"
          #ngOtpInput
        >
        </ng-otp-input>

        <div
          *ngIf="submitOtpForm && OtpFormVal.otp.errors.required"
          class="text-danger pl-0 mt-2"
        >
          <div *ngIf="!activeClassButton">
            {{ "OTP.OTP_REQUIRED" | translate }}
          </div>
        </div>
        <div *ngIf="invalidOtp" class="text-danger pl-0 fs12">
          <div>Kode yang Anda masukkan salah</div>
        </div>
      </div>
      <button
        type="submit"
        [disabled]="lock"
        class="btn btnPurple br10 btn-block mt70 mb-3 btn-50"
      >
        Verifikasi
      </button>
      <span class="px-2 text-center d-block">
        <div *ngIf="!showResendOtp && !lock">
          Kirim ulang kode dalam waktu (<span
            class="contentSmall"
            *ngIf="beginTimer"
          >
            <countdown
              #cd
              [config]="{ leftTime: 60, format: 'mm:ss', notify: [2, 5] }"
              (event)="handleEvent($event)"
            ></countdown> </span
          >)<span>
            <!-- ( <span> {{ this.count }} </span>) -->
          </span>
        </div>

        <br />
        <a class="resend textPurple mb20" *ngIf="showResendOtp"
          ><span (click)="resendOTP()"> Kirim ulang kode </span>
        </a>
      </span>
    </form>
  </section>
</section>
<app-footer></app-footer>
