<nav class="headerContainer">
    <div class="row align-items-center h-100 ">
        <div class="col-10">
            <img class="logo" src="../../../assets/images/PediaSure_loyalty_logo.png">
        </div>
        <div class="col-2 text-right" >
            <img class="" src="../../../assets/images/closeiconwhite.png" *ngIf="showCloseIcon" (click)=" onClickCloseIcon()">
            <!-- <i class="mdi mdi-menu menuIcon" *ngIf="showHamburgerIcon" (click)="onclickHamburger()"></i> -->
            <img class="menu-icon" src="../../../assets/images/hamburger-menu.svg" *ngIf="showHamburgerIcon" (click)="onclickHamburger()" />
        </div>
    </div>
    <div *ngIf="showMenuItems" class="menuHeight">
        <ul class="menuItems">
            <li>
               <a href="https://pediasure.co.id/growthpedia"><img src="../../../assets/images/trending-up.png" >{{ 'HEADER.GROWTH' | translate }}</a> 
            </li>
            <li>
                <a href="https://pediasure.co.id/why-pediasure"><img src="../../../assets/images/emoji-happy.png" >{{ 'HEADER.KENAPA_PEDIASURE' | translate }}</a> 
             </li>
            <li>
                <a href="https://pediasure.co.id/product"><img src="../../../assets/images/box.png" >{{ 'HEADER.PRODUK' | translate }}</a> 
             </li>
             <li>
                <a href="https://pediasure.co.id/contact"><img src="../../../assets/images/mail.png">{{ 'HEADER.HUBUNGI' | translate }}</a> 
             </li>
             <li>
                <a href="https://pediasure.co.id/shop"><img src="../../../assets/images/shopping-cart.png" >{{ 'HEADER.BELANJA' | translate }}</a> 
             </li>
             <li>
                <a *ngIf="getAuthKey == null" routerLink="/login-signup"><img src="../../../assets/images/user.png" >{{ 'HEADER.MASUK' | translate }}</a>  
                <a *ngIf="getAuthKey != null" (click)="logout()"><img src="../../../assets/images/user.png" >Keluar</a>  
             </li>
             <li class="rewardPoints">
                <a routerLink="/rewards"><img src="../../../assets/images/Point.png" >{{ 'HEADER.REWARD' | translate }}</a> <img class="arrowImg" src="../../../assets/images/arrow-drop-right-line.png">
             </li>
             <li class="eBook">
                 <a href="https://pediasure.co.id/cerita-juara"><img src="../../../assets/images/book.png" > {{ 'HEADER.EBOOK' | translate }}</a>
             </li>
             <li>
                <a href="https://pediasure.co.id/articles"><img src="../../../assets/images/file.png" >{{ 'HEADER.ARTICLE' | translate }} </a>
            </li>
            <li>
                <a routerLink="/faq"><img src="../../../assets/images/message.png" >{{ 'HEADER.FAQ' | translate }} </a>
            </li>
            <li>
                <a routerLink="/faq/loyalty"><img src="../../../assets/images/abbottlogoheader.png" >{{ 'HEADER.ABOUTUS' | translate }}</a>
            </li>
        </ul>
        <p class="text-center mt-5"><a style="color: #fff;" href="https://pediasure.co.id/sitemap">{{ 'HEADER.SITEMAP' | translate }} </a></p>
    </div>
</nav>