import { SpinWheelComponent } from './spin-wheel/spin-wheel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TNCComponent } from './tnc/tnc.component';
import { InfoGameComponent } from './info-game/info-game.component';

const routes: Routes = [{ path: '', component: SpinWheelComponent },
{ path: 'tnc', component: TNCComponent} , { path: 'info', component: InfoGameComponent }];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpinWheelRoutingModule { }
