import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent implements OnInit {
  @Input() text = "";
  @Input() customClass = "";
  constructor(private location: Location) {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
  }

  onClickBack() {
    this.location.back();
  }

}
