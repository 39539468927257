import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PediasureTraceabilityComponent } from './pediasure-traceability/pediasure-traceability.component';

const routes: Routes = [
  {
    path: '',
    component: PediasureTraceabilityComponent
  },
  {
    path: 'traceability',
    component: PediasureTraceabilityComponent, data: { data: 2 }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TraceabilityRoutingModule { }
