import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BackComponent } from './back/back.component';
import { SuccessComponent } from './success/success.component';
import { SharedRoutingModule } from './shared-routing.module';
import { CameraComponent } from './camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { PopupModule } from '@ciri/ngx-popup';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BackComponent,
    SuccessComponent,
    CameraComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedRoutingModule,
    WebcamModule,
    PopupModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [HeaderComponent, FooterComponent, BackComponent, RouterModule],
})
export class SharedModule { }
