import { DashboardComponent } from './dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    //path: 'landing-page',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'traceability',
    loadChildren: () =>
      import('./traceability/traceability.module').then(
        (m) => m.TraceabilityModule
      ),
  },
  {
    path: 'login-signup',
    loadChildren: () =>
      import('./login-signup/login-signup.module').then(
        (m) => m.LoginSignupModule
      ),
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {  
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'rewards',
    loadChildren: () =>
      import('./rewards/rewards.module').then((m) => m.RewardsModule),
  },
  {
    path: 'game',
    loadChildren: () =>
      import('./spin-wheel/spin-wheel.module').then((m) => m.SpinWheelModule),
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./faq-privacy-tnc/faq-privacy-tnc.module').then(
        (m) => m.FaqPrivacyTncModule
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
