<app-header></app-header>
<section class="globalContainer pt70 pb70">

    <div class="successContainer text-center px15 pt40">
        <img class="img-fluid max-240" src="../../../assets/images/success.gif">

        <h5 class="mt12"> 🎁 Hadiah sedang diproses 🎁 </h5>
        <p class="content mt20">Terima Kasih telah menukarkan poinmu dengan hadiah. Hadiah akan diterima dalam waktu 2x24 jam</p>
        
        <button class="btn btnPurple br6 btn-block mt40" routerLink="/profile">Kembali ke Profil</button>

    </div>
</section>
<app-footer></app-footer>