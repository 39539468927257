import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/service/bugsnag.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotMobileForm: FormGroup;
  submittedMobileForm = false;
  forgotPasswordForm: FormGroup;
  showForgotMobileForm = true;
  submittedForgotForm = false;
  forgotOtpForm: FormGroup;
  submittedOtpForm = false;
  showForgotPasswordForm = false;
  showOtpForm = false;
  lock = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  submitOtpForm = false;
  showResendOtp: boolean = false;
  mobileno: any;
  resend = false;
  userNotFound: boolean = false;
  nonRegisteredUser: boolean = false;
  timeLeft: any;
  showResetForm = false;
  mobileNo: any;
  passwordError: boolean = false;
  count: number;
  beginTimer: boolean = false;
  showpassword: boolean = false;
  showSignUpPassword: boolean = false;
  hiddenBtn:boolean = false;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  sessionId: any;
  otpLength: number;
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private spinner: NgxSpinnerService, 
    private apiService: ApiService, 
    private bsService: BugsnagService
    ) {
    window.scrollTo({ top: 0 })
  }

  ngOnInit(): void {
    this.count = 3;
    this.beginTimer = true;
    this.showForgotMobileForm = true;
    this.forgotMobileForm = this.formBuilder.group({
      mobile: ['', Validators.required]
    })
    this.forgotPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$')]]
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });

    this.forgotOtpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    })
  }

  get mobileFormVal() {
    return this.forgotMobileForm.controls;
  }

  get OtpFormVal() {
    return this.forgotOtpForm.controls;
  }

  get forgotFormVal() {
    return this.forgotPasswordForm.controls;
  }

  resendOTP() {
    this.ngOtpInput.setValue('');
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: this.forgotPasswordForm.value.password,
      confirmPassword: this.forgotPasswordForm.value.confirmPassword,
    };


    this.spinner.show();
    let tokengenresp;
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        tokengenresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
        }
        this.showResendOtp = false;
        //this.ngOtpInput.setValue('');

        let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
        //this.tokenReGenerate();
        let otpGenerateData = {
          mobile: getMobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          sessionId: sessionIdValue,
        };
        let otpgenresp
        this.apiService
          .otpGenerate(otpGenerateData)
          .pipe(first())
          .subscribe(
            (res) => {
              otpgenresp = res;
              //window.alert('OTP sent');
              this.spinner.hide();
              if (res['status'].code == 200) {
              } else if (res['status'].code == 401) {
                this.apiService.logout();
              } else {
                this.apiService.showToaster(res['status'].message);
              }
            },
            (err) => {
              this.spinner.hide();
              console.log(err, '=== OTP gererate error list =======');
              this.spinner.hide();
              this.bsService.notifyError(err, otpGenerateData);
            }
          );
      },err=>{
        this.spinner.hide();
        this.bsService.notifyError(err, formData);
      });
  }

  onSubmitMobileForm() {
    this.submittedMobileForm = true;
    if (this.forgotMobileForm.invalid)
      return;
    else {
      let formValues = this.forgotMobileForm.value
      this.mobileNo = formValues.mobile
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": this.mobileNo,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
      }
      this.spinner.show();
      this.apiService.tokenGenerate(formData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            console.log(res);
            if (res['user'].userRegisteredForPassword == false) {
              this.userNotFound = true;
              this.showResendOtp = false;
              console.log('user not registered')
            }
            else {
              this.userNotFound = false;
              this.nonRegisteredUser = false;
              this.sessionId = res['user'].sessionId;
              sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
              this.showForgotMobileForm = false;
              this.showForgotPasswordForm = true;
              this.forgotMobileForm.reset();
              this.submittedMobileForm = false;

            }

          }
          else if (res['status'].code == 1520) {
            console.log('test')
            this.nonRegisteredUser = true;
          }
          else { }


        }, err =>{
          this.bsService.notifyError(err, formData);
        })
    }
  }

  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }

  }
  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false
    this.otp = otp;
    console.log('---', otp.length);
    this.otpLength = otp.length
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }
  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  onSubmitOtpForm() {
    this.submitOtpForm = true;
    if (this.otpLength != 6) {
      return;
    } else {
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": this.mobileNo,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId,
        "otp": this.otp
      }
      this.spinner.show();
      let otpvalresp;
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe(res => {
          otpvalresp = res;
          this.spinner.hide();
          if (res['status'].code == 200) {
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            this.router.navigateByUrl('/login')

            this.forgotOtpForm.reset();
            this.showOtpForm = false;
            this.beginTimer = false;
            this.router.navigate(['/login'])
          } else {
            this.invalidOtp = true;
            this.count = this.count - 1;
            if (this.count === 0) {
              this.lock = true;
            }

            this.count = this.count - 1;
          }
        }, err => {
          console.log(err)
          this.spinner.hide();
          this.bsService.notifyError(err, otpSubmitData);
        })
    }
  }

  onSubmitForgotPasswordForm() {
    this.hiddenBtn = true;
    this.submittedForgotForm = true;
    if (this.forgotPasswordForm.invalid) {
      this.hiddenBtn = false;
      return;
    }
    else {
      let formValues = this.forgotPasswordForm.value
      this.spinner.show();
      this.sessionId = JSON.parse(sessionStorage.getItem('sessionID'));

      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": this.mobileNo,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId,
        "password": formValues.password,
        "confirmPassword": formValues.confirmPassword
      }
      let forgotpass;

      this.apiService.forgotPassword(formData).pipe(first())
        .subscribe(res => {
          this.hiddenBtn = false;
          forgotpass = res;
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.passwordError = false;
            this.showForgotPasswordForm = false;
            this.showOtpForm = true;
            this.beginTimer = true;

            this.forgotPasswordForm.reset();
            this.submittedForgotForm = false;

            let otpformValue = this.forgotOtpForm.value

            let otpGenerateData = {
              "identifierType": "MOBILE",
              "identifierValue": this.mobileNo,
              "deviceId": env.DEVICE_ID,
              "brand": env.BRAND,
              "sessionId": this.sessionId
            }
            let otpGenerate;
            this.apiService.otpGenerate(otpGenerateData).pipe(first())
              .subscribe(res => {
                otpGenerate = res;
                this.spinner.hide();
                if (res['status'].code == 200) {

                  let formValues = this.forgotOtpForm.value;
                  console.log('inside else---')
                  console.log(this.forgotOtpForm.value.otp, 'this.forgotOtpForm.value.otp--')

                }
                else {

                }
              }, err => {
                this.spinner.hide();
                this.bsService.notifyError(err, otpGenerateData);
                console.log(err, '=== OTP gererate error list =======')

              })

          }
          else if (res['status'].code == 1527) {
            this.passwordError = true;
          }
          else {
            console.log(res['status'].message)
          }
        }, err => {
          this.spinner.hide();
          this.hiddenBtn = false;
          this.bsService.notifyError(err, formData);
          console.log(err)
        })


    }
  }

  eyepassword() {
    this.showpassword = !this.showpassword;


  }
  eyepassword1() {

    this.showSignUpPassword = !this.showSignUpPassword;

  }

}
