<app-header></app-header>

<section class="globalContainer voucherPage mb70">
  <div class="voucherTitle pt70 px20">
    <app-back customClass="text-white" text="Kembali"></app-back>
    <h1>Detail e-Voucher</h1>
  </div>

  <div class="eVoucher-container px20">
    <div
      class="voucher-card"
      *ngIf="
        data?.betterluck == 'true' ||
          data?.betterluck == 'false' ||
          dataList?.detail;
        else noData
      "
    >
      <h5>{{ voucherDetail?.name }}</h5>
      <span class="code-label">Kode Voucher</span>
      <div class="voucher-field">
        <p>{{ voucherDetail?.code }}</p>
        <span
          class="copy-code"
          id="tooltip"
          data-toggle="tooltip"
          title="kode disalin"
          ngxClipboard
          [cbContent]="voucherDetail?.code"
        >
          <img src="../../../assets/images/copy-icon.png" alt="copy" />
          salin
        </span>
      </div>
      <div class="vouch-label">
        <div class="label">
          <p>Issued Date</p>
        </div>
        <div class="result">
          <p>{{ voucherDetail?.issuedDate }}</p>
        </div>
      </div>
      <div class="vouch-label">
        <div class="label">
          <p>Besar Nominal</p>
        </div>
        <div class="result">
          <p>{{ cost[0] + " " + cost[1] }}</p>
        </div>
      </div>
      <div class="vouch-label">
        <div class="label">
          <p>Berlaku Hingga</p>
        </div>
        <div class="result">
          <p>{{ voucherDetail?.expiryDate | date: "dd MMMM yyyy" }}</p>
        </div>
      </div>
    </div>
    <ng-template #noData>
      <div class="voucher-card">
        <div class="divide" *ngFor="let voucher of voucherDetail1">
          <h5>{{ voucher?.seriesName }}</h5>
          <span class="code-label">Kode Voucher</span>
          <div class="voucher-field">
            <p>{{ voucher?.code }}</p>
            <span
              class="copy-code"
              data-toggle="tooltip"
              title="kode disalin"
              ngxClipboard
              [cbContent]="voucher?.code"
            >
              <img src="../../../assets/images/copy-icon.png" alt="copy" />
              salin
            </span>
          </div>
          <div class="vouch-label">
            <div class="label">
              <p>Issued date</p>
            </div>
            <div class="result">
              <p>{{ voucher?.issued | date: "dd MMMM yyyy" }}</p>
            </div>
          </div>
          <div class="vouch-label">
            <div class="label">
              <p>Besar Nominal</p>
            </div>
            <div class="result">
              <p>{{ voucher?.seriesName.split(" ").splice(-2) }}</p>
            </div>
          </div>
          <div class="vouch-label">
            <div class="label">
              <p>Berlaku Hingga</p>
            </div>
            <div class="result">
              <p>{{ voucher?.expiry | date: "dd MMMM yyyy" }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="voucher-terms">
      <h4>Cara pemakaian :</h4>
      <ul *ngIf="firstWord == 'Tokopedia'">
        <li>Install aplikasi Tokopedia di Playstore/Appstore</li>
        <li>Register di aplikasi Tokopedia</li>
        <li>
          Setelah terdaftar klik pencarian, dan ketik <b>Tokopedia Giftcard.</b>
        </li>
        <li>
          Setelah sudah menerima kode, input kode tersebut di kolom
          <b>Redeem Giftcard.</b>
        </li>
        <li>
          Pastikan nomer HP aktif karena akan ada notifikasi untuk redeem
          tersebut dan setelah itu otomatis masuk untuk evouchernya
        </li>
      </ul>
      <ul *ngIf="firstWord == 'Grabfood' || firstWord == 'GrabFood'  || firstWord == 'Grab'">
        <li>Pilih menu kesukaan Anda di Grabfood</li>
        <li>Di halaman keranjang, klik <b>Promo.</b></li>
        <li>Input kode voucher yang didapatkan</li>
        <li>Selesaikan pesanan Anda</li>
      </ul>
      <ul *ngIf="firstWord == 'Alfamart'">
        <li>Bawa kode voucher dan tunjukan ke kasir Alfamart terdekat</li>
        <li>Tidak bisa dipakai di online merchant / ecommerce</li>
        <li>
          E-Voucher hanya bisa digunakan dan ditukarkan di merchant offline
        </li>
      </ul>
      <ul *ngIf="firstWord == 'Blibli'">
        <li>Install Aplikasi Blibli di Playstore / App store</li>
        <li>Register di aplikasi untuk login</li>
        <li>Pesan barang yang ingin dibeli</li>
        <li>
          Setelah check out sebelum pembayaran klik redeem voucher, lalu ketikan
          kode voucher BliBli tersebut dan otomatis akan mengurangi dari tagihan
          yang ingin dibayarkan
        </li>
      </ul>
    </div>
    <div class="voucher-terms">
      <h4>Syarat dan Ketentuan :</h4>
      <ul *ngIf="firstWord == 'Tokopedia'">
        <li>Kode voucher hanya bisa digunakan di aplikasi Tokopedia</li>
        <li>
          Kode voucher yang sudah di redeem hanya bisa 1 kali pemakaian tidak
          bisa berulang
        </li>
        <li>
          Kode voucher yang sudah di redeem akan masuk di Ovo point / Toko point
          Tokopedia
        </li>
      </ul>
      <ul *ngIf="firstWord == 'Grabfood'  || firstWord == 'Grab'">
        <li>
          Kode voucher hanya bisa digunakan di aplikasi Grab (khusus pembelian
          Grab food saja)
        </li>
        <li>
          Kode voucher yang sudah di redeem hanya bisa 1 kali pemakaian tidak
          bisa berulang
        </li>
        <li>
          Kode voucher yang sudah di redeem akan akan langsung potongan diskon
          dari pemesanan Grab food
        </li>
      </ul>
      <ul *ngIf="firstWord == 'Alfamart'">
        <li>
          Voucher belanja Alfamart Digital harus ditunjukkan melalui media
          digital seperti SMS,WA,Email,App (tidak boleh dicetak / print)
        </li>
        <li>
          1 Kode voucher hanya dapat dipergunakan 1 kali pertransaksi (sekaligus
          habis) sesuai dengan nilai voucher
        </li>
        <li>
          Nilai Voucher Belanja Alfamart Digital tidak dapat ditukar maupun
          dikembalikan dengan uang tunai apabila belanja lebih kecil nilainya
        </li>
        <li>
          Jika nilai belanja lebih besar, kekurangan dapat dilakukan dengan
          pembayaran Cash/Tunai
        </li>
        <li>
          Saat pengunaan Voucher Alfamart Digital tidak dapat digabung dengan
          pembayaran voucher fisik.
        </li>
        <li>
          Alfamart tidak bertanggung jawab atas kode Voucher Belanja Digital
          yang hilang maupun sudah kadaluwarsa.
        </li>
      </ul>
      <ul *ngIf="firstWord == 'Blibli'">
        <li>Kode voucher hanya bisa digunakan di aplikasi Blibli</li>
        <li>
          Kode voucher yang sudah di redeem hanya bisa 1 kali pemakaian tidak
          bisa berulang
        </li>
        <li>
          Kode voucher yang sudah di redeem tidak bisa dipakai ke dua kali
        </li>
        <li>
          Kupon berlaku untuk seluruh produk di
          <a
            href="https://www.blibli.com/"
            target="_blank"
            rel="noopener noreferrer"
            >blibli.com</a
          >
          kecuali susu formula dibawah 1 tahun dan & korek api
        </li>
      </ul>
    </div>
  </div>
</section>

<app-footer></app-footer>
