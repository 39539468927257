<app-header></app-header>
<section class="globalContainer px15 pt70 pb70">
    <app-back text="Kembali"></app-back>
    <section class="faqContainer">
        <h4 class="mt30 faqTitle">Punya pertanyaan? <br/>Yuk simak jawaban di bawah ini</h4>
        <h5 class="mt-4 mb20">Tentang :</h5>

        <ul class="nav nav-pills mb-3 mx--10 faqPills justify-content-center" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active btn-block" position-relative id="pills-loyalty-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-loyalty" type="button" role="tab" aria-controls="pills-loyalty"
                    aria-selected="false" (click)="collapse=!collapse"><img class="img-fluid"
                        src="../../../assets/images/loyaltyproductimage.png">
                    <span class="position-absolute"></span></button>
                <p class="tabDesc">PediaSure Loyalty Program</p>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link btn-block position-relative" id="pills-points-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-points" type="button" role="tab" aria-controls="pills-points"
                    aria-selected="false" (click)="collapse=!collapse"><img class="img-fluid"
                        src="../../../assets/images/lotaltyscanner.png"><span class="position-absolute"></span></button>
                <p class="tabDesc">Pengumpulan Poin</p>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link btn-block position-relative" id="pills-gift-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-gift" type="button" role="tab" aria-controls="pills-gift"
                    aria-selected="false" (click)="collapse=!collapse"><img class="img-fluid"
                        src="../../../assets/images/loyaltygiftactive.png"><span
                        class="position-absolute"></span></button>
                <p class="tabDesc">Penukaran Hadiah</p>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-loyalty" role="tabpanel"
                aria-labelledby="pills-loyalty-tab">
                <p>Tentang PediaSure Loyalty Program</p>

                <div class="questionsBlock mt-2">

                    <div class="accordion" id="accordionExample">
                        <div class="card mb-2" *ngFor="let faq of faqData1; let i = index">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block  collapsed collapseBtn" type="button"
                                        data-toggle="collapse" [attr.data-target]="'#collapse'+i+faq?.active"  aria-expanded="false"
                                        aria-controls="collapseOne">
                                        {{faq?.questions}}
                                    </button>
                                </h2>
                            </div>

                            <div id="collapse{{i}}{{faq?.active}}" class="collapse" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    {{faq?.answers}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-points" role="tabpanel" aria-labelledby="pills-points-tab">
                <p>Tentang Pengumpulan Poin</p>
                <div class="questionsBlock mt-2">
                    <div class="accordion" id="accordionExample">
                        <div class="card mb-2" *ngFor="let faq of faqData2; let j = index;">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block  collapsed collapseBtn" type="button"
                                        data-toggle="collapse" [attr.data-target]="'#collapse'+j+faq?.active" aria-expanded="false"
                                        aria-controls="collapseOne">
                                        {{faq?.questions}}
                                    </button>
                                </h2>
                            </div>

                            <div id="collapse{{j}}{{faq?.active}}" class="collapse" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    {{faq?.answers}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-gift" role="tabpanel" aria-labelledby="pills-gift-tab">
                <p>Tentang Pengumpulan Poin</p>
                <div class="questionsBlock mt-2">
                    <div class="accordion" id="accordionExample">
                        <div class="card mb-2" *ngFor="let faq of faqData3; let i = index">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block  collapsed collapseBtn" type="button"
                                        data-toggle="collapse" [attr.data-target]="'#collapse'+i+faq?.active"  aria-expanded="false"
                                        aria-controls="collapseOne">
                                        {{faq?.questions}}
                                    </button>
                                </h2>
                            </div>

                            <div id="collapse{{i}}{{faq?.active}}" class="collapse" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    {{faq?.answers}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h5 class="mt-4">Punya pertanyaan lain?</h5>
        <button class="btn btnPurple br10 mt-3 btn-50 w-100"><a href="https://pediasure.co.id/contact" style="color: #fff;"> Hubungi Kami </a></button>
    </section>
</section>
<app-footer></app-footer>