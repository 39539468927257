<app-header></app-header>
<section class="globalContainer pt70 px15">
  <div class="mb-2 mt-3 successMessage" *ngIf="profileUpdate">
    <span><i class="mdi mdi-check"></i>Profil anak berhasil diperbarui</span>
  </div>
  <app-back text="Kembali"></app-back>

  <div class="text-center mt-4" *ngIf="datanotfound">
    <img class="" src="../../../assets/images/kiddatanotfound.png" />
    <h5 class="mt40 textBlack">Data si Kecil tidak ditemukan</h5>

    <p class="textDarkGrey">
      Maaf, Anda belum mengisi data si Kecil. Ayo isi sekarang untuk melengkapi
      profile Anda
    </p>
  </div>
  <div class="kidprofileContainer" *ngIf="showCard">
    <!-- kid data not found -->
    <h3 *ngIf="allKids.length > 0" class="primaryBlackTitle mt30 mb-4">
      Data si Kecil
    </h3>
    <div *ngFor="let point of allKids; let i = index">
      <div class="card mt-3">
        <span class="edit" (click)="onClickEditData(point, i)">Edit Data</span>
        <!-- <span (click)="delete(point, i)">
          <img class="deleteIcon" src="../../../assets/images/Delete.png" />
        </span> -->

        <p class="textGrey fW600">Nama Anak</p>
        <p>{{ point.babys_name }} {{ point.babys_lastname }}</p>
        <!-- <p class="textGrey">Jenis Kelamin</p> -->
        <p class="textGrey fW600">Tanggal Lahir</p>
        <p>{{ point.babys_age }}</p>
        <p class="textGrey fW600">Usia Anak</p>
        <p>{{ point.years }} Tahun</p>
      </div>
    </div>
    <button
      *ngIf="childdata.length < 3"
      type="submit"
      (click)="onAddNew()"
      [disabled]="addNew"
      class="btn btnPurple br10 btn-block mt-5 btn-50 mb70"
    >
      <i class="mdi mdi-plus pr-2 fs20"></i>Tambah data si Kecil
    </button>
  </div>

  <div class="formContainer pt-0 pb70" *ngIf="showChildNewForm">
    <h3 class="primaryBlackTitle mt40 mb-3">Data si Kecil</h3>

    <form [formGroup]="editChildForm" (ngSubmit)="onSubmitChildEditForm()">
      <div class="form-group">
        <label>Nama depan anak</label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan nama depan anak"
          [ngClass]="{
            'is-invalid':
              submittedChildForm && editChildFormVal.childFirstName.errors
          }"
          formControlName="childFirstName"
        />
        <div
          *ngIf="submittedChildForm && editChildFormVal.childFirstName.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editChildFormVal.childFirstName.errors.required">
            Enter first name
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Nama belakang anak</label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan nama belakang anak"
          [ngClass]="{
            'is-invalid':
              submittedChildForm && editChildFormVal.childLastName.errors
          }"
          formControlName="childLastName"
        />
        <div
          *ngIf="submittedChildForm && editChildFormVal.childLastName.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editChildFormVal.childLastName.errors.required">
            Enter last name
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Tanggal lahir </label>
        <input
          type="text"
          placeholder="Pilih tanggal lahir Anda"
          class="form-control"
          formControlName="dob"
          [maxDate]="maxDate"
          bsDatepicker
          [bsConfig]="{ isAnimated: true }"
          [ngClass]="{
            'is-invalid': submittedChildForm && editChildFormVal.dob.errors
          }"
        />
        <div class="eyeIcon calendarIcon">
          <!-- <i class="mdi mdi-calendar"></i> -->
          <img src="../../../assets/images/calendar-icon.png" alt="calendar" />
        </div>
        <div
          *ngIf="submittedChildForm && editChildFormVal.dob.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editChildFormVal.dob.errors.required">select date</div>
        </div>
      </div>

      <button
        type="submit"
        (click)="onsave()"
        class="btn btnPurple br10 btn-block mt-5 btn-50"
      >
        Simpan
      </button>
    </form>
  </div>

  <div class="formContainer pt-0 pb70" *ngIf="showChildEditForm">
    <form [formGroup]="editChildForm" (ngSubmit)="onSubmitChildEditForm()">
      <div class="form-group">
        <label>Nama depan anak</label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan nama depan anak"
          [ngClass]="{
            'is-invalid':
              submittedChildForm && editChildFormVal.childFirstName.errors
          }"
          formControlName="childFirstName"
        />
        <div
          *ngIf="submittedChildForm && editChildFormVal.childFirstName.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editChildFormVal.childFirstName.errors.required">
            Enter first name
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Nama belakang anak</label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan nama belakang anak"
          [ngClass]="{
            'is-invalid':
              submittedChildForm && editChildFormVal.childLastName.errors
          }"
          formControlName="childLastName"
        />
        <div
          *ngIf="submittedChildForm && editChildFormVal.childLastName.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editChildFormVal.childLastName.errors.required">
            Enter last name
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Tanggal lahir </label>
        <input
          type="text"
          placeholder="Pilih tanggal lahir Anda"
          class="form-control"
          formControlName="dob"
          [bsValue]="minDate"
          [maxDate]="maxDate"
          [minDate]="minDate"
          bsDatepicker
          [bsConfig]="{ isAnimated: true }"
          [ngClass]="{
            'is-invalid': submittedChildForm && editChildFormVal.dob.errors
          }"
        />
        <div class="eyeIcon calendarIcon">
          <!-- <i class="mdi mdi-calendar"></i> -->
          <img src="../../../assets/images/calendar-icon.png" alt="calendar" />
        </div>
        <div
          *ngIf="submittedChildForm && editChildFormVal.dob.errors"
          class="invalid-feedback"
        >
          <div *ngIf="editChildFormVal.dob.errors.required">select date</div>
        </div>
      </div>

      <button
        type="submit"
        (click)="onEdit()"
        class="btn btnPurple br10 btn-block mt-5 btn-50"
      >
        Simpan
      </button>
    </form>
  </div>
</section>
<app-footer></app-footer>
