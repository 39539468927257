import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent implements OnInit {
  aboutLoyality:any;
  termsHeading:any;
  constructor(
    private spinner: NgxSpinnerService,
    private route:ActivatedRoute,
    private APIService: ApiService,
    private bsService: BugsnagService
    ) {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
    this.getAboutLoyality();
  }

  getAboutLoyality(){
    this.spinner.show();
    this.APIService.getcontentBlocks().subscribe((res:any)=>{
      this.spinner.hide();
      let aboutLoyality = res.data.filter(element => element.key == 'About Loyalty program');
      this.aboutLoyality = aboutLoyality[0];
      console.log(this.aboutLoyality, 'aboutLoyality')
      
    },err=>{
      this.spinner.hide();
      this.bsService.notifyError(err);
    })
  }

}
