
<app-header></app-header>

<section class="globalContainer position-relative pt70">
    <div class="blocker-main text-center mt30">
        <div class="block-Img mb25">
            <img class="max-180" src="../../../../assets/images/blocker.png">
        </div>
        <div class="blockTitle">
            <h5>Maaf, Akun Anda terblokir sementara</h5>
            <p class="mt10">Akun Anda terblokir sementara waktu dikarenakan terdeteksi adanya aktivitas mencurigakan dalam proses pengambilan poin.
            Hubungi ke customer service Pediasure untuk verifikasi agar bisa aktifkan kembali akun Anda.</p>

            <div class="contact-Btn">
                <a href="https://pediasure.co.id/contact" class="btn btnPurple br10 btn-50 w-100 mt-3 mb10">Hubungi Kami</a>
                <a (click)="logout()" class="mt-3 logoutBtn mb70">Keluar dari akun</a>
            </div>
        </div>
    </div>
</section>
<footer class="">
    <app-footer></app-footer>
</footer>