import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { BugsnagService } from 'src/app/service/bugsnag.service';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  myProfileForm: FormGroup;
  submitted = false;
  customerData: any;
  profileForm: any;
  dob: any;
  role: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router, 
    private bsService: BugsnagService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.customerData = navigation?.extras?.state?.customer;
    window.scrollTo({top: 0})
  }

  ngOnInit(): void {
    this.myProfileForm = this.formBuilder.group({
      gender: [''],
      dob: [''],
    });

    this.dob = '';

    this.customerData.extended_fields.field.forEach((element) => {
      if (element.name === 'dob') {
        this.dob = element.value;
      }
      if (element.name === 'role') {
        this.role = element.value;
      }
    });
    this.customerData.custom_fields.field.forEach((element) => {
      if (element.name === 'role') {
        this.role = element.value;
      }
    });

    // if (this.customerData.extended_fields.dob) {
    // this.dob = this.customerData.extended_fields.dob;
    console.log(this.dob);
    this.myProfileForm.patchValue({
      gender: this.role,
      dob: this.dob !== '' ? moment(this.dob).format('DD/MM/YYYY') : null,
    });
  }

  get myProfileFormVal() {
    return this.myProfileForm.controls;
  }

  getuserForm(userData) {
    return (this.profileForm = {
      root: {
        customer: [
          {
            mobile: userData.mobile,
            custom_fields: {
              field: [
                {
                  name: 'role',
                  value: userData.gender,
                },
                {
                  name: 'dob',
                  value: moment(userData.dob).format('YYYY-DD-MM'),
                }
              ],
            },
            extended_fields: {
              field: [

              ],
            },
          },
        ],
      },
    });
  }

  onSubmitProfileForm() {
    this.submitted = true;
    if (this.myProfileForm.invalid) {
      return;
    } else {
      let userData = this.myProfileForm.value;
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      userData.mobile = getMobile;
      let filledFormValues = this.getuserForm(userData);
      this.spinner.show();
      let updatecustresp;
      this.apiService
        .updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            updatecustresp = res;
            this.spinner.hide();
            if (res['status'].code == 200) {
              console.log('customer updated');
              this.router.navigate(['/profile']);
            }
          },
          (err) => {
            this.spinner.hide();
            this.bsService.notifyError(err, filledFormValues);
            console.log(err, '=== customer update error =======');
          }
        );
    }
  }
}
