import { identifierModuleUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { CountrylistService } from 'src/app/shared/countrylist.service';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/service/bugsnag.service';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  addAddressForm: FormGroup;
  submitted = false;
  customerData: any;
  addressForm: any;
  //showCard = false;
  showAddressCard = false;
  //showAddressForm: boolean = false;
  showAddAddressImage = false;
  showAddressForm = false;
  addressData = {
    recipientsName: '',
    phoneNumber: '',
    address: '',
    ward: '',
    countyDown: '',
    district: '',
    province: '',
    postalCode: ''
  }
  countryList: any;
  mainProvienceData: any;
  provinceList = [];
  countyDownList = [];
  county = [];
  district = [];
  ward = [];
  //showAddressFormOnAdd = false;

  constructor(
    private formBuilder: FormBuilder, 
    private apiService: ApiService, 
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private countryListData: CountrylistService,
    private bsService:BugsnagService
    ) {
    window.scrollTo({ top: 0 })
    const navigation = this.router.getCurrentNavigation();
    this.customerData = navigation.extras.state.customer;
    this.displayAddressForm(this.customerData)
    this.countryList = this.countryListData.jsondata01[0];
    this.mainProvienceData = Object.values(this.countryList);
    this.mainProvienceData[0].forEach(p => this.provinceList.push(p));
    this.provinceList.filter(p => {
      if (p.province_name == this.addressData.province) {
        this.county = p.regency_list
      }
    })
    if (this.provinceList) {
      this.county.filter(c => {
        if (c.regency_name == this.addressData.countyDown) {
          this.district = c.district_list
        }
      })
    }
    if (this.county) {
      this.district.filter(d => {
        if (d.district_name == this.addressData.district) {
          this.ward = d.each_village_list
        }
      })
    }




  }


  ngOnInit() {
    window.scrollTo({ top: 0 });

    this.addAddressForm = this.formBuilder.group({
      recipientsName: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('^((\\+63-?)|0)?[0-9]{9,12}$')]],
      address: ['', Validators.required],
      province: ['', Validators.required],
      countyDown: ['', Validators.required],
      // districts: ['', Validators.required],
      ward: ['', Validators.required],
      // urbanVillage: ['', Validators.required],
      // province: ['', Validators.required],
      district: ['', Validators.required],
      // city: ['', Validators.required],
      postalCode: ['', Validators.required]
    })
  }

  provinceChange(e) {
    let str = e.target.value.split(":");
    this.provinceList.filter(val => {
      if (val.province_name == str[1].trim()) {
        this.county = val.regency_list;
        this.addAddressForm.patchValue(
          { province: str[1].trim() }
        )
      }
    })
  }

  countyChange(e) {
    let str = e.target.value.split(":");
    this.county.filter(val => {
      if (val.regency_name == str[1].trim()) {
        this.district = val.district_list;
        this.addAddressForm.patchValue(
          { countyDown: str[1].trim() }
        )
      }
    })
  }

  districtChange(e) {
    let str = e.target.value.split(":");
    this.district.filter(val => {
      if (val.district_name == str[1].trim()) {
        this.ward = val.each_village_list;
        this.addAddressForm.patchValue(
          { district: str[1].trim() }
        )
      }
    })
  }

  wardChange(e) {
    let str = e.target.value.split(":");
    this.addAddressForm.patchValue(
      { ward: str[1].trim() }
    )
  }

  displayAddressForm(customerData) {
    let customAddressfields: any = []
    customerData.custom_fields.field.forEach(({ name }) => {
      customAddressfields.push(name);
    });;
    // let includedAddressFields = ['receipient_name', 'province', 'city', 'village', 'postal_code', 'street_name', 'district', 'recipient_phone']
    let includedAddressFields = ['receipient_name', 'recipient_phone']

    let addressFieldNotFound = false;
    includedAddressFields.forEach(ele => {
      console.log(ele, 'ele')
      if (customAddressfields.indexOf(ele) === -1) {
        console.log("ele: ", ele);
        addressFieldNotFound = true;
        return;
      }
    })
    console.log("addressFieldNotFound: ", addressFieldNotFound)
    if (addressFieldNotFound) {
      this.showAddAddressImage = true;
      //this.showAddressForm = true;
    } else {
      this.showAddressCard = true;
      customerData.custom_fields.field.forEach(ele => {
        if (ele.name === 'receipient_name') this.addressData.recipientsName = ele.value
        if (ele.name === 'province') this.addressData.province = ele.value
        if (ele.name === 'village') this.addressData.ward = ele.value
        if (ele.name === 'city') this.addressData.countyDown = ele.value
        if (ele.name === 'postal_code') this.addressData.postalCode = ele.value
        if (ele.name === 'street_name') this.addressData.address = ele.value
        if (ele.name === 'district') this.addressData.district = ele.value
        if (ele.name === 'recipient_phone') this.addressData.phoneNumber = ele.value
      })
    }
  }



  get addressFormVal() {
    return this.addAddressForm.controls;
  }
  onSubmitAddAddressForm() {
    this.submitted = true;
    if (this.addAddressForm.invalid) {
      return;
    } else {
      let addressData = this.addAddressForm.value;
      let filledFormValues = this.getUserForm(addressData);
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      this.spinner.show();
      let updatetransresp;
      this.apiService.updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            updatetransresp = res;
            if (res['status'].code == 200) {
              this.spinner.hide();
              //  this.showAddressForm = false
              this.addressData.recipientsName = addressData.recipientsName;
              this.addressData.phoneNumber = addressData.phoneNumber;
              this.addressData.address = addressData.address;
              this.addressData.ward = addressData.ward;
              this.addressData.countyDown = addressData.countyDown;
              this.addressData.district = addressData.district;
              this.addressData.province = addressData.province;
              this.addressData.postalCode = addressData.postalCode;
              //    this.showAddressFormOnAdd = false;
              //   this.showCard = true;
              this.showAddressForm = false;
              this.showAddressCard = true;
            }

          }
        ),
        (err) => {
          this.spinner.hide();
          this.bsService.notifyError(err, filledFormValues);
          console.log(err, '=== customer update error =======');

        }
    }
  }

  getUserForm(addressData) {
    return (this.addressForm = {
      root: {
        customer: [
          {
            mobile: this.customerData.mobile,
            custom_fields: {
              field: [
                {
                  name: 'receipient_name',
                  value: addressData.recipientsName,
                },
                {
                  name: 'recipient_phone',
                  value: addressData.phoneNumber,
                },
                {
                  name: 'province',
                  value: addressData.province,
                },
                {
                  name: 'postal_code',
                  value: addressData.postalCode,
                },
                {
                  name: 'district',
                  value: addressData.district,
                },
                {
                  name: 'street_name',
                  value: addressData.address,
                },
                {
                  name: 'village',
                  value: addressData.ward,
                },
                {
                  name: 'city',
                  value: addressData.countyDown,
                }
              ],
            },
          },
        ],
      },
    });
  }

  onClickAddAddress() {
    this.showAddAddressImage = false;
    this.showAddressForm = true;
    //  this.showAddressFormOnAdd = true;
  }


  onClickEditAddress() {
    this.showAddressCard = false;
    this.showAddressForm = true;
    // this.showCard = false;
    // this.showAddressFormOnAdd = true;
    this.addAddressForm.patchValue({
      recipientsName: this.addressData.recipientsName,
      phoneNumber: this.addressData.phoneNumber,
      address: this.addressData.address,
      province: this.addressData.province,
      ward: this.addressData.ward,
      countyDown: this.addressData.countyDown,
      district: this.addressData.district,
      postalCode: this.addressData.postalCode
    })
  }
}
