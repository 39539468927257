import { Component, OnInit } from '@angular/core';
import { PopoverModule, PopoverConfig } from 'ngx-bootstrap/popover';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
declare var $:any;

@Component({
  selector: 'app-level-member',
  templateUrl: './level-member.component.html',
  styleUrls: ['./level-member.component.scss']
})
export class LevelMemberComponent implements OnInit {
  
  max: number = 100;
  value: number = 35;
  customerPoints:any;
  constructor(
    private spinner: NgxSpinnerService,
    private apiService: ApiService
  ) {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
    $('.toolTip').tooltip()
    this.getCustomer()
  }

  getCustomer() {
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService
      .getCapCustomer(getMobile, getAuthKey)
      .pipe(first())
      .subscribe((res) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          //console.log("Result", res);
          this.customerPoints = res['customers']['customer'][0]?.lifetime_points;
          //this.customerPoints = 1600;
          console.log(this.customerPoints, 'this.customerData')
         
        }
      });
  }

}
