<section class="privacyData">
<app-header></app-header>
    <section class="globalContainer pt70 px15">
        <app-back text="Kembali"></app-back>
        <div class="text-center">
            <img class="img-fluid max-240" [src]="termsData?.associatedfile">
        </div>
    <div class="privacy-policy-main termsPage">
        <div class="privacyContent" [innerHTML]="termsData?.contentvalue | safe: 'html'">
        </div>
    </div>
    </section>

<app-footer></app-footer>
</section>