import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { element } from 'protractor';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/service/bugsnag.service';
@Component({
  selector: 'app-kid-profile',
  templateUrl: './kid-profile.component.html',
  styleUrls: ['./kid-profile.component.scss'],
})
export class KidProfileComponent implements OnInit, OnDestroy {
  editChildForm: FormGroup;
  submittedChildForm = false;
  showCard = true;
  showChildEditForm = false;
  customerData: any;
  submitted = false;
  profileForm: any;
  showChildNewForm = false;
  childdata = [];
  allKids = [];
  editData: any = {};
  childOne = true;
  childTwo = true;
  childThree = true;
  addNew = false;
  datanotfound = false;
  kidsFields = [];
  kidsExtentedFields: string[];
  includedExtentFields: string[];
  includedCustomFields: string[];
  profileUpdate: boolean = false;
  minDate: any;
  maxDate: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 })
    const navigation = this.router.getCurrentNavigation();
    this.customerData = navigation?.extras?.state?.customer;
  }
  ngOnDestroy(): void {
    // this.childdata = [];
    // this.allKids = []
    sessionStorage.setItem("kidsLength", JSON.stringify(this.allKids?.length))
    console.log(JSON.parse(sessionStorage.getItem("kidsLength")), 'get kida length')

  }

  ngOnInit(): void {
    this.maxDate = moment();
    this.maxDate = new Date(this.maxDate)
    this.minDate = moment().subtract(21, 'years')
    this.minDate = new Date(this.minDate);
    this.editChildForm = this.formBuilder.group({
      childFirstName: ['', Validators.required],
      childLastName: ['', Validators.required],
      dob: ['', Validators.required],
    });

    let a = {};
    let b = {};
    let c = {};

    let extendedfields: any = [];

    if (this.customerData) {
      this.customerData.extended_fields.field.forEach(({ name }) => {
        extendedfields.push(name);
      });;
    } else {
      this.router.navigate(['/profile']);
      return;
    }


    this.includedExtentFields = ['child1_dob', 'child2_dob', 'child3_dob'];


    this.includedExtentFields.forEach(feild => {
      if (extendedfields.indexOf(feild) == -1) {
        this.customerData.extended_fields.field.push({
          "name": feild,
          "value": ""
        })
      }
    })

    let customfields: any = [];
    this.customerData.custom_fields.field.forEach(({ name }) => {
      customfields.push(name);
    });;

    this.includedCustomFields = ['child1_name', 'child2_name', 'child3_name', 'child1_lastname',
      'child2_lastname', 'child3_lastname'];

    this.includedCustomFields.forEach(feild => {
      if (customfields.indexOf(feild) == -1) {
        this.customerData.custom_fields.field.push({
          "name": feild,
          "value": ""
        })
      }
    })

    this.customerData.extended_fields.field.forEach(element => {


      if (element.name == 'child1_dob') {
        a['babys_age'] = moment(element.value).format('DD/MM/YYYY');
      }

      if (element.name == 'child2_dob') {
        b['babys_age'] = moment(element.value).format('DD/MM/YYYY')
      }

      if (element.name == 'child3_dob') {
        c['babys_age'] = moment(element.value).format('DD/MM/YYYY')
      }
    });

    this.customerData.custom_fields.field.forEach((element) => {
      if (element.name === 'child1_name') {
        a['babys_name'] = element.value;
      }
      if (element.name === 'child2_name') {
        b['babys_name'] = element.value;
      }
      if (element.name === 'child3_name') {
        c['babys_name'] = element.value;
      }

      if (element.name === 'child1_lastname') {
        a['babys_lastname'] = element.value;
      }
      if (element.name === 'child2_lastname') {
        b['babys_lastname'] = element.value;
      }
      if (element.name === 'child3_lastname') {
        c['babys_lastname'] = element.value;
      }

    });

    if (!this.isEmptyObj(a) && a.hasOwnProperty('babys_name') && !(a['babys_name'] == '')) {
      var years = moment().diff(moment(a['babys_age'], "DD-MM-YYYY"), 'years')
      a['years'] = years;
      this.childdata.push(a);
      a['id'] = 1
    }
    if (!this.isEmptyObj(b) && b.hasOwnProperty('babys_name') && !(b['babys_name'] == '')) {
      var years = moment().diff(moment(b['babys_age'], "DD-MM-YYYY"), 'years')
      b['years'] = years;
      this.childdata.push(b);
      b['id'] = 2
    }
    if (!this.isEmptyObj(c) && c.hasOwnProperty('babys_name') && !(c['babys_name'] == '')) {
      var years = moment().diff(moment(c['babys_age'], "DD-MM-YYYY"), 'years')
      c['years'] = years;
      this.childdata.push(c);
      c['id'] = 3
    }


    this.allKids = this.childdata;
    console.log("all kids: ", this.allKids)

    if (this.childdata.length > 2) {
      this.addNew = true;
    } else if (this.childdata.length === 0) {
      this.datanotfound = true;
    }

  }


  isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
  }


  delete(point, index) {
    this.editData = point;
    console.log('ss', this.editData);
    let getMobile = sessionStorage.getItem('mobileNo');
    let customerRoot = {
      root: {
        customer: [
          {
            mobile: getMobile,
            custom_fields: {
              field: [
                {
                  name: `child${this.editData.id}_name`,

                  value: '',
                }, {
                  name: `child${this.editData.id}_lastname`,

                  value: '',
                },

              ],
            },
            extended_fields: {
              field: [
                {
                  name: `child${this.editData.id}_dob`,

                  value: '',
                }
              ],
            },
          },
        ],
      },
    };
    this.spinner.show();
    console.log("del: ", customerRoot);
    let token = sessionStorage.getItem('authToken');
    let updatecustresp;
    this.apiService
      .updateCapCustomer(customerRoot, getMobile, token)
      .pipe(first())
      .subscribe(
        (res) => {
          this.spinner.hide();
          updatecustresp = res;
          if (res['status'].code == 200) {
            console.log('customer updated');
            this.router.navigate(['/profile'])
            this.allKids = this.allKids.filter(kid => kid.id != this.editData.id)
            sessionStorage.setItem("kidsLength", JSON.stringify(this.allKids?.length))

          }
        },
        (err) => {
          this.spinner.hide();
          console.log(err, '=== customer update error =======');  
          this.bsService.notifyError(err, customerRoot);
      });

  
  }
  get editChildFormVal() {
    return this.editChildForm.controls;
  }

  onSubmitChildEditForm() {
    if (this.editChildForm.invalid) {
      return;
    }
    this.submittedChildForm = true;
    this.onEdit();
  }


  onClickEditData(point: any, index) {
    // point.id = ++index;
    this.editChildForm.patchValue({
      childFirstName: point.babys_name ? point.babys_name : '',
      dob: point.babys_age ? point.babys_age : '',
      childLastName: point.babys_lastname ? point.babys_lastname : '',
    });
    this.showCard = false;
    this.showChildEditForm = true;
    this.editData = point;
  }


  onAddNew() {
    this.showCard = false;
    this.datanotfound = false;
    this.showChildEditForm = false;
    this.showChildNewForm = true;
  }
  onsave() {
    this.getuserForm();
  }
  onEdit() {
    this.submitted = true;
    if (this.editChildForm.invalid) {
      return;
    } else {
      let getMobile = sessionStorage.getItem('mobileNo');
      if (this.allKids.length > 0) {
        let ids = [];
        this.allKids.forEach(({ id }) => {
          ids.push(id);
        })
        if (!this.editData.id) {
          if (!ids.includes(1)) {
            this.editData.id = 1;
          }

          else if (!ids.includes(2)) {
            this.editData.id = 2;
            //return;
          }

          else if (!ids.includes(3)) {
            this.editData.id = 3;
            //return;
          }
        }
      } else {
        this.editData.id = 1;
      }

      console.log("edit: ", this.editData);
      // let currentChildAge: any = moment(this.editChildForm.value.dob).format('DD/MM/YYYY')
      // let previousChildAge: any = this.allKids[this.editData.id - 2].babys_age
      // let ageDifference = moment(previousChildAge).diff(moment(currentChildAge), 'months')
      // let ageDifference = moment(currentChildAge).diff(moment(previousChildAge), 'months')

      // var endDate = moment(this.editChildForm.value.dob, 'DD-MM-YYYY');
      // var startDate = moment(this.allKids[this.editData.id - 2].babys_age, 'DD-MM-YYYY');
      //const monthDifference = moment(new Date(currentChildAge)).diff(new Date(previousChildAge), 'months', true);

      // var monthDiff = endDate.diff(startDate, 'months', true);
      // console.log('Month:' + monthDiff);

      // let nameDuplicated = this.isNameDuplicated(this.allKids, this.editChildForm)
      // let firstNameLastNameSame = this.isFirstNameLastNameSame(this.editChildForm.value.childFirstName.trim(), this.editChildForm.value.childLastName);
      // // if (firstNameLastNameSame) {
      //   alert('Childs first name and last name must be different')
      //   return
      // } 
      // else if (monthDiff <= 7) {
      //   alert('Childs age must be greater than 7 months to the previous child')
      //   return
      // }
      // else if (nameDuplicated && this.showChildNewForm) {
      //   alert('Childs names must be different')
      //   return
      // }
      //  else {
      let customerRoot = {
        root: {
          customer: [
            {
              mobile: getMobile,
              custom_fields: {
                field: [
                  {
                    name: `child${this.editData.id}_name`,
                    value: this.editChildForm.value.childFirstName,
                  },
                  {
                    name: `child${this.editData.id}_lastname`,
                    value: this.editChildForm.value.childLastName,
                  }
                ],
              },
              extended_fields: {
                field: [
                  {
                    name: `child${this.editData.id}_dob`,
                    value: moment(this.editChildForm.value.dob).format("YYYY-MM-DD HH:mm:ss")
                  }
                ],
              },
            },
          ],
        },
      };
      this.spinner.show();
      console.log("edit: ", customerRoot);
      let token = sessionStorage.getItem('authToken');


      this.apiService
        .updateCapCustomer(customerRoot, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res['status'].code == 200) {

              ++this.allKids.length

              sessionStorage.setItem("kidsLength", JSON.stringify(this.allKids?.length))
              console.log('customer updated');
              this.profileUpdate = true;


              setTimeout(() => {
                this.profileUpdate = false;
                this.router.navigate(['/profile'])

              }, 3000)

            }
          },err=>{
            this.spinner.hide();
            this.bsService.notifyError(err, customerRoot);
          });
      // }
    }
  }

  isFirstNameLastNameSame(firstName, lastName) {
    return firstName == lastName
  }

  isNameDuplicated(allKids, editChildFormVal) {
    let names = [];
    this.allKids.forEach(kid => {
      let fullName = kid.babys_name.concat(kid.babys_lastname)
      names.push(fullName)
    })

    let currentChildName = editChildFormVal.value.childFirstName.concat(editChildFormVal.value.childLastName)
    return names.includes(currentChildName);
  }

  getuserForm() {
    this.submitted = true;
    if (this.editChildForm.invalid) {
      return;
    } else {
      let userData = this.editChildForm.value;
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      let filledform = {};
      this.customerData.custom_fields.field.forEach((element) => {
        if (element.name === 'child1_name') {
          if (element.value !== '') {
            this.childOne = false;
          } else {
            this.childOne = true;
          }
        }
        if (element.name === 'child2_name') {
          if (element.value !== '') {
            this.childTwo = false;
          } else {
            this.childTwo = true;
          }
        }
        if (element.name === 'child3_name') {
          if (element.value !== '') {
            this.childThree = false;
          } else {
            this.childThree = true;
          }
        }
      });
      if (this.childOne) {
        filledform = this.babyDataset(1, userData);
      } else {
        if (this.childTwo) {
          filledform = this.babyDataset(2, userData);
        } else {
          if (this.childThree) {
            filledform = this.babyDataset(3, userData);
          } else {
          }
        }
      }

      userData.mobile = getMobile;

      //filledform = this.temp_form;
      this.spinner.show();
      let updatecustresp;
      this.apiService
        .updateCapCustomer(filledform, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
            updatecustresp = res;
            this.spinner.hide();
            if (res['status'].code == 200) {
              console.log('customer updated');
              // this.router.navigate(['/profile']);
            }
          },
          (err) => {
            this.bsService.notifyError(err, filledform);
            this.spinner.hide();
            console.log(err, '=== customer update error =======');
          }
        );
    }
  }
  babyDataset(number, userData) {
    return (this.profileForm = {
      root: {
        customer: [
          {
            mobile: userData.mobile,
            custom_fields: {
              field: [
                {
                  name: `child${number}_name`,
                  value: userData.childName,
                },
                {
                  name: `child${number}_gender`,
                  value: userData.gender,
                },
              ],
            },
            extended_fields: {
              field: [
                {
                  name: `child${number}_dob`,
                  value: moment(userData.dob).format('DD/MM/YYYY'),
                },
              ],
            },
          },
        ],
      },
    });
  }
}
