<app-header></app-header>
<section class="globalContainer">
  <section class="traceabilityContainer px15">
    <app-back text="Kembali"></app-back>
    <div class="text-center pt-5">
      <img
        *ngIf="isIsprint == false"
        class="img-fluid w-50"
        [src]="prodImage"
      />
      <img *ngIf="isIsprint == true" class="img-fluid" [src]="productImage" />
      <h1 class="productTitle mt-3">{{ productName }}</h1>
      <h1 class="productTitle">{{ flavor }} {{ size }}</h1>
    </div>
    <div class="specificationBlock mt20" *ngIf="isIsprint == false">
      <h2 class="cardTitle">
        <i class="mdi mdi-alert-circle-outline"></i>
        Spesifikasi Produk
      </h2>
      <div class="row mt-4">
        <div class="col-7 text-left cardTextLeft">
          <p>Nomor Batch :</p>
          <p>Tanggal Kadaluwarsa :</p>
          <p>Tanggal Produksi :</p>
        </div>
        <div class="col-5 text-right cardTextRight">
          <p>{{ batchNumber }}</p>
          <p>{{ expiryDate }}</p>
          <p>{{ manufactureDate }}</p>
        </div>
      </div>
    </div>
    <p class="secondaryTitle mt30 text-center">Deskripsi Produk</p>
    <p class="prodDescription textLightGrey text-center">
      {{ description }}
    </p>
    <div class="text-center my-4 authContent">
      <p>
        Pediasure dengan formula baru yang disempurnakan mendukung pertumbuhan
        nyata dan daya tahan tubuh serta memenuhi kebutuhan nutrisi harian si
        Kecil.
      </p>

      <p>
        Cocok untuk <strong>usia 1-10 tahun</strong> dengan berbagai varian rasa
        yang lezat.
      </p>
    </div>
    <div class="formula-img text-center mb-4">
      <img
        class="img-fluid max-240"
        src="../../../assets/images/trace-formula.png"
      />
    </div>
    <div class="btnContainer" *ngIf="routeData == '2' || routeData != '1'">
      <button
        type="submit"
        class="btn w-100 btn-50 mb-4 btnPurple"
        routerLink="/profile"
      >
        Kembali ke Profil
      </button>
    </div>
    <div class="btnContainer" *ngIf="routeData == '1'" routerLink="/landing">
      <button type="submit" class="btn w-100 btn-50 mb-4 btnPurple">
        Kembali ke Beranda
      </button>
    </div>
    <!-- <h1 class="primaryTitle mt30">
      {{ "PEDIA_TRACEABILITY.SPECIAL_PROMO" | translate }}
    </h1>
    <div class="customCardsBlockOut">
      <div class="card promo1" routerLink="/faq/terms-conditions"></div>
      <div class="card" routerLink="/faq/terms-conditions"></div>
      <div class="card" routerLink="/faq/terms-conditions"></div>
      <div class="card" routerLink="/faq/terms-conditions"></div>
      <div class="card" routerLink="/faq/terms-conditions"></div>
      <div class="card" routerLink="/faq/terms-conditions"></div>
      <div class="card" routerLink="/faq/terms-conditions"></div>
    </div> -->
    <!-- <button class="btn btnPurple br10 btn-block mt-4" routerLink="/profile">
      Kembali ke Profil
    </button>

    <button
      *ngIf="routeData"
      class="btn btnPurple br10 btn-block mt-4"
      routerLink="/landing"
    >
      Kembali ke rumah
    </button> -->
  </section>
</section>
<app-footer></app-footer>
