import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  termsData:any;
  termsHeading:any;
  constructor(
    private spinner: NgxSpinnerService, 
    private route:ActivatedRoute, 
    private APIService: ApiService, 
    private router:Router, 
    private bsService: BugsnagService
    ) {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
    this.getRoute()
    this.getPrivacyPolicys();
  }
  getRoute(){
    console.log(this.router.url, 'this.router.url')
    if (this.router.url === '/faq/privacy-policy'){
      document.querySelector('body').classList.add('privacy-data');
    }else{
      document.querySelector('body').classList.remove('privacy-data');
    }
  }

  getPrivacyPolicys(){
    this.spinner.show();
    this.APIService.getcontentBlocks().subscribe((res:any)=>{
      this.spinner.hide();
      let termsData = res.data.filter(element => element.key == 'Proivacy Policy');
      this.termsData = termsData[0];
      console.log(this.termsData, 'termsData')
      
    },err=>{
      this.spinner.hide();
      this.bsService.notifyError(err);
    })
  }

}



