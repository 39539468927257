<app-header></app-header>
<section class="globalContainer px15 pt70">
  <app-back text="Kembali"></app-back>
  <section class="formContainer form-forgetPassword pt-4">
    <form
      [formGroup]="forgotMobileForm"
      (ngSubmit)="onSubmitMobileForm()"
      *ngIf="showForgotMobileForm"
    >
      <h3 class="primaryBlackTitle mb-3 text-center textPurple">
        Masukkan nomor untuk mendapatkan otp
      </h3>

      <div class="form-group">
        <label>Nomor HP</label>
        <input
          type="number"
          class="form-control hl-50"
          placeholder="Enter phone number"
          formControlName="mobile"
          [ngClass]="{
            'is-invalid': submittedMobileForm && mobileFormVal.mobile.errors
          }"
        />
        <div
          *ngIf="submittedMobileForm && mobileFormVal.mobile.errors"
          class="invalid-feedback"
        >
          <div *ngIf="mobileFormVal.mobile.errors.required">
            Masukkan nomor Ponsel
          </div>
          <div *ngIf="mobileFormVal.mobile.errors.pattern">
            *Kata Sandi harus memiliki minimal 8 karakter, dengan kombinasi
            minimal 1 karakter numerik dan 1 karakter khusus
          </div>
        </div>
      </div>
      <div>
        <div
          *ngIf="
            submittedMobileForm && userNotFound && !mobileFormVal.mobile.errors
          "
          class="invalidText"
        >
          Pengguna tidak terdaftar
        </div>
        <div
          *ngIf="
            submittedMobileForm &&
            nonRegisteredUser &&
            !mobileFormVal.mobile.errors
          "
          class="invalidText"
        >
          Pengguna tidak terdaftar
        </div>
        <!-- <p class="contentDarkBlue invalidText mt-2">
          Please enter full handphone number beginning with 1. Eg: Handphone
          number 012-3899000. Key in 123899000.
        </p> -->
      </div>
      <button
        type="submit"
        class="btn btnPurple btn-50 br10 btn-block mt70 mb70"
      >
        Kirim OTP
      </button>
    </form>

    <form
      [formGroup]="forgotOtpForm"
      (ngSubmit)="onSubmitOtpForm()"
      *ngIf="showOtpForm"
    >
      <h3 class="primaryBlackTitle mb-3 text-center textPurple">Enter otp</h3>

      <span class="px-2 text-center d-block">
        <div *ngIf="!showResendOtp">
          Kirim ulang kode dalam waktu (<span
            class="contentSmall"
            *ngIf="beginTimer"
          >
            <countdown
              #cd
              [config]="{ leftTime: 60, format: 'mm:ss', notify: [2, 5] }"
              (event)="handleEvent($event)"
            ></countdown> </span
          >)<span>
            <!-- ( <span> {{ this.count }} </span>) -->
          </span>
        </div>

        <br />
        <a class="resend textPurple" *ngIf="showResendOtp"
          ><span (click)="resendOTP()"> Kirim ulang kode </span>
        </a>
      </span>

      <div class="form-group text-center mt-3 forgotOTPForm">
        <ng-otp-input
          (onInputChange)="onOtpChange($event)"
          [config]="{ length: 6, placeholder: '0', allowNumbersOnly: true }"
          formControlName="otp"
          [ngClass]="{
            'is-invalid':
              (submitOtpForm && (otp == undefined || otp.length < 6)) ||
              invalidOtp
          }"
          #ngOtpInput
        >
        </ng-otp-input>

        <div
          *ngIf="submitOtpForm && OtpFormVal.otp.errors.required"
          class="text-danger pl-0 mt-2"
        >
          <div *ngIf="!activeClassButton">
            {{ "OTP.OTP_REQUIRED" | translate }}
          </div>
        </div>
        <div *ngIf="invalidOtp" class="text-danger pl-0 fs12">
          <div>Kode yang Anda masukkan salah</div>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-50 btnPurple br10 btn-block mt70 mb70"
      >
        Verify Otp
      </button>
    </form>

    <form
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="onSubmitForgotPasswordForm()"
      *ngIf="showForgotPasswordForm"
    >
      <h3 class="primaryBlackTitle mb-3 text-center textPurple">
        Create New Password
      </h3>

      <div class="form-group">
        <label>{{ "LOGIN_SIGNUP.PASSWORD" | translate }}</label>
        <input
          [type]="showpassword ? 'text' : 'password'"
          class="form-control hl-50"
          placeholder="{{ 'LOGIN_SIGNUP.ENTER_PASSWORD' | translate }}"
          formControlName="password"
          [ngClass]="{
            'is-invalid': submittedForgotForm && forgotFormVal.password.errors
          }"
        />
        <div class="eyeIcon">
          <i
            class="mdi mdi-eye-off"
            *ngIf="!showpassword"
            (click)="eyepassword()"
          ></i>

          <i
            class="mdi mdi-eye"
            *ngIf="showpassword"
            (click)="eyepassword()"
          ></i>
        </div>
        <div
          *ngIf="submittedForgotForm && forgotFormVal.password.errors"
          class="invalid-feedback"
        >
          <div *ngIf="forgotFormVal.password.errors.required">
            Enter New Password
          </div>
          <div *ngIf="forgotFormVal.password.errors.pattern">
            *Kata Sandi harus memiliki minimal 8 karakter, dengan kombinasi
            minimal 1 karakter numerik dan 1 karakter khusus
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ "LOGIN_SIGNUP.CONFIRM_PASSWORD" | translate }}</label>
        <input
          [type]="showSignUpPassword ? 'text' : 'password'"
          class="form-control hl-50"
          placeholder="{{ 'LOGIN_SIGNUP.ENTER_PASSWORD' | translate }}"
          formControlName="confirmPassword"
          [ngClass]="{
            'is-invalid':
              submittedForgotForm && forgotFormVal.confirmPassword.errors
          }"
        />
        <div class="eyeIcon">
          <i
            class="mdi mdi-eye-off"
            *ngIf="!showSignUpPassword"
            (click)="eyepassword1()"
          ></i>

          <i
            class="mdi mdi-eye"
            *ngIf="showSignUpPassword"
            (click)="eyepassword1()"
          ></i>
        </div>
        <div
          *ngIf="submittedForgotForm && forgotFormVal.confirmPassword.errors"
          class="invalid-feedback"
        >
          <div *ngIf="forgotFormVal.confirmPassword.errors.required">
            Enter Password
          </div>
          <div *ngIf="forgotFormVal.password.errors.pattern">
            *Kata Sandi harus memiliki minimal 8 karakter, dengan kombinasi
            minimal 1 karakter numerik dan 1 karakter khusus
          </div>
          <div *ngIf="forgotFormVal.confirmPassword.errors.mustMatch">
            Password confirmation does not match
          </div>
        </div>
      </div>
      <button
      [disabled]= "hiddenBtn"
        type="submit"
        class="btn btnPurple br10 btn-50 btn-block mt40 mb70"
      >
        Save
      </button>
    </form>
  </section>
</section>
