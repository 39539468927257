<app-header></app-header>
<section class="globalContainer">
  <section class="formContainer pb70 pt65">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          [disabled]="disabledLogin"
          [class.active]="disabledLogin == false"
          class="nav-link active btn-block"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="false"
        >
          Masuk
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          [class.active]="disabledLogin == true"
          class="nav-link btn-block"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Daftar
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        [class.active]="disabledLogin == false"
        [class.show]="disabledLogin == false"
        class="tab-pane fade"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="text-center px15">
          <img class="w-100p" src="../../../assets/images/login-register.png" />

          <h5 class="textPurple fW600 fs20 lh30">
            Ayo gabung menjadi member PediaSure Loyalty Program!
          </h5>
          <p class="textBlack mt-3">Masuk menggunakan</p>
        </div>
        <div class="row mx-0 px15 justify-content-between">
          <div class="col pl-0 pr-5">
            <button
              type="submit"
              class="btn btnPurple blueBg br10 btn-block mt-3 socialLoginBtn"
              (click)="signInWithFB()"
            >
              <img src="../../../assets/images/icon-fb.png" />
              <!-- <i class="mdi mdi-facebook mr-2"></i> -->
              {{ "LOGIN_SIGNUP.SIGN_IN_FACEBOOK" | translate }}
            </button>
          </div>
          <div class="col pr-0 pl-5">
            <button
              type="submit"
              class="
                btn
                btnPurple
                btnOutlineGrey
                social
                br10
                btn-block
                mt-3
                socialLoginBtn
              "
              (click)="loginWithGoogle()"
            >
              <img src="../../../assets/images/icon-google.png" alt="" />
              {{ "LOGIN_SIGNUP.SIGN_IN_GOOGLE" | translate }}
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt30">
          <div class="borderline"></div>
          <span> Atau </span>
          <div class="borderline"></div>
        </div>
        <form
          [formGroup]="loginForm"
          class="px15 mt30 login-Form"
          (ngSubmit)="onSubmitLoginForm()"
        >
          <div
            *ngIf="showError && !invalidPassword"
            class="formFailedError text-white p-3 br10 mb-3"
          >
            <div class="row">
              <div class="col-1">
                <i class="mdi mdi-alert-circle-outline"></i>
              </div>
              <div class="col-10">
                <p class="mb-0">
                  Nomor HP atau kata sandi salah, coba cek lagi ya.
                </p>
              </div>
            </div>
          </div>
          <div
            *ngIf="invalidPassword"
            class="formFailedError text-white p-3 br10 mb-3"
          >
            <div class="row">
              <div class="col-1">
                <i class="mdi mdi-alert-circle-outline"></i>
              </div>
              <div class="col-10">
                <p class="mb-0">
                  Kata sandi yang dimasukkan tidak valid, Silakan coba lagi.
                </p>
              </div>
            </div>
          </div>
          <div
            *ngIf="userNotRegistered"
            class="formFailedError text-white p-3 br10 mb-3"
          >
            <div class="row">
              <div class="col-1">
                <i class="mdi mdi-alert-circle-outline"></i>
              </div>
              <div class="col-10">
                <p class="mb-0">
                  Pengguna tidak terdaftar. Silakan daftarkan pengguna
                </p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Nomor HP</label>
            <div class="position-relative">
              <input
                type="number"
                class="form-control mobNumber"
                placeholder="8xx-xxxx-xxxx"
                [ngClass]="{
                  'is-invalid':
                    submittedLoginForm && loginFormVal.mobileNumber.errors
                }"
                formControlName="mobileNumber"
              />
              <span class="mobileCode">+62</span>
              <div
                *ngIf="submittedLoginForm && loginFormVal.mobileNumber.errors"
                class="errIcon"
              >
                <img src="../../../assets/images/error-icon.png" />
              </div>
            </div>

            <!-- <div *ngIf="submittedLoginForm && loginFormVal.mobileNumber.errors" class="invalid-feedback">
              <div *ngIf="loginFormVal.mobileNumber.errors.required">
                Masukkan Nomor
              </div>
              <div *ngIf="
                  loginFormVal.mobileNumber.errors.pattern ||
                  loginFormVal.mobileNumber.errors.pattern
                ">
                Nomor Tidak Valid
              </div>
            </div> -->
          </div>
          <div class="form-group">
            <label>Kata Sandi</label>
            <input
              [type]="showpassword ? 'text' : 'password'"
              class="form-control position-relative"
              placeholder="********"
              formControlName="password"
              [ngClass]="{
                'is-invalid': submittedLoginForm && loginFormVal.password.errors
              }"
            />
            <div class="eyeIcon">
              <i
                class="mdi mdi-eye-off"
                *ngIf="!showpassword"
                (click)="eyepassword()"
              ></i>

              <i
                class="mdi mdi-eye"
                *ngIf="showpassword"
                (click)="eyepassword()"
              ></i>
            </div>
            <div
              *ngIf="submittedLoginForm && loginFormVal.password.errors"
              class="errIcon"
            >
              <img src="../../../assets/images/error-icon.png" />
            </div>

            <!-- <div *ngIf="submittedLoginForm && loginFormVal.password.errors" class="invalid-feedback">
              <div *ngIf="loginFormVal.password.errors.required">
                Masukkan kata kunci
              </div>
              <div *ngIf="loginFormVal.password.errors.pattern">
                Kata sandi harus memiliki minimal 8 karakter, mengandung setidaknya 1
                karakter numerik dan 1 karakter khusus.
              </div>
            </div> -->
          </div>

          <button
            type="submit"
            class="btn btnPurple br10 btn-block mt-4 loginBtn"
          >
            Masuk
          </button>
          <div class="text-center mt-2">
            <a
              class="forgotPasswordLink ml-2 text-center"
              routerLink="/login-signup/forgot-password"
              >Lupa kata sandi?</a
            >
          </div>
        </form>
      </div>
      <div
        [class.active]="disabledLogin == true"
        [class.show]="disabledLogin == true"
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div class="text-center px15">
          <img class="w-100p" src="../../../assets/images/login-register.png" />

          <h5 class="textPurple fW600 fs20 lh30">
            Ayo gabung menjadi member PediaSure Loyalty Program!
          </h5>
          <p class="textBlack mt-3">Daftar menggunakan</p>
        </div>
        <div class="row mx-0 px15 justify-content-between">
          <div class="col pl-0 pr-5">
            <button
              type="submit"
              class="btn btnPurple blueBg br10 btn-block mt-3 socialLoginBtn"
              (click)="signInWithFB()"
            >
              <img src="../../../assets/images/icon-fb.png" />
              <!-- <i class="mdi mdi-facebook mr-2"></i> -->
              {{ "LOGIN_SIGNUP.SIGN_IN_FACEBOOK" | translate }}
            </button>
          </div>
          <div class="col pr-0 pl-5">
            <button
              type="submit"
              class="
                btn
                btnPurple
                btnOutlineGrey
                social
                br10
                btn-block
                mt-3
                socialLoginBtn
              "
              (click)="loginWithGoogle()"
            >
              <img src="../../../assets/images/icon-google.png" alt="" />

              {{ "LOGIN_SIGNUP.SIGN_IN_GOOGLE" | translate }}
            </button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt30">
          <div class="borderline"></div>
          <span> Atau </span>
          <div class="borderline"></div>
        </div>

        <div
          bsModal [config]="{backdrop: 'static'}"
          #otpModal="bs-modal"
          class="modal fade customModalContainer px30"
          tabindex="-1"
          role="dialog"
          aria-labelledby="dialog-child-name"
        >
          <div class="modal-dialog modal-sm mx-auto">
            <div class="modal-content">
              <div class="modal-body pt-3 text-center">
                <form [formGroup]="otpForm" (ngSubmit)="onSubmitOtpForm()">
                  <h3 class="primaryBlackTitle mb-3 text-center textPurple">
                    Enter otp
                  </h3>
                  <span>
                    We have sent an otp for {{ mobileNo }}, please enter the otp
                    to proceed</span
                  >

                  <div class="form-group text-center mt-3">
                    <ng-otp-input
                      *ngIf="!lock"
                      (onInputChange)="onOtpChange($event)"
                      [config]="{
                        length: 6,
                        placeholder: '0',
                        allowNumbersOnly: true
                      }"
                      formControlName="otp"
                      [ngClass]="{
                        'is-invalid':
                          (submitOtpForm &&
                            (otp == undefined || otp.length < 6)) ||
                          invalidOtp
                      }"
                      #ngOtpInput
                    >
                    </ng-otp-input>

                    <div
                      *ngIf="submitOtpForm && OtpFormVal.otp.errors.required"
                      class="text-danger pl-0 mt-2"
                    >
                      <div *ngIf="!activeClassButton">
                        {{ "OTP.OTP_REQUIRED" | translate }}
                      </div>
                    </div>
                    <div *ngIf="invalidOtp" class="text-danger pl-0 fs12">
                      <div>Kode yang Anda masukkan salah</div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btnPurple br10 mt40 mb70 mr30"
                  >
                    Verify Otp
                  </button>

                  <button
                    type="button"
                    (click)="onClickCancel()"
                    class="btn btnPurple btnOutlineGrey br10 mt40 mb70"
                  >
                    Cancel
                  </button>

                  <span class="px-2 text-center d-block">
                    <div *ngIf="!showResendOtp">
                      Kirim ulang kode dalam waktu (<span
                        class="contentSmall"
                        *ngIf="beginTimer"
                      >
                        <countdown
                          #cd
                          [config]="{
                            leftTime: 60,
                            format: 'mm:ss',
                            notify: [2, 5]
                          }"
                          (event)="handleEvent($event)"
                        ></countdown> </span
                      >)<span>
                        <!-- ( <span> {{ this.count }} </span>) -->
                      </span>
                    </div>

                    <br />
                    <a class="resend textPurple mb20" *ngIf="showResendOtp"
                      ><span (click)="resendOTP()"> Kirim ulang kode </span>
                    </a>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>

        <form
          [formGroup]="signUpForm"
          class="px15 mt30 login-Form"
          (ngSubmit)="onSubmitSignUpForm()"
        >
          <div class="form-group">
            <label>Nomor HP</label>
            <div class="position-relative">
              <input
                type="number"
                class="form-control mobNumber"
                placeholder="8xx-xxxx-xxxx"
                [ngClass]="{
                  'is-invalid':
                    submittedSignUpForm && signUpFormVal.mobileNumber.errors
                }"
                formControlName="mobileNumber"
                (change)="checkSalesForce($event)"
                [readonly]="isMobileSalesForce"
              />
              <span class="mobileCode">+62</span>
              <div
                *ngIf="submittedSignUpForm && signUpFormVal.mobileNumber.errors"
                class="errIcon"
              >
                <img src="../../../assets/images/error-icon.png" />
              </div>
              <div
                *ngIf="submittedSignUpForm && signUpFormVal.mobileNumber.errors"
                class="invalid-feedback"
              >
                <div *ngIf="signUpFormVal.mobileNumber.errors.required">
                  Mohon masukkan nomor hp Anda
                </div>
                <div
                  *ngIf="
                    signUpFormVal.mobileNumber.errors.pattern ||
                    signUpFormVal.mobileNumber.errors.pattern
                  "
                >
                  Nomor Tidak Valid
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Nama Depan </label>
            <input
              type="name"
              class="form-control"
              placeholder="Masukkan nama depan Anda"
              [ngClass]="{
                'is-invalid':
                  submittedSignUpForm && signUpFormVal.firstname.errors
              }"
              formControlName="firstname"
              (keypress)="onlyCharacters($event)"
            />
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.firstname.errors"
              class="errIcon"
            >
              <img src="../../../assets/images/error-icon.png" />
            </div>
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.firstname.errors"
              class="invalid-feedback"
            >
              <div *ngIf="signUpFormVal.firstname.errors.required">
                Mohon masukkan Nama Depan
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Nama Belakang </label>
            <input
              type="name"
              class="form-control"
              placeholder="Masukkan nama belakang Anda"
              [ngClass]="{
                'is-invalid':
                  submittedSignUpForm && signUpFormVal.lastname.errors
              }"
              formControlName="lastname"
              (keypress)="onlyCharacters($event)"
            />
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.lastname.errors"
              class="errIcon"
            >
              <img src="../../../assets/images/error-icon.png" />
            </div>
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.lastname.errors"
              class="invalid-feedback"
            >
              <div *ngIf="signUpFormVal.lastname.errors.required">
                Mohon masukkan Nama Belakang
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "LOGIN_SIGNUP.EMAIL" | translate }}</label>
            <input
              type="email"
              class="form-control"
              (change)="trimEmail($event)"
              placeholder="Masukkan email Anda"
              [ngClass]="{
                'is-invalid':
                  submittedSignUpForm &&
                  (signUpFormVal.email.errors || emailExsistError)
              }"
              (keydown)="emailInput($event)"
              formControlName="email"
            />
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.email.errors"
              class="errIcon"
            >
              <img src="../../../assets/images/error-icon.png" />
            </div>
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="signUpFormVal.email.errors.required">
                Mohon masukkan Alamat Email
              </div>
              <div
                *ngIf="
                  signUpFormVal.email.errors.pattern ||
                  signUpFormVal.email.errors.pattern
                "
              >
                Email tidak valid
              </div>
            </div>
            <div
              *ngIf="submittedSignUpForm && emailExsistError"
              class="invalid-feedback"
            >
              <div>Email sudah ada</div>
            </div>
          </div>

          <div class="form-group">
            <label>Tanggal Lahir </label>
            <input
              type="text"
              class="form-control"
              placeholder="Pilih tanggal lahir Anda"
              [ngClass]="{
                'is-invalid': submittedSignUpForm && signUpFormVal.dob.errors
              }"
              formControlName="dob"
              bsDatepicker
              [bsValue]="minDate"
              [maxDate]="maxDate"
              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }"
            />
            <div class="eyeIcon calendarIcon">
              <!-- <i class="mdi mdi-calendar"></i> -->
              <img
                src="../../../assets/images/calendar-icon.png"
                alt="calendar"
              />
            </div>
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.dob.errors"
              class="errIcon"
            >
              <img src="../../../assets/images/error-icon.png" />
            </div>
            <div
              *ngIf="submittedSignUpForm && signUpFormVal.dob.errors"
              class="invalid-feedback"
            >
              <div *ngIf="signUpFormVal.dob.errors.required">
                Mohon masukkan Tanggal Lahir
              </div>
              <div
                *ngIf="
                  signUpFormVal.dob.errors.pattern ||
                  signUpFormVal.dob.errors.pattern
                "
              >
                Dob tidak valid
              </div>

              <div *ngIf="signUpFormVal.dob.errors.minimumAgeIs18">
                Usia minimal harus 18
              </div>
            </div>
          </div>

          <div *ngIf="!socialLogin">
            <div class="form-group">
              <label>Kata Sandi</label>
              <input
                [type]="showSignUpPassword ? 'text' : 'password'"
                class="form-control"
                placeholder="********"
                formControlName="password"
                [ngClass]="{
                  'is-invalid':
                    submittedSignUpForm && signUpFormVal.password.errors
                }"
              />
              <div class="eyeIcon">
                <i
                  class="mdi mdi-eye-off"
                  *ngIf="!showSignUpPassword"
                  (click)="eyeSignUppassword()"
                ></i>

                <i
                  class="mdi mdi-eye"
                  *ngIf="showSignUpPassword"
                  (click)="eyeSignUppassword()"
                ></i>
              </div>
              <div
                *ngIf="submittedSignUpForm && signUpFormVal.password.errors"
                class="errIcon"
              >
                <img src="../../../assets/images/error-icon.png" />
              </div>
              <div
                *ngIf="submittedSignUpForm && signUpFormVal.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="signUpFormVal.password.errors.required">
                  Mohon masukkan Kata Sandi
                </div>
                <div *ngIf="signUpFormVal.confirmPassword.errors.pattern">
                  *Kata Sandi harus memiliki minimal 8 karakter, dengan
                  kombinasi minimal 1 karakter numerik dan 1 karakter khusus
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Konfirmasi Kata Sandi</label>
              <input
                [type]="showConfirmPassword ? 'text' : 'password'"
                class="form-control"
                placeholder="********"
                formControlName="confirmPassword"
                [ngClass]="{
                  'is-invalid':
                    submittedSignUpForm && signUpFormVal.confirmPassword.errors
                }"
              />
              <div class="eyeIcon">
                <i
                  class="mdi mdi-eye-off"
                  *ngIf="!showConfirmPassword"
                  (click)="eyeSignUpConfirmpassword()"
                ></i>

                <i
                  class="mdi mdi-eye"
                  *ngIf="showConfirmPassword"
                  (click)="eyeSignUpConfirmpassword()"
                ></i>
              </div>
              <div
                *ngIf="
                  submittedSignUpForm && signUpFormVal.confirmPassword.errors
                "
                class="errIcon"
              >
                <img src="../../../assets/images/error-icon.png" />
              </div>
              <div
                *ngIf="
                  submittedSignUpForm && signUpFormVal.confirmPassword.errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="signUpFormVal.confirmPassword.errors.pattern">
                  Kata Sandi harus memiliki minimal 8 karakter, dengan kombinasi
                  minimal 1 karakter numerik dan 1 karakter khusus
                </div>
                <div *ngIf="signUpFormVal.confirmPassword.errors.required">
                  Mohon masukkan kembali Kata Sandi
                </div>
                <div *ngIf="signUpFormVal.confirmPassword.errors.mustMatch">
                  Konfirmasi kata sandi tidak sesuai
                </div>
              </div>
            </div>
          </div>

          <div class="form-check pl-1">
            <input
              type="checkbox"
              class="form-check-input mr-2 position-relative ml-0"
              id=""
              formControlName="termsConditions"
              [ngClass]="{
                'is-invalid':
                  submittedSignUpForm && signUpFormVal.termsConditions.errors
              }"
            />
            <label
              class="form-check-label colorWhite fontKanitLight"
              for="exampleCheck1"
              >Saya setuju dengan
              <a class="underlinedText" routerLink="/faq/terms-conditions">
                Syarat & Ketentuan</a
              >
            </label>
            <div
              *ngIf="
                submittedSignUpForm && signUpFormVal.termsConditions.errors
              "
              class="invalid-feedback"
            >
              <div *ngIf="signUpFormVal.termsConditions.errors.required">
                Anda harus menyetujui syarat dan ketentuan
              </div>
            </div>
          </div>

          <button
            type="submit"
            [disabled]="hiddenBtn"
            class="btn btnPurple br10 btn-block mt-4 loginBtn"
          >
            Daftar
          </button>
        </form>
      </div>
    </div>
  </section>
</section>

<!-- <div
  bsModal
  #completeProfileDataModel="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mt120 mx-auto">
    <div class="modal-content">
      <span class="closeIcon" (click)="closeCompleteProfileDataModal()"
        ><i class="mdi mdi-close"></i
      ></span>
      <div class="modal-body">
        <form [formGroup]="mobNumFormGroup" (ngSubmit)="socialMob()">
          <div class="text-left globalContainer">
            <div class="form-group formContainer pt-0">
              <label>Masukkan nomor telpon selular anda</label>
              <input
                type="number"
                class="form-control"
                placeholder="Masukkan nomor handphone"
                formControlName="mobNum"
              />

              <div
                *ngIf="f.mobNum.invalid"
                style="color: red; margin-top: 10px"
              >
                <div *ngIf="f.mobNum.errors.pattern">
                  Please, Enter 10 digit Mobile Number.
                </div>
                <div *ngIf="f.mobNum.errors.required">
                  Please, Enter the mobile number.
                </div>
              </div>
            </div>
          </div>

          <button
            [disabled]="disabled"
            class="btn btnPurple br10 btn-block mt40"
            type="submit"
          >
            Kirim
          </button>
        </form>
      </div>
    </div>
  </div>
</div> -->
<app-footer></app-footer>
