import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-e-redeem',
  templateUrl: './e-redeem.component.html',
  styleUrls: ['./e-redeem.component.scss']
})
export class ERedeemComponent implements OnInit {
  voucherDetail: any;
  firstWord: any;
  cost: any;
  data: any;
  voucherDetail1: any;
  dataList: any;
  constructor(private router: Router, private route: ActivatedRoute) {
    window.scrollTo({ top: 0 })
    console.log(this.router.getCurrentNavigation()?.extras?.state?.voucherData);
    this.data = this.router.getCurrentNavigation()?.extras?.state?.voucherData;

    this.dataList = this.route.snapshot.params;
    console.log(this.dataList, 'datalist');


    //this.voucherDetail = this.router.getCurrentNavigation()?.extras?.state?.voucherData
    if (this.data?.betterluck == 'true' || this.data?.betterluck == 'false') {

      this.voucherDetail = {
        name: this.data?.giftName,
        code: this.data?.couponCode,
        price: this.data?.giftName,
        expiryDate: this.data?.expiryDate,
        issuedDate: this.data?.issuedDate,
      }
      this.firstWord = this.voucherDetail.name.split(" ")[1];
      this.cost = this.voucherDetail.name.split(" ").splice(-2);
    } else if (this.data?.vouchers?.length >= 0) {
      let datavochers = this.data?.vouchers
      this.voucherDetail1 = datavochers.reverse();
      console.log(this.voucherDetail1, 'this.voucherDetail1')
      this.firstWord = this.voucherDetail1[0].seriesName.split(" ")[0]
      console.log(this.firstWord , 'this.firstWord ')
    }else if(this.dataList?.detail){
      let date = new Date();
      console.log(moment(date).format('DD MMMM YYYY'), 'date')
      this.voucherDetail = {
        name: this.dataList?.detail,
        code: this.dataList?.coupon,
        price: this.dataList?.detail,
        expiryDate: this.dataList?.expiry,
        issuedDate: moment(date).format('DD MMMM YYYY')
      }
      if (this.voucherDetail.name.split(" ")[0] == 'E-Voucher') {
        this.firstWord = this.voucherDetail.name.split(" ")[1];
        this.voucherDetail.expiryDate = moment(this.voucherDetail.expiryDate, "DD-MM-YYYY").format('DD MMMM YYYY')
      } else {
        this.firstWord = this.voucherDetail.name.split(" ")[0];
      }
      this.cost = this.voucherDetail.name.split(" ").splice(-2);
    }
    // if(this.voucherDetail.name.startsWith('E-Voucher')){
    //   this.firstWord = this.voucherDetail.name.split(" ")[1];
    //   console.log(this.firstWord , 'this.firstWord ')
    // }else{
    //   this.firstWord = this.voucherDetail.name.split(" ")[0]
    //   console.log(this.firstWord , 'this.firstWord ')
    // }
    // this.voucherDetail = {
    //   name : this.data?.vouchers[0]?.seriesName,
    //   code : this.data?.vouchers[0]?.code,
    //   price :this.data?.vouchers[0]?.seriesName,
    //   expiryDate : this.data?.vouchers[0]?.expiry
    // }



  }

  ngOnInit(): void {
    $('.copy-code').tooltip()
  }

}
