<app-header></app-header>
<section class="globalContainer pt60">
    <section class="levelMemberContainer pb70">
        <div class="topCarouselBlock">
            <div class="px15 backBtn">
                <app-back text="Kembali"></app-back>
            </div>
            <div id="carouselExampleControls" class="carousel slide levelCarousel  carousel-fade" data-ride="carousel" data-interval="10000000000">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active" style="background: #F5E5D6;">
                        <div class="px30 mb70">
                            <div class="card" style="background-color: #cc7f31;">
                                <p class="contentBottomRight" *ngIf="customerPoints < 1200 ">Silver</p>
                                <div class="row">
                                    <div class="col-9">
                                        <h5>Bronze</h5>
                                        <p class="levelContent mb-2">Tingkatkan levelmu untuk mendapatkan benefit lebih</p>
                                    </div>
                                    <div class="col-3">
                                        <div class="badgeBlock text-center">
                                            <img class="" src="../../../assets/images/bronze-icon.png" />
                                        </div>
                                    </div>
                                </div>

                                <div class="progressOut mt-3">
                                    <p *ngIf="customerPoints < 1200 " class="levelContent mb-2">Poin Anda: {{customerPoints}}/<small>1200</small></p>
                                    <p *ngIf="customerPoints > 1200 " class="levelContent mb-2">Poin Anda: 1200/<small>1200</small></p>
                                    <progressbar *ngIf="customerPoints < 1200 " [value]="customerPoints" [max]="1200" class="bronze" type="" [striped]="true" [animate]="true"><i></i></progressbar>
                                </div>

                            </div>
                        </div>
                        <div class="px20 pt-2 pb-4 bg-white">
                            <h4 class="title my-4">Benefit Bronze</h4>
                            <div class="medal-description row mx-0">
                                <div class="unlock">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/welcoming-points.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Selamat Datang</h5>
                                    <p>Dapatkan 15 Point saat pertama kali bergabung PediaSure Loyalty Program</p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="unlock">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/birthday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Hadiah Ulang Tahun <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Poin akan masuk pada perhitungan selanjutnya"></i></h5>
                                    <p>Dapatkan kado 10 poin pada hari ulang tahun Anda </p>
                                </div>
                            </div>
                            
                            <h4 class="title mt50 mb-4">Punya pertanyaan?</h4>  
                            <div class="faqContainer">
                                <div class="questionsBlock mt-2">
                                    <div class="accordion" id="accordionBronze">
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button" data-toggle="collapse"
                                                        data-target="#collapse1" aria-expanded="false" aria-controls="collapseOne">
                                                        Cara mendapatkan poin
                                                    </button>
                                                </h2>
                                            </div>
                                
                                            <div id="collapse1" class="collapse" aria-labelledby="headingOne" data-parent="#accordionBronze">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-1.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Beli produk PediaSure</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-2.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Scan barcode pada produk untuk dapatkan poin</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-3.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Kumpulkan dan tukarkan poin menjadi 🎁🎁</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button" data-toggle="collapse"
                                                        data-target="#collapse2" aria-expanded="false" aria-controls="collapseOne">
                                                        Poin yang didapat dari penukaran produk
                                                    </button>
                                                </h2>
                                            </div>
                                        
                                            <div id="collapse2" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionBronze">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-4.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>25 poin</b> dari pembelian produk berukuran <b>200g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-5.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>50 poin</b> dari pembelian produk berukuran <b>400g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-6.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>150 poin</b> dari pembelian produk berukuran <b>850g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-7.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>300 poin</b> dari pembelian produk berukuran <b>1800g</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                                                                      
                        </div>
                    </div>
                    <div class="carousel-item" style="background: #f2f2f2;">
                        <div class="px30 mb70" *ngIf="customerPoints >= 1200 ">
                            <div class="card" style="background-color: #C0C0C0;">
                                <p class="contentBottomRight" *ngIf="customerPoints < 2799 ">Gold</p>
                                <div class="row">
                                    <div class="col-9">
                                        <h5>Silver</h5>
                                        <p class="levelContent mb-2">Tingkatkan levelmu untuk mendapatkan benefit lebih</p>
                                    </div>
                                    <div class="col-3">
                                        <div class="badgeBlock text-center">
                                            <img class="" src="../../../assets/images/silver-icon.png" />
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="progressOut mt-3">
                                    <p *ngIf="customerPoints < 2799 " class="levelContent mb-2">Poin Anda: {{customerPoints}}/<small>2800</small></p>
                                    <p *ngIf="customerPoints > 2799 " class="levelContent mb-2">Poin Anda: 2800/<small>2800</small></p>
                                    <progressbar *ngIf="customerPoints < 2799 "  [value]="customerPoints" [max]="2800" class="silver" type="" [striped]="true" [animate]="true"><i></i></progressbar>
                                </div>
                    
                            </div>
                        </div>
                        <div class="px30 mb70" *ngIf="customerPoints < 1200 ">
                            <div class="card levelLock bgSilver">
                                <div class="lock-icon">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                
                                <h5>Level ini masih terkunci</h5>
                                <p>Ayo kumpulkan poin lebih banyak untuk membuka level ini</p>
                            </div>
                        </div>
                        <div class="px20 pt-2 pb-4 bg-white">
                            <h4 class="title my-4">Benefit Silver</h4>
                            <div class="medal-description row mx-0">
                                <div class="unlock"  [ngClass]="{'locked': customerPoints < 1200}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/welcoming-points.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Selamat Datang</h5>
                                    <p>Dapatkan 15 Point saat pertama kali bergabung PediaSure Loyalty Program</p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 1200}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/birthday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Hadiah Ulang Tahun <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Poin akan masuk pada perhitungan selanjutnya"></i></h5>
                                    <p>Dapatkan kado 10 poin pada hari ulang tahun Anda </p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="locked">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/payday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Gajian <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Hanya berlaku untuk 1 kali transaksi/bulan"></i></h5>
                                    <p>Dapatkan bonus 10 poin setiap transaksi Pediasure</p>
                                </div>
                            </div>
                    
                            <h4 class="title mt50 mb-4">Punya pertanyaan?</h4>
                            <div class="faqContainer">
                                <div class="questionsBlock mt-2">
                                    <div class="accordion" id="accordionSilver">
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button"
                                                        data-toggle="collapse" data-target="#collapse3" aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                        Cara mendapatkan poin
                                                    </button>
                                                </h2>
                                            </div>
                            
                                            <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordionSilver">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-1.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Beli produk PediaSure</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-2.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Scan barcode pada produk untuk dapatkan poin</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-3.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Kumpulkan dan tukarkan poin menjadi 🎁🎁</p>
                                                        </div>
                                                    </div>
                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button"
                                                        data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                        Poin yang didapat dari penukaran produk
                                                    </button>
                                                </h2>
                                            </div>
                            
                                            <div id="collapse4" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSilver">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-4.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>25 poin</b> dari pembelian produk berukuran <b>200g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-5.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>50 poin</b> dari pembelian produk berukuran <b>400g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-6.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>150 poin</b> dari pembelian produk berukuran <b>850g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-7.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>300 poin</b> dari pembelian produk berukuran <b>1800g</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                    <div class="carousel-item" style="background: #FDF2CC;">
                        <div class="px30 mb70" *ngIf="customerPoints >= 2800 ">
                            <div class="card" style="background-color: #F5BD02;">
                                <p class="contentBottomRight" *ngIf="customerPoints < 4499 ">Platinum</p>
                                <div class="row">
                                    <div class="col-9">
                                        <h5>Gold</h5>
                                        <p class="levelContent mb-2">Tingkatkan levelmu untuk mendapatkan benefit lebih</p>
                                    </div>
                                    <div class="col-3">
                                        <div class="badgeBlock text-center">
                                            <img class="" src="../../../assets/images/gold-icon.png" />
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="progressOut mt-3">
                                    <p *ngIf="customerPoints < 4499 " class="levelContent mb-2">Poin Anda: {{customerPoints}}/<small>4500</small></p>
                                    <p *ngIf="customerPoints > 4499 " class="levelContent mb-2">Poin Anda: 4500/<small>4500</small></p>
                                    <progressbar *ngIf="customerPoints < 4499 " [value]="customerPoints" [max]="4500" class="gold" type="" [striped]="true" [animate]="true"><i></i></progressbar>
                                </div>
                    
                            </div>
                        </div>
                        <div class="px30 mb70" *ngIf="customerPoints < 2800 ">
                            <div class="card levelLock bgGold">
                                <div class="lock-icon">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                        
                                <h5>Level ini masih terkunci</h5>
                                <p>Ayo kumpulkan poin lebih banyak untuk membuka level ini</p>
                            </div>
                        </div>
                        <div class="px20 pt-2 pb-4 bg-white">
                            <h4 class="title my-4">Benefit Gold</h4>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 2800}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/welcoming-points.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Selamat Datang</h5>
                                    <p>Dapatkan 15 Point saat pertama kali bergabung PediaSure Loyalty Program</p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 2800}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/birthday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Hadiah Ulang Tahun <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Poin akan masuk pada perhitungan selanjutnya"></i></h5>
                                    <p>Dapatkan kado 10 poin pada hari ulang tahun Anda </p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 2800}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/payday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Gajian <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Hanya berlaku untuk 1 kali transaksi/bulan"></i></h5>
                                    <p>Dapatkan bonus 20 poin setiap transaksi Pediasure</p>
                                </div>
                            </div>
                    
                            <h4 class="title mt50 mb-4">Punya pertanyaan?</h4>
                            <div class="faqContainer">
                                <div class="questionsBlock mt-2">
                                    <div class="accordion" id="accordionGold">
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button"
                                                        data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                        Cara mendapatkan poin
                                                    </button>
                                                </h2>
                                            </div>
                            
                                            <div id="collapse5" class="collapse" aria-labelledby="headingOne" data-parent="#accordionGold">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-1.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Beli produk PediaSure</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-2.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Scan barcode pada produk untuk dapatkan poin</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-3.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Kumpulkan dan tukarkan poin menjadi 🎁🎁</p>
                                                        </div>
                                                    </div>
                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button"
                                                        data-toggle="collapse" data-target="#collapse6" aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                        Poin yang didapat dari penukaran produk
                                                    </button>
                                                </h2>
                                            </div>
                            
                                            <div id="collapse6" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionGold">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-4.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>25 poin</b> dari pembelian produk berukuran <b>200g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-5.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>50 poin</b> dari pembelian produk berukuran <b>400g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-6.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>150 poin</b> dari pembelian produk berukuran <b>850g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-7.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>300 poin</b> dari pembelian produk berukuran <b>1800g</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                    <div class="carousel-item" style="background: #E5E1D8;">
                        <div class="px30 mb70" *ngIf="customerPoints >= 4500 ">
                            <div class="card" style="background-color: #88847b;">
                                <p class="contentBottomRight">Level Max</p>
                                <div class="row">
                                    <div class="col-9">
                                        <h5>Platinum</h5>
                                        <p class="levelContent mb-2">Selamat, Anda telah meraih level maksimal!</p>
                                    </div>
                                    <div class="col-3">
                                        <div class="badgeBlock text-center">
                                            <img class="" src="../../../assets/images/platinum-icon.png" />
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="progressOut mt-3">
                                    <p class="levelContent mb-2 mt-4">Poin Anda: 4500/<small>4500</small></p>
                                    <!-- <progressbar [value]="value" class="platinum" type="" [striped]="true" [animate]="true"><i></i></progressbar> -->
                                </div>
                    
                            </div>
                        </div>
                        <div class="px30 mb70" *ngIf="customerPoints < 4500 ">
                            <div class="card levelLock bgPlantinum">
                                <div class="lock-icon">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                        
                                <h5>Level ini masih terkunci</h5>
                                <p>Ayo kumpulkan poin lebih banyak untuk membuka level ini</p>
                            </div>
                        </div>
                        <div class="px20 pt-2 pb-4 bg-white">
                            <h4 class="title my-4">Benefit Platinum</h4>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 4500}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/welcoming-points.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Selamat Datang</h5>
                                    <p>Dapatkan 15 Point saat pertama kali bergabung PediaSure Loyalty Program</p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 4500}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/birthday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Hadiah Ulang Tahun <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Poin akan masuk pada perhitungan selanjutnya"></i></h5>
                                    <p>Dapatkan kado 10 poin pada hari ulang tahun Anda </p>
                                </div>
                            </div>
                            <div class="medal-description row mx-0">
                                <div class="unlock" [ngClass]="{'locked': customerPoints < 4500}">
                                    <img src="../../../assets/images/lock.png" />
                                </div>
                                <div class="col-2 px-0 imageContLeft">
                                    <img src="../../../assets/images/payday.png" />
                                </div>
                                <div class="col-10">
                                    <h5>Poin Gajian <i class="mdi mdi-information-outline toolTip" id="tooltip" data-toggle="tooltip" title="Hanya berlaku untuk 1 kali transaksi/bulan"></i></h5>
                                    <p>Dapatkan bonus 2x lipat poin setiap transaksi Pediasure</p>
                                </div>
                            </div>
                    
                            <h4 class="title mt50 mb-4">Info lain tentang</h4>
                            <div class="faqContainer">
                                <div class="questionsBlock mt-2">
                                    <div class="accordion" id="accordionPlatinum">
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button"
                                                        data-toggle="collapse" data-target="#collapse7" aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                        Cara mendapatkan poin
                                                    </button>
                                                </h2>
                                            </div>
                            
                                            <div id="collapse7" class="collapse" aria-labelledby="headingOne" data-parent="#accordionPlatinum">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-1.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Beli produk PediaSure</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-2.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Scan barcode pada produk untuk dapatkan poin</p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-3.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Kumpulkan dan tukarkan poin menjadi 🎁🎁</p>
                                                        </div>
                                                    </div>
                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card mb-2">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link btn-block collapsed collapseBtn" type="button"
                                                        data-toggle="collapse" data-target="#collapse8" aria-expanded="false"
                                                        aria-controls="collapseOne">
                                                        Poin yang didapat dari penukaran produk
                                                    </button>
                                                </h2>
                                            </div>
                            
                                            <div id="collapse8" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionPlatinum">
                                                <div class="card-body">
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-4.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>25 poin</b> dari pembelian produk berukuran <b>200g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-5.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>50 poin</b> dari pembelian produk berukuran <b>400g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-6.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>150 poin</b> dari pembelian produk berukuran <b>850g</b></p>
                                                        </div>
                                                    </div>
                                                    <div class="level-accord">
                                                        <div class="imgSection">
                                                            <img src="../../../assets/images/acc-7.png" alt="product" />
                                                        </div>
                                                        <div class="contentSection">
                                                            <p>Dapat <b>300 poin</b> dari pembelian produk berukuran <b>1800g</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
                    <img class="" src="../../../assets/images/carouselarrowleft.png">

                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
                    <img class="" src="../../../assets/images/carouselarrowright.png">

                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </section>
</section>
<app-footer></app-footer>