<section class="desktopMessageOut text-center">
  <div class="desktopMessage">
    <div class="imgSection">
      <img class="" src="../../../assets/images/desktop-message.png" />
    </div>
    <div class="messageContent">
      <p class="message-title">
        Maaf 🙁, Pediasure Loyalty Program hanya tersedia di versi Handphone
      </p>
      <p class="message-content">
        Akses dengan handphone Anda untuk bisa menikmati konten-konten di
        Pediasure Loyalty Program. Banyak hadiah menarik menanti Anda!
      </p>
    </div>
    <div class="btnSection">
      <a href="#" class="btn btnPurple br10 btn-50 mt-3 mb10">Kembali ke Beranda</a>
    </div>
  </div>
</section>
<!-- <app-header></app-header> -->
<router-outlet></router-outlet>

<ngx-spinner
  template="<img src='../assets/images/Animation Loading Pediasure.png' />"
>
</ngx-spinner>

<!-- //bdColor="rgba(0, 0, 0, 1)" -->