import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-game',
  templateUrl: './info-game.component.html',
  styleUrls: ['./info-game.component.scss']
})
export class InfoGameComponent implements OnInit {

  constructor() {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
  }

}
