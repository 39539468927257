import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMenuItems = false;
  showCloseIcon = false;
  showHamburgerIcon = true;
  getAuthKey:any;
  constructor(public translate: TranslateService, private router: Router) {

    translate.addLangs(['en_US', 'my_MH']);
    translate.setDefaultLang('en_US');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|my_MH/) ? browserLang : 'en_US');
   }

  ngOnInit(): void {
    this.getAuthKey = sessionStorage.getItem('authToken');
    console.log(this.getAuthKey, 'getAuthKey --')
  }
  onclickHamburger() {
    this.showMenuItems = true;
    this.showHamburgerIcon = false;
    this.showCloseIcon = true;
  }

  onClickCloseIcon() {
    this.showMenuItems = false;
    this.showHamburgerIcon = true;
    this.showCloseIcon = false;
  }

  logout(){
    sessionStorage.clear();
    this.router.navigate(['/login-signup'])
  }

}
