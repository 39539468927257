<app-header></app-header>
<section class="globalContainer pt70 px15">
  <app-back text="Kembali"></app-back>
  <h3 class="primaryBlackTitle mt40">My profile</h3>

  <form
    [formGroup]="myProfileForm"
    class="formContainer pt-3"
    (ngSubmit)="onSubmitProfileForm()"
  >
    <h6 class="mb-3">Role</h6>
    <div class="row no-gutters">
      <div class="col pr-1">
        <div class="form-check formCheckCustom">
          <input
            class="form-check-input customInput"
            type="radio"
            name="gender"
            formControlName="gender"
            id="exampleRadios1"
            value="Dad"
            checked
          />
          <label class="form-check-label" for="exampleRadios1"> Dad </label>
        </div>
      </div>
      <div class="col pl-1">
        <div class="form-check formCheckCustom">
          <input
            class="form-check-input customInput"
            type="radio"
            name="gender"
            formControlName="gender"
            id="exampleRadios2"
            value="Mom"
          />
          <label class="form-check-label" for="exampleRadios2"> Mom </label>
        </div>
      </div>
    </div>
    <h6 class="mt-4 mb-3">BirthDay</h6>
    <!-- <ngb-datepicker #d></ngb-datepicker> -->
    <input
      type="text"
      placeholder="Datepicker"
      class="form-control"
      formControlName="dob"
      bsDatepicker
      [bsConfig]="{ isAnimated: true }"
    />
    <!-- <div class="row">
            <div class="col-4 pr-1">
                <div class="form-group">
                    <div class="custom-select-box">
                        <select class="form-control pl-2"
                            [ngClass]="{'is-invalid': submitted && myProfileFormVal.day.errors}" formControlName="year">
                            <option value="" disabled selected hidden>01
                            </option>
                            <option value="">02</option>
                        </select>
                        <div *ngIf="submitted && myProfileFormVal.day.errors" class="invalid-feedback">
                            <div *ngIf="submitted && myProfileFormVal.day.errors.required">Enter day</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 pl-1 pr-1">
                <div class="form-group">
                    <div class="custom-select-box">
                        <select class="form-control pl-2"
                            [ngClass]="{'is-invalid': submitted && myProfileFormVal.month.errors}"
                            formControlName="year">
                            <option value="" disabled selected hidden>01
                            </option>
                            <option value="">02</option>
                        </select>
                        <div *ngIf="submitted && myProfileFormVal.month.errors" class="invalid-feedback">
                            <div *ngIf="submitted && myProfileFormVal.month.errors.required">Enter month</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 pl-1">
                <div class="form-group">
                    <div class="custom-select-box">
                        <select class="form-control pl-2"
                            [ngClass]="{'is-invalid': submitted && myProfileFormVal.year.errors}"
                            formControlName="year">
                            <option value="" disabled selected hidden>1985
                            </option>
                            <option value="">1986</option>
                        </select>
                        <div *ngIf="submitted && myProfileFormVal.year.errors" class="invalid-feedback">
                            <div *ngIf="submitted && myProfileFormVal.year.errors.required">Enter month</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <button type="submit" class="btn btnPurple br10 btn-block mt70 mb70">
      Save
    </button>
  </form>
</section>
<app-footer></app-footer>
