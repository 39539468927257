<app-header></app-header>
<section class="globalContainer pt70 px15 pb70">
  <app-back text="Kembali"></app-back>
  <div class="mt40 text-center" *ngIf="showAddAddressImage">
    <img class="" src="../../../assets/images/noaddressyet.png" />

    <h5 class="textBlack mt30 fW600">Belum ada alamat</h5>
    <p class="textDarkGrey">Anda belum mendaftarkan alamat pengiriman.</p>

    <button
      type="submit"
      class="btn btnPurple br10 btn-block mt70 mb70 btn-50"
      (click)="onClickAddAddress()"
    >
      <i class="mdi mdi-plus pr-2 fs20"></i>Tambah alamat
    </button>
  </div>
  <!-- <h3 class="primaryBlackTitle mt40 font-weight-bold">List of address</h3>
  <h6 class="mt-3 chooseAddress">Choose one of your main address</h6>
  <div>
    <button type="button" class="btn btnPurple br10">
      <i class="mdi mdi-plus"></i> add address
    </button>
  </div> -->
  <section class="formContainer pt-1" *ngIf="showAddressForm">
    <form
      [formGroup]="addAddressForm"
      class="mt-3 pt-1"
      (ngSubmit)="onSubmitAddAddressForm()"
    >
      <div class="form-group">
        <label>Nama Penerima </label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan nama penerima"
          [ngClass]="{
            'is-invalid': submitted && addressFormVal.recipientsName.errors
          }"
          formControlName="recipientsName"
        />
        <div
          *ngIf="submitted && addressFormVal.recipientsName.errors"
          class="invalid-feedback"
        >
          <div *ngIf="addressFormVal.recipientsName.errors.required">
            Masukkan Nama Penerima
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Nomor HP</label>
        <input
          type="number"
          class="form-control"
          placeholder="0812-3456-7890"
          [ngClass]="{
            'is-invalid': submitted && addressFormVal.phoneNumber.errors
          }"
          formControlName="phoneNumber"
        />
        <div
          *ngIf="submitted && addressFormVal.phoneNumber.errors"
          class="invalid-feedback"
        >
          <div *ngIf="addressFormVal.phoneNumber.errors.required">
            Masukkan nomor telepon
          </div>
          <div
            *ngIf="
              addressFormVal.phoneNumber.errors.pattern ||
              addressFormVal.phoneNumber.errors.pattern
            "
          >
            Invalid Number
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Alamat</label>
        <!-- <label>Pin Location</label> -->

        <!-- maps should be implemented -->
        <!-- <button
          type="button"
          class="
            btn
            btnPurple
            btnPurpleOutline
            bgLightPurpleGrey
            br10
            btn-block
            mt-3
          "
        >
          <img class="" src="../../../assets/images/map-pin-2-line.png" />
          Gunakan Pin Lokasi
        </button> -->
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Tuliskan alamat Anda"
          [ngClass]="{
            'is-invalid': submitted && addressFormVal.address.errors
          }"
          formControlName="address"
        ></textarea>
        <div
          *ngIf="submitted && addressFormVal.address.errors"
          class="invalid-feedback"
        >
          <div *ngIf="addressFormVal.address.errors.required">
            Masukkan alamat
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Provinsi</label>
        <div class="custom-select-box bg-f7">
          <select
            class="form-control pl-2"
            [ngClass]="{
              'is-invalid': submitted && addressFormVal.province.errors
            }"
            formControlName="province"
            (change)="provinceChange($event)"
          >
            <option value="" disabled selected>Pilih provinsi</option>
            <option *ngFor="let p of provinceList" [ngValue]="p.province_name">
              {{ p.province_name }}
            </option>
          </select>
          <div
            *ngIf="submitted && addressFormVal.province.errors"
            class="invalid-feedback"
          >
            <div *ngIf="submitted && addressFormVal.province.errors.required">
              Pilih provinsi
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Kota/Kabupaten</label>
        <div class="custom-select-box bg-f7">
          <select
            class="form-control pl-2"
            [ngClass]="{
              'is-invalid': submitted && addressFormVal.countyDown.errors
            }"
            formControlName="countyDown"
            (change)="countyChange($event)"
          >
            <option value="" disabled selected>Pilih kota/kabupaten</option>
            <option *ngFor="let c of county" [ngValue]="c.regency_name">
              {{ c.regency_name }}
            </option>
          </select>
          <div
            *ngIf="submitted && addressFormVal.countyDown.errors"
            class="invalid-feedback"
          >
            <div *ngIf="submitted && addressFormVal.countyDown.errors.required">
              Pilih Kota/Kabupaten
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Kecamatan</label>
        <div class="custom-select-box bg-f7">
          <select
            class="form-control pl-2"
            [ngClass]="{
              'is-invalid': submitted && addressFormVal.district.errors
            }"
            formControlName="district"
            (change)="districtChange($event)"
          >
            <option value="" disabled selected>Pilih kecamatan</option>
            <option *ngFor="let d of district" [ngValue]="d.district_name">
              {{ d.district_name }}
            </option>
          </select>
          <div
            *ngIf="submitted && addressFormVal.district.errors"
            class="invalid-feedback"
          >
            <div *ngIf="submitted && addressFormVal.district.errors.required">
              Pilih Kecamatan
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Kelurahan</label>
        <div class="custom-select-box bg-f7">
          <select
            class="form-control pl-2"
            [ngClass]="{
              'is-invalid': submitted && addressFormVal.ward.errors
            }"
            formControlName="ward"
            (change)="wardChange($event)"
          >
            <option value="" disabled selected>Pilih kelurahan</option>
            <option *ngFor="let w of ward" [ngValue]="w.village_name">
              {{ w.village_name }}
            </option>
          </select>
          <div
            *ngIf="submitted && addressFormVal.ward.errors"
            class="invalid-feedback"
          >
            <div *ngIf="submitted && addressFormVal.ward.errors.required">
              Pilih Kelurahan
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Kodepos</label>
        <input
          type="text"
          class="form-control"
          placeholder="Masukkan kode pos"
          [ngClass]="{
            'is-invalid': submitted && addressFormVal.postalCode.errors
          }"
          formControlName="postalCode"
        />
        <div
          *ngIf="submitted && addressFormVal.postalCode.errors"
          class="invalid-feedback"
        >
          <div *ngIf="addressFormVal.postalCode.errors.required">
            Masukkan kode pos
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
       
      </div> -->
      <!-- <div class="bg-white br10 py-2 pl-2 mt-3">
                <p class="mb-0">Make Sure to fill address on detail</p>
            </div> -->
      <button
        type="submit"
        class="btn btnPurple br10 btn-block mt-5 mb-3 btn-50"
      >
        Simpan
      </button>
    </form>
  </section>

  <div class="addressContainer mt-4" *ngIf="showAddressCard">
    <div class="row">
      <div class="col-12">
        <div class="addressHeading">
          <h3>Daftar Alamat</h3>
          <p>Pilih alamat utama Anda</p>
        </div>
        <div class="card">
          <div class="text-right">
            <!-- <i
              class="mdi mdi-pencil float-righ"
              (click)="onClickEditAddress()"
            ></i> -->
          </div>
          <h6 class="name">{{ addressData.recipientsName }}</h6>
          <p lass="number">{{ addressData.phoneNumber }}</p>
          <!-- <p class="content">Jl. Karadenan RT. 05 RW. 06, Kelurahan Karadenan, Kecamatan Cibinong, Kabupaten Bogor, 16913</p> -->
          <p class="content">
            {{ addressData.address }},{{ addressData.ward }},{{
              addressData.countyDown
            }},{{ addressData.district }},{{ addressData.province }},{{
              addressData.postalCode
            }}
          </p>
          <button
            class="btn-50 btn edit-addressbtn"
            (click)="onClickEditAddress()"
          >
            Edit Alamat
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
