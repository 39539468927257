<app-header></app-header>
<section
  class="globalContainer pt70 px15 pb50 spinWheelContainer"
  [hidden]="showScanner"
>
  <app-back customClass="text-white" text="Kembali"></app-back>
  <article class="grow-spinwheel mt30">
    <h3></h3>
    <figure>
      <!-- <img src="../../../assets/images/logo.png" /> -->
    </figure>
    <h2 class="playGetRewards">Main & menangkan hadiah hingga Rp250.000!</h2>
    <p class="takesPoints">Dibutuhkan 300 poin untuk <br> memainkan ini</p>
    <div class="mt16">
      <div class="addGifts">
        <table
          class="table-borderless tableSpace"
          cellpadding="0"
          cellspacing="0"
        >
        <tr>
          <td class="spinWheelOut">
              <img class="stopimg" src="../../../assets/images/traingle.png" />

              <div class="position-relative">
                <canvas
                  (click)="getSegment($event)"
                  id="canvasOne"
                  height="250"
                  width="250"
                >
                  <p text-align="center" style="color: white">
                    Sorry, your browser doesn't support canvas. Please try
                    another.
                  </p>
                </canvas>
              </div>

              <img [class.disabled]="disabled == true"
                class="spinimg disabled"
                (click)="showPointsModal(); (false)"
                id="spin_button"
                src="../../../assets/images/Group 638.png"
              />
            </td>
          </tr>
          
        </table>
      </div>

      <div class="text-center pt-1"></div>
    </div>
    <p  *ngIf="pointUpdate; else elseblock rem-points" class="text-white mt23 text-center rem-points">
      Sisa Point Anda:  <span>{{loyaltyPoints}} Poin</span>
    </p>
    <ng-template #elseblock>
      <p  class="text-white mt23 text-center rem-points">
        Sisa Point Anda: 
        <span>{{ loyaltyPoints}} Poin</span>
      </p>
    </ng-template>
    <button class="btn btn seeMoreBtn" (click)="onClickSeeMore()">
      Lihat Hadiah
      <i class="mdi mdi-alert-circle-outline"></i>
    </button>
    <button class="btn btn seeMoreBtn" (click)="onClickMoreInfo()">
      Info Game
      <i class="mdi mdi-alert-circle-outline"></i>
    </button>
  
    <!-- <button class="btn btn seeMoreBtn" (click)="onClickFailedModal()">
      failed modal
    </button>

    <button class="btn btn seeMoreBtn" (click)="openNotEnoughPointsModal()">
      not enough points modal
    </button>

    <button class="btn btn seeMoreBtn" (click)="openSuccessModal()">
      SuccessModal
    </button> -->
  </article>

</section>

<app-footer></app-footer>

<div class="modal fade" bsModal #pointsModal="bs-modal" [config]="{backdrop: 'static'}"
  class="modal fade customModalContainer px30 spinPopupContainer"
  tabindex="-1" 
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <span class="closeIcon"
        ><i class="mdi mdi-close" (click)="closePointsModal()"></i
      ></span>
      <div class="modal-body pt-3">
        <h3 class="pointsModalTilte text-center mt-2">
          300 poin akan digunakan untuk main
        </h3>
        <div class="row mt20">
          <div class="col text-left pr-2">
            <button (click)="onClickCancel()"
              class="btn btn btnPurple btnOutlineGrey btn-block br10 btn-50"
            >
              Batal
            </button>
          </div>
          <div class="col text-right pl-2">
            <button
              class="btn btn btnPurple btn-block btn-50 br10"
              (click)="calculatePrize(); (false)"
            >
              Mainkan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  #failedModal="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">

      <div class="modal-body pt-3 text-center">
        <img class="pt10" src="../../../assets/images/anguish.png" />
        <h3 class="pointsModalTilte text-center mt30">Anda kurang beruntung</h3>
        <p class="greyContentSmall mt16">
          Maaf ibu/ayah belum beruntung dikesempatan kali ini, silakan dicoba
          lagi ya :)
        </p>
        <button
          class="btn btn btnPurple btn-block mt40 br10"
          (click)="closeFailedModal()"
        >
          Main Lagi
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  #notEnoughPointsModal="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto mt-25vh">
    <div class="modal-content">
      <span class="closeIcon"
        ><i class="mdi mdi-close" (click)="closeNotEnoughPointsModal()"></i
      ></span>
      <div class="modal-body pt-3 text-center">
        <!-- <h2 class="mt20"></h2> -->
        <img class="mt20 w-80" src="../../../assets/images/not-enough.png" />
        <!-- <h3 class="pointsModalTilte text-center mt30">Poin Belum Cukup</h3> -->
        <h3 class="pointsModalTilte text-center mt30">Poin tidak cukup</h3>
        <p class="greyContentSmall mt16">
          <!-- Ayo terus belanja produk PediaSure, kumpulkan poinnya dan tukarkan
          dengan hadiah yang Anda mau -->
          Dibutuhkan 300 poin untuk memainkan Spin of the Wheel
        </p>
        <p class="pointsBalance br10 btn-50">Point Anda: {{ loyaltyPoints }}</p>
        <button
          type="button"
          (click)="closeNotEnoughPointsModal()"
          class="my-2 sbtn btnPurple w-100 btn-50 br10"
        >
          Oke
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  #successModal="bs-modal"
  class="modal fade customModalContainer px30 spin-succeess-msg"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <!-- <span class="closeIcon"
        ><i class="mdi mdi-close"  (click)=" closeNotEnoughPointsModal()"></i
      ></span> -->
      <div class="modal-body pt-3 text-center">
        <h3>VOUCHER</h3>
        <h4>{{ sttrData?.name }}</h4>
        <img class="img-fluid" [src]="sttrData?.giftimage" />
        <!-- <h3 class="pointsModalTilte text-center mt30">Selamat!!</h3> -->
        <p class="greyContentSmall mt16">
          Selamat! Anda mendapatkan voucher <span>{{ sttrData?.name }}</span>
        </p>
        <button
          class="btn btn btnPurple btn-block br10"
          (click)="closeSuccessModal()"
         
      
        >
        <!-- Lihat e-Voucher -->
        Proses Hadiah
        </button>
      </div>
    </div>
  </div>
</div>
