import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from '../service/bugsnag.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  scanScode = null;
  typeCheck: number;
  loader: boolean = true;
  errMsg: any;
  redirect: any;
  productName: any;
  latitude: string;
  longitude: string;
  confidence: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private APIService: ApiService, 
    private spinner: NgxSpinnerService,
    private bsService: BugsnagService
  ) {

    this.getPosition();


    window.scrollTo({ top: 0 })
    if (this.route.snapshot.queryParams.c) {
      this.scanScode = this.route.snapshot.queryParams.c;
      console.log(this.scanScode, 'this.scanScode 12')
    } else if (this.route.snapshot.queryParams.u) {
      this.scanScode = this.route.snapshot.queryParams.u;
      console.log(this.scanScode, 'this.scanScode 12')
    }

    //this.spinner.show();
    if (
      this.scanScode === undefined ||
      this.scanScode === null ||
      this.scanScode === ''
    ) {
      this.loader = false;
      this.router.navigate(['/login-signup']);

      //alert('Scan Code is mandatory');
    }
    if (this.scanScode == undefined) {
      this.loader = false;
      sessionStorage.removeItem('iSprintData');
      //this.router.navigate(['/landing']);
      // alert("Please scan a valid QR code.")
    }

    sessionStorage.setItem('scanCode', this.scanScode);
  }


  ngOnInit(): void {

    // if (this.loader && this.scanScode) {
    //   this.getTraceabilityContent();
    // } else {
    //   // this.router.navigate(['/about-glucerna'])
    // }
  }


  getPosition() {
    this.APIService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      let confidence = pos.accuracy
      this.latitude = JSON.stringify(lat)
      this.longitude = JSON.stringify(lang)
      this.confidence = JSON.stringify(confidence);
      if (this.loader && this.scanScode) {
        this.getTraceabilityContent();
      }

    }, err => {
      this.bsService.notifyError(err);
    })
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  getTraceabilityContent() {

    this.loader = true;
    let scancoderes;
    this.APIService.productinfo(this.scanScode, this.latitude, this.longitude, this.confidence).subscribe((res: any) => {
      this.loader = false;
      scancoderes = res;
      console.log(res, 'res isprint');
      // sessionStorage.setItem('productWeight', res?.result?.size);
      // let weight: any = res?.result?.size
      // let weightinGrs = weight?.includes('GRS');
      // if (weightinGrs) {
      //   weight = weight.replace("GRS", "");
      //   var kilograms = parseInt(weight) / 1000;
      //   console.log(kilograms + " Kilograms");

      // }


      if (res.status === 'success') {
        sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
        let data = { data: 1 }
        // setTimeout(() => {
        //   console.log(sessionStorage.getItem('iSprintData'), 'i sprint data)');
        // }, 1000);
        this.router.navigate(['/landing', data]);
      } else {
        alert('Please scan a valid QR code.');
      };

      // let product = this.parseFunction(res.result.data.product.productName);
      // this.productName = product.en_US;

      // sessionStorage.setItem('productName', this.productName);

      // let productValue = res.result.data;
      // let skuData = productValue?.product?.txt;
      // console.log(skuData, 'skuData--');
      // skuData.filter((items) => {
      //   if (items.orders == 7) {
      //     let sf_product_code = this.parseFunction(items.value);
      //     let sfProductCode = sf_product_code.value;
      //     sessionStorage.setItem('sku', sfProductCode);
      //   }
      // });

      // if (res.result.code == '0') {
      //   if (
      //     res.result.data.uaidInfo.type == 1 ||
      //     res.result.data.uaidInfo.type == 7
      //   ) {
      //     console.log(
      //       res.result.data.uaidInfo.type,
      //       'res.result.data.uaidInfo.type'
      //     );
      //     this.router.navigate(['/traceability-home']);
      //     // this.router.navigate(['/about-glucerna',data])
      //   } else if (res.result.data.uaidInfo.type == 2) {
      //     console.log(
      //       res.result.data.uaidInfo.type,
      //       'res.result.data.uaidInfo.type'
      //     );
      //     this.router.navigate(['/login']);
      //     //  this.router.navigate(['/templanding'])
      //   }
      // } else {
      // }
    }, err => {
      this.spinner.hide();
      this.bsService.notifyError(err, this.scanScode);
      console.log(err.error.message)
      alert(err.error.message);
      this.loader = false;
    });
  }
}
