<app-header></app-header>
<section class="globalContainer redeemContainer pt70 px15 mb-5">
  <app-back text="Kembali"></app-back>
  <div class="redeem-points">
    <div class="redeemTitle">
      <h3>Tukar Poin Dengan Voucher e-Wallet</h3>
      <p class="warningMessage">
        <i class="mdi mdi-alert"></i>Pastikan nomor e-wallet Anda benar
      </p>
    </div>
    <form
      class="redeemForm mt-4 mb-5"
      [formGroup]="mobNumFormGroup"
      (ngSubmit)="onSubmitMobileForm()"
    >
      <div class="form-group">
        <label>Nomor HP</label>
        <div class="position-relative">
          <input
            type="number"
            class="form-control mobNumber"
            placeholder="8xx-xxxx-xxxx"
            formControlName="mobileNumber"
          />
          <span class="mobileCode">+62</span>
          <div
            *ngIf="submittedMobileForm && mobileFormValue.mobileNumber.errors"
            class="errIcon"
          >
            <img src="../../../assets/images/error-icon.png" />
          </div>
        </div>
      </div>
      <div class="field form-group mt-4 mb-4">
        <button type="submit" class="btn btnPurple w-100 btn-50 my-2">
          Redeem
        </button>
      </div>
    </form>
  </div>
</section>

<app-footer></app-footer>
