import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment as env } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { BugsnagService } from 'src/app/service/bugsnag.service';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  registerOtpForm: FormGroup;
  invalidOtp: boolean = false;
  otp: string;
  refNo: any;
  refName: any;
  prodCategory: any;
  activeClassButton: boolean = false;
  submitRegOtpForm = false;
  showResendOtp: boolean = false;
  mobileno: any;
  resend = false;
  timeLeft: any;
  count: number;
  beginTimer: boolean = false;
  lock = false;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  addUserRegisterForm: any = {};
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  formValue: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 })
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { formValue: string };
    this.formValue = state.formValue;
    // this.formValue = { mobileNumber: '1234', password: '12"' };
  }

  ngOnInit(): void {
    this.count = 3;
    this.beginTimer = true;

    this.mobileno = this.formValue.mobileNumber;
    this.registerOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    let scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.prodCategory = scancodeData?.brand;
  }

  get regOtpFormVal() {
    return this.registerOtpForm.controls;
  }

  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
    if (this.timeLeft == 0 && this.showResendOtp) {
      this.count = this.count - 1;
      if (this.count === 0) {
        this.lock = true;
        this.count = this.count - 1;
      }
    }
  }
  resendOTP() {
    this.ngOtpInput.setValue('');
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: this.formValue.password,
      confirmPassword: this.formValue.password,
    };
    let myuuid = uuidv4();
    let loggerFormData = {
      unique_id: myuuid,
      message: JSON.stringify(formData),
    };
    let tokengenresp;
    this.spinner.show();
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        tokengenresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
        }
        this.showResendOtp = false;
        //this.ngOtpInput.setValue('');

        let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
        //this.tokenReGenerate();
        let otpGenerateData = {
          mobile: getMobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          sessionId: sessionIdValue,
        };
        let otpgenresp;
        this.apiService
          .otpGenerate(otpGenerateData)
          .pipe(first())
          .subscribe(
            (res) => {
              //window.alert('OTP sent');
              this.spinner.hide();
              otpgenresp = res;
              if (res['status'].code == 200) {
              } else if (res['status'].code == 401) {
                this.apiService.logout();
              } else {
                this.apiService.showToaster(res['status'].message);
              }
            },
            (err) => {
              this.spinner.hide();
              this.bsService.notifyError(err, otpGenerateData);
              console.log(err, '=== OTP gererate error list =======');
            }
          );
      },err=>{
        this.spinner.hide();
        this.bsService.notifyError(err, formData);
      });
  }

  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false;
    this.otp = otp;

    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }
  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  onSubmitRegOtpForm() {
    this.submitRegOtpForm = true;
    if (this.otp == undefined || this.otp.length < 6) {
      console.log('otp err');
      return;
    }
    if (this.formValue.reset == true) {
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      let sessionId = sessionStorage.getItem('sessionID');

      let otpSubmitData = {
        identifierType: 'MOBILE',
        identifierValue: String(getMobile),
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: JSON.parse(sessionId),
        otp: this.otp,
      };

      this.spinner.show();
      let otpvalresp;
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe(
          (res) => {
            otpvalresp = res;
            this.spinner.hide();
            if (res['status'].code == 200) {
              sessionStorage.setItem('authToken', res['auth'].token);
              sessionStorage.setItem('authKey', res['auth'].key);
              // this.forgotOtpModal.hide();

              // this.ngOtpInputRef.setValue('');
              // this.forgotOtpForm.reset();

              let formData = {
                identifierType: 'MOBILE',
                identifierValue: getMobile,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                password: 'test123',
                newPassword: this.formValue.newPassword,
                confirmPassword: this.formValue.confirmNewPassword,
                token: token,
              };
            } else {
              this.invalidOtp = true;

              this.count = this.count - 1;
              if (this.count === 0) {
                this.lock = true;
              }

              this.count = this.count - 1;
            }
          },
          (err) => {
            console.log('err', err);
            this.spinner.hide();
            this.bsService.notifyError(err, otpSubmitData);
          }
        );
    } else {
      let formValues = this.registerOtpForm.value;
      let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
      let otpSubmitData = {
        identifierType: 'MOBILE',
        identifierValue: String(this.formValue.mobileNumber),
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: sessionIdValue,
        otp: this.otp,
      };
      this.spinner.show();
      let otpvalresp;
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe((res) => {
          otpvalresp = res;
          this.spinner.hide();
          if (res['status'].code === 200) {
            sessionStorage.setItem('authToken', res['auth'].token);
            sessionStorage.setItem('authKey', res['auth'].key);
            let token = res['auth'].token;
            this.apiService.getPosition().then((pos) => {
              let lat = pos.lat;
              let lang = pos.lng;
              let latitude = JSON.stringify(lat);
              let longitude = JSON.stringify(lang);
              let authToken = sessionStorage.getItem('authToken');
              let authKey = sessionStorage.getItem('authKey');

              let filledFormValues = this.getuserForm(
                this.formValue,
                latitude,
                longitude
              );
              console.log(filledFormValues, 'filledFormValues')

              this.spinner.show();
                let addcustresp;
              this.apiService
                .addCapCustomer(
                  filledFormValues,
                  String(this.formValue.mobileNumber),
                  token
                )
                .pipe(first())
                .subscribe((res) => {
                  addcustresp = res;
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    console.log('customer added');
                    // Social Login add
                    let socialUserId = sessionStorage.getItem('socialUserId');
                    console.log(socialUserId, 'socialUserId')
                    // console.log(socialUserId.length, 'socialUserId length')
                    if (socialUserId) {
                      this.spinner.show();
                      let socialSignup = {
                        mobile: JSON.stringify(this.formValue.mobileNumber),
                        social_id: socialUserId
                      }
                      let social;
                      this.apiService.postSocialLoginUser(socialSignup).subscribe(res => {
                        this.spinner.hide();
                        social = res;
                        console.log(res, 'post social user;')
                      },err=>{
                        this.spinner.hide();
                        this.bsService.notifyError(err, socialSignup);
                      })
                    }

                    this.router.navigate(['/profile']);
                  } else {
                    console.log(res['status'].message);
                  }
                },err=>{
                  this.spinner.hide();
                  this.bsService.notifyError(err, filledFormValues);
                });
            });
          } else {
            this.count = this.count - 1;
            if (this.count === 0) {
              this.lock = true;
            }

            this.invalidOtp = true;
          }
        },err=>{
          this.spinner.hide();
          this.bsService.notifyError(err, otpSubmitData);
        });
    }
  }

  getuserForm(userData, latitude, longitude) {
    return (this.addUserRegisterForm = {
      root: {
        customer: [
          {
            mobile: userData.mobileNumber,
            email: userData.email,
            firstname: userData.firstname,
            lastname: userData.lastname,
            custom_fields: {
              field: [
                {
                  name: 'categorycode',
                  value: this.prodCategory ? this.prodCategory : null,
                },
                {
                  name: 'reglat',
                  value: latitude,
                },
                {
                  name: 'reglong',
                  value: longitude,
                },
                // {
                //   name: 'referrar_code',
                //   value:
                //     this.refNo == ''
                //       ? 'NO REFERRAR CODE'
                //       : "'" + this.refNo + "'",
                // },
                // {
                //   name: 'referrar_name',
                //   value:
                //     this.refName == undefined
                //       ? 'NO REFERRAR NAME'
                //       : this.refName,
                // },
                {
                  name: 'child1_name',
                  value: '',
                },
                {
                  name: 'child2_name',
                  value: '',
                },
                {
                  name: 'child3_name',
                  value: '',
                },
              ],
            },
            extended_fields: {
              field: [
                {
                  name: 'dob',
                  value: moment(userData.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
                },
                {
                  name: 'child1_dob',
                  value: '',
                },
                {
                  name: 'child1_dob',
                  value: '',
                },
                {
                  name: 'child3_dob',
                  value: '',
                },
              ]
            },
          },
        ],
      },
    });
  }
}
