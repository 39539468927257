import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit {
  mobNumFormGroup: FormGroup;
  submittedMobileForm = false;
  customerData: any;
  rewardID: any;
  pointsExcedeedError: boolean = false;
  userPoints: any;
  intouchPoints: any;
  constructor(
    private formBuilder: FormBuilder, 
    private apiService: ApiService,
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 })
    const navigation = this.router.getCurrentNavigation();
    console.log(navigation.extras.state);
    this.customerData = navigation.extras.state.customer;
    this.rewardID = navigation.extras.state.id;
    this.intouchPoints = navigation.extras.state.intouchPoints;

  }

  ngOnInit(): void {
    this.mobNumFormGroup = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^((\\+63-?)|0)?[0-9]{9,12}$'),
        ],
      ],
    }
    )
  }

  get mobileFormValue() {
    return this.mobNumFormGroup.controls;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let getcustresp;
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      getcustresp = res;
      // Block the user
      let block = res['customers']['customer'][0].custom_fields.field;
      let blockUser = block.filter(filt => {
        return filt.name == 'block_count'
      });
      console.log(blockUser, 'blockUser===');
      if (blockUser[0]?.value == '1') {
        sessionStorage.clear();
        this.router.navigate(['/blocker']);
      }
      this.spinner.hide();
      if (res['status'].code == 200) {
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';
        let userPoints = this.userPoints;
        // let rewardPoints = parseInt(this.rewardData?.intouchPoints);
        let rewardPoints = parseInt(this.intouchPoints);
        if (rewardPoints > userPoints) {
          this.pointsExcedeedError = true;
        } else {
          this.pointsExcedeedError = false;
        }
      } else if (res['status'].code == 401) {
        this.spinner.show();
        let tokenReGenerate = {
          mobile: mobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          key: key,
        };
        let tokenGenerate;
        this.apiService
          .tokenReGenerate(tokenReGenerate)
          .pipe(first())
          .subscribe((resGen) => {
            tokenGenerate = resGen;
            this.spinner.hide();
            console.log(tokenReGenerate, 'token regen');
            if (resGen['status'].code == 200) {
              sessionStorage.setItem('authToken', resGen['auth'].token);
              this.spinner.show();
              this.apiService
                .getCapCustomer(mobile, resGen['auth'].token)
                .subscribe();
              this.spinner.hide();
            }
          },err=>{
            this.spinner.hide();
            this.bsService.notifyError(err, tokenReGenerate);
          });
      }
    },err=>{
      this.spinner.hide();
      this.bsService.notifyError(err, token);
    });
  }

  onSubmitMobileForm() {
    this.submittedMobileForm = true;
    if (this.mobNumFormGroup.invalid) {
      return;
    } else {
      let time = new Date();
      let todayDate = moment(new Date()).format('YYYY-MM-DD HH:MM:SS');
      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken');
      let formData = {
        notes: "",
        rewards: [
          {
            quantity: 1,
            rewardId: this.rewardID,
          },
        ],
        redemptionTime: todayDate,
        transactionNumber: this.rewardID + '_' + time.getTime(),
      };
      let marwalreward;
      this.spinner.show();
      this.apiService.marvalRewardIssue(formData, getMobile, getAuthKey)
        .pipe(first()).subscribe(
          (res: any) => {
            marwalreward = res;
            this.spinner.hide();
            if (res['status'].code == 200 || res['status'].success == true) {
              console.log(res, 'marvalRewardIssue');
              this.getCustomer();
              this.router.navigate(['/success']);
            } else if (res['status'].code != 200) {
              this.apiService.showToasterError(res['status'].message)
              console.log(res['status'].code, ' status code');
              console.log(res['status'].message, ' status message');
              if (res['status'].code == 8003) {
                // this.pointsExceeded = true;
                // console.log(this.pointsExceeded, ' this.pointsExceeded ');
              }
            }
          },
          (err) => {
            this.spinner.hide();
            this.bsService.notifyError(err, formData);
          }
        )
    }



  }

}


