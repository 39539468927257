<footer class="footerContainer px15 pt40">
    <div class="footerLogo">
        <img class="" src="../../../assets/images/pediasurefooterlogo.png">
    </div>
    <div class="row mt25">
        <div class="col">
            <h3 class="textSandal">{{ 'FOOTER.SITE' | translate }}</h3>
            <a class="mt16" routerLink="/faq/loyalty"> {{ 'FOOTER.ABOUTUS' | translate }}</a>
            <a href="https://pediasure.co.id/contact">{{ 'FOOTER.CONTACTUS' | translate }} </a>
            <a routerLink="/faq/terms-conditions">{{ 'FOOTER.TERMS' | translate }} </a>
            <a routerLink="/faq">{{ 'FOOTER.FAQ' | translate }}</a>
        </div>
        <div class="col">
            <div class="">
                <i class="mdi mdi-facebook-box"></i>
            </div>
            <h3 class="textSandal">{{ 'FOOTER.LEGAL' | translate }}</h3>
            <a href="https://www.id.abbott/" class="mt16">{{ 'FOOTER.ABBOT_INDONESIA' | translate }}</a>
            <a href="https://www.abbott.com/">{{ 'FOOTER.ABBOT_GLOBAL' | translate }}</a>
            <a href="https://pediasure.co.id/sitemap">{{ 'FOOTER.SITEMAP' | translate }} </a>
            <a routerLink="/faq/privacy-policy">{{ 'FOOTER.PRIVACY' | translate }} </a>
        </div>
    </div>
    <div class="socialIconBlock text-center mt30 pb20 d-flex justify-content-center align-items-center">
        <a href="https://www.facebook.com/PediasureIndonesia/"><img class="mr-2" src="../../../assets/images/facebook.png"></a>
        <a href="https://www.instagram.com/pediasureindonesia/" ><img class="ml-2" src="../../../assets/images/instagram.png"></a>
        <span class="iconify" data-icon="mdi:information-variant"></span>
    </div>
    <p class="mb-0 pb30 mt15 text-white text-center"> &#169; Copyright 2021 Abbott Laboratories</p>
</footer>