import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { v4 as uuidv4 } from 'uuid';
//import jsQR from 'jsqr';
declare var ZXing: any;
import * as moment from 'moment';
import { BugsnagService } from 'src/app/service/bugsnag.service';
import { environment as env } from '../../../environments/environment';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  showScanner: boolean = false;
  showData: boolean = true;
  videoStart = false;
  iSprintData: any;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  max: number = 100;
  value: number = 35;
  points: any;
  customerData: any;
  token: any;
  scanCode: any;
  profileImage: any;
  finalUserImage: any = '';

  @ViewChild('completeProfileDataModel', { static: true })
  completeProfileDataModel: ModalDirective;
  @ViewChild(' scanQrcodeGuideModal', { static: true })
  scanQrcodeGuideModal: ModalDirective;
  @ViewChild('profileNotCoomplete', { static: true })
  profileNotCoomplete: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('camerapermission', { static: true }) camerapermission: ModalDirective;
  latitude: string;
  longitude: string;
  pointsExpired: any;
  loyaltyPoints:any
  pointsExpiredDate: any;
  currentYear: any;
  showPointsExpiry: boolean = false;
  confidence: string;
  profileFieldNotFound = false;
  firstName: any;
  currentSlab: any;
  labelledMetal: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
    translate.addLangs(['en_US', 'my_MH']);
    translate.setDefaultLang('en_US');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|my_MH/) ? browserLang : 'en_US');
    this.getCustomer();
  }

  ngOnInit(): void {
   
    this.getPosition();
    //this.openProfileDataCompleteModal();

    this.token = sessionStorage.getItem('authToken');
    if (this.token) {
      return;
    } else {
      this.router.navigate(['/login-signup']);
    }
  }

  isProfileComplete() {
    let includedFields = [
      'street_name',
      'city',
      'district',
      'postal_code',
      'receipient_name',
      'recipient_phone',
      'province',
      'village',
      'product_tried',
      'producttime_tried',
      'child1_name',
      'child1_lastname',
    ];
    let firstname = this.customerData.firstname;
    let lastname = this.customerData.lastname;
    let mobile = this.customerData.mobile;
    let email = this.customerData.email;
    let child1_name: any;
    let child1_lastname: any;
    let child1_dob: any;
    let dob: any;

    this.customerData.extended_fields.field.forEach((ele: any) => {
      if (ele.name == 'dob') {
        dob = ele.value;
      }
      if (ele.name == 'child1_dob') {
        child1_dob = ele.value;
      }
    });

    this.customerData.custom_fields.field.forEach((ele) => {
      if (ele.name == 'child1_name') {
        child1_name = ele.value;
      }
      if (ele.name == 'child1_lastname') {
        child1_lastname = ele.value;
      }
    });

    if (
      this.isEmpty(firstname) ||
      this.isEmpty(lastname) ||
      this.isEmpty(mobile) ||
      this.isEmpty(email) ||
      this.isEmpty(dob) ||
      this.isEmpty(child1_lastname) ||
      this.isEmpty(child1_name) ||
      this.isEmpty(child1_dob)
    ) {
      this.profileFieldNotFound = true;
    } else {
      let customAddressfields: any = [];
      this.customerData.custom_fields.field.forEach(({ name }) => {
        customAddressfields.push(name);
      });

      let includedAddressFields = ['product_tried', 'producttime_tried'];

      includedAddressFields.forEach((ele) => {
        if (customAddressfields.indexOf(ele) === -1) {
          this.profileFieldNotFound = true;
          console.log(ele);
          return;
        }
      });
    }

    if (this.profileFieldNotFound) {
      this.profileNotCoomplete.show();
      return;
    }
  }

  isEmpty(str) {
    return !str || str.length === 0;
  }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      console.log('pos: ', pos);
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
    });
  }  

  onClickScanner() {
    this.showScanner = true;
    this.showData = false;

    let selectedDeviceId;
    const codeReader = new ZXing.BrowserMultiFormatReader();
    console.log('ZXing code reader initialized');
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById('sourceSelect');
        selectedDeviceId = videoInputDevices[0].deviceId;
        codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
          if (result) {
            console.log(result);
            //document.getElementById('result').textContent = result.text

            //if (code) {
            let data = result.text;
            console.log(data, 'scanned data===');
            let array = data.split('=');
            let scannedCode = array[1];
            this.scanCode = scannedCode;
            codeReader.reset();
            //this.scanCode = 'VdiKfAJvgVF7';

            console.log(scannedCode, ' scannedCode');
            sessionStorage.setItem('scannedCode', scannedCode);
            let scanCode = sessionStorage.getItem('scannedCode');
            console.log(scanCode, 'scanCode first');
            this.spinner.show();
            let prdinfo;
            this.apiService
              .productinfo(
                scannedCode,
                this.latitude,
                this.longitude,
                this.confidence
              )
              .subscribe(
                (res: any) => {
                  prdinfo = res;
                  console.log(res, 'res isprint');

                  let weight: any = res?.result?.size;
                  let weightinGrs = weight?.includes('GRS');
                  var kilograms;
                  if (weightinGrs) {
                    weight = weight.replace('GRS', '');
                    kilograms = parseInt(weight) / 1000;
                    console.log(kilograms + ' Kilograms');
                  }
                  sessionStorage.setItem('productWeight', kilograms);

                  this.spinner.hide();
                  if (res.status === 'success') {
                    sessionStorage.setItem(
                      'iSprintData',
                      JSON.stringify(res.result)
                    );
                    this.closeScanQrcodeGuideModal();
                  } else {
                    alert('Please scan a valid QR code.');
                  }
                },
                (err) => {
                  console.log(err, 'err ---');
                  this.spinner.hide();
                  this.bsService.notifyError(err, scannedCode);
                  alert(err.error.message);
                }
              );

            //}
          }
          if (err && !(err instanceof ZXing.NotFoundException)) {
            console.error(err);
            document.getElementById('result').textContent = err;
          }
        });
        console.log(
          `Started continous decode from camera with id ${selectedDeviceId}`
        );
      })
      .catch((err) => {
        console.error(err);
      });

    // this.showData = false;

    // if (this.showScanner) {
    //   this.startVideo();
    // }
  }


  // toggleVideoMedia() {
  //   if (this.videoStart) {
  //     this.stopVideo();
  //   } else {
  //     this.startVideo()
  //   }
  // }
  // startVideo() {
  //   this.medias.video = { facingMode: 'environment', width: 100, height: 150 }
  //   navigator.mediaDevices.getUserMedia(this.medias).then(
  //     (localStream: MediaStream) => {
  //       this.videoElm.nativeElement.srcObject = localStream;
  //       this.videoStart = true;
  //       this.checkImage();
  //     }
  //   ).catch(
  //     error => {
  //       console.error(error);
  //       this.videoStart = false;
  //     }
  //   );
  // }
  // stopVideo() {
  //   this.medias.video = false;
  //   this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
  //   this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
  //   this.videoStart = false;
  // }
  // checkImage() {
  //   const WIDTH = this.videoElm.nativeElement.clientWidth;
  //   const HEIGHT = this.videoElm.nativeElement.clientHeight;
  //   this.canvasElm.nativeElement.width = WIDTH;
  //   this.canvasElm.nativeElement.height = HEIGHT;

  //   const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

  //   ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
  //   const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
  //   const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
  //   if (code) {
  //     let data = code.data;
  //     console.log(data, 'scanned data===')
  //     let array = data.split("=");
  //     let scannedCode = array[1];
  //     this.scanCode = scannedCode;
  //     console.log(scannedCode, ' scannedCode')
  //     sessionStorage.setItem('scannedCode', scannedCode);
  //     let scanCode = sessionStorage.getItem('scannedCode');
  //     console.log(scanCode, 'scanCode first');
  //     this.spinner.show()
  //     this.apiService.productinfo(scannedCode, this.latitude, this.longitude, this.confidence).subscribe((res: any) => {
  //       console.log(res, 'res isprint');

  //       let weight: any = res?.result?.size
  //       let weightinGrs = weight?.includes('GRS');
  //       var kilograms
  //       if (weightinGrs) {
  //         weight = weight.replace("GRS", "");
  //         kilograms = parseInt(weight) / 1000;
  //         console.log(kilograms + " Kilograms");

  //       }
  //       sessionStorage.setItem('productWeight', kilograms)

  //       this.spinner.hide()
  //       if (res.status === 'success') {
  //         sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
  //         this.closeScanQrcodeGuideModal();
  //       } else {
  //         alert('Please scan a valid QR code.');
  //       }
  //     },err=>{
  //       this.spinner.hide();
  //       console.log(err, 'err ---');
  //       alert(err.error.message)

  //     })

  //   }
  //   else {
  //     setTimeout(() => { this.checkImage(); }, 100)
  //   }
  // }

  colseProfileModal() {
    this.profileNotCoomplete.hide();
  }
  closeScanner() {
    this.showScanner = false;
    this.showData = true;
  }

  onClickCheckProduct() {
    this.scanQrcodeGuideModal.show();
  }

  closeScanInstMdl() {
    this.scanQrcodeGuideModal.hide();
  }

  openProfileDataCompleteModal() {
    this.completeProfileDataModel.show();
  }
  closeCompleteProfileDataModal() {
    this.completeProfileDataModel.hide();
  }
  logOut() {
    sessionStorage.clear();
    let data = {
      data: 'logout',
    };
    this.router.navigate(['/login-signup', data]);
  }
  onClickScanQr() {
    this.scanQrcodeGuideModal.show();
  }

  closeScanQrcodeGuideModal() {
    let data = {
      scanCode: this.scanCode,
    };
    this.router.navigate(['/verify-product', data]);
    this.scanQrcodeGuideModal.hide();
  }
  getCustomer() {
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.currentYear = new Date().getFullYear();
    let getcustresp;
    this.apiService
      .getCapCustomer(getMobile, getAuthKey)
      .pipe(first())
      .subscribe((res) => {
        getcustresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          //console.log("Result", res);
          this.customerData = res['customers']['customer'][0];
          this.isProfileComplete();
          this.points = this.customerData?.lifetime_points;
          this.loyaltyPoints = parseInt(this.customerData?.loyalty_points);
          this.firstName = this.customerData?.firstname;
          this.currentSlab = this.customerData?.current_slab
            if(this.loyaltyPoints >=0 && this.loyaltyPoints <= 499) this.labelledMetal = 'Bronze'
            else if(this.loyaltyPoints >=500 && this.loyaltyPoints <= 999) this.labelledMetal = 'Silver'
            else if(this.loyaltyPoints >=1000 && this.loyaltyPoints <= 1999) this.labelledMetal = 'Gold'
            else if(this.loyaltyPoints >=2000) this.labelledMetal = 'Platinum'
          //this.labelledMetal = this.currentSlab?.charAt(0).toUpperCase() + this.currentSlab?.slice(1);
          console.log("labeeled: ", this.labelledMetal)
         // this.labelledMetal = 'Platinum'

          this.value = this.points
          console.log(
            this.customerData?.expiry_schedule.schedule,
            'this.customerData?.expiry_schedule?'
          );
          if (this.customerData?.expiry_schedule?.schedule?.length && this.customerData?.expiry_schedule?.schedule?.length == 1) {
            this.pointsExpired =
              this.customerData?.expiry_schedule?.schedule[0]?.points;
            this.pointsExpiredDate = moment(
              this.customerData?.expiry_schedule?.schedule[0]?.expiry_date
            ).format('D MMM YYYY');
            this.showPointsExpiry = this.pointsExpired <= 0 ? false : true;
          }else if(this.customerData?.expiry_schedule?.schedule?.length > 1){
            const result :any= this.customerData.expiry_schedule.schedule.filter(date => moment(date.expiry_date) > moment());
            const lowest = result.reduce((previous, current) => {
              return current.expiry_date < previous.expiry_date ? current : previous;
           });
             this.pointsExpired = lowest?.points;
             this.pointsExpiredDate = moment(lowest.expiry_date).format('D MMM YYYY')
             this.showPointsExpiry = this.pointsExpired <= 0 ? false : true;
           
          }

          let profilePic = res['customers']['customer'][0].custom_fields.field;
          let image = profilePic.filter((res) => res.name == 'profileimage');
          this.profileImage = image[0]?.value;
          console.log(this.profileImage, 'this.profileImage');
          this.spinner.show();
          this.apiService
            .getSavedImageFinal(getMobile, getAuthKey, this.profileImage)
            .pipe(first())
            .subscribe((res: any) => {
              this.spinner.hide();
              this.finalUserImage = res.file_path;
            });

          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == 'block_count';
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            sessionStorage.clear();
            this.router.navigate(['/blocker']);
          }
        }
      }, err => {
        this.spinner.hide();
        this.spinner.hide();
        this.bsService.notifyError(err, getAuthKey);
      });
  }
}
