import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BugsnagService } from 'src/app/service/bugsnag.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  termsData:any;
  termsHeading:any;
  constructor(
    private spinner: NgxSpinnerService, 
    private route:ActivatedRoute, 
    private APIService: ApiService, 
    private bsService: BugsnagService
    ) {
    window.scrollTo({top: 0})
   }

  ngOnInit(): void {
    this.getTermsandConditions();
  }

  getTermsandConditions(){
    this.spinner.show();
    this.APIService.getcontentBlocks().subscribe((res:any)=>{
      this.spinner.hide();
      let termsData = res.data.filter(element => element.key == 'Terms and Conditions');
      console.log(termsData[0].maintnc, 'termsData')
      this.termsHeading = termsData[0];
      this.termsData = termsData[0].maintnc[0];
      console.log(this.termsData, 'termsData 1')
      
    },err=>{
      this.spinner.hide();
      this.bsService.notifyError(err);
    })
  }

}
