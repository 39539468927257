import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TimelineMax, TweenMax } from 'gsap';
import { first } from 'rxjs/operators';
import { ApiService } from '../../service/api.service';
import { environment as env } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';

import jsQR from 'jsqr';
import { BugsnagService } from 'src/app/service/bugsnag.service';

declare var $: any;

declare let Winwheel: any;

@Component({
  selector: 'app-spin-wheel',
  templateUrl: './spin-wheel.component.html',
  styleUrls: ['./spin-wheel.component.scss'],
})
export class SpinWheelComponent implements OnInit {
  theWheel: any;
  wheelPower = 0;
  wheelSpinning = false;
  winningSegment: string;
  myDate = new Date();
  growtermsContent: any;
  pediasure = [];
  // nonmilk_similac_array = [];
  // nonmilk_grow_array = [];
  // all_nonmilk_array = [];
  isGrow = false;
  eligible = false;
  showProfile: Boolean = true;
  // scanCode: any;
  // FinalMilkcode: any;
  // categoryCode: any = [];
  // checkQRCode2: any;
  // scancodeData: any;
  // prodCategory: any;

  remaining_grow_chances: any = 0;
  gameId: any;
  //growNonMilk: any;
  SimilacMom: any;
  SimilacS4: any;

  is_grow: boolean = false;

  custom_isGrow: boolean = false;
  eligible_nonmilk_grow: boolean = false;
  eligible_nonmilk_similac: boolean = false;
  redemmed: boolean = false;
  is_not_expired: boolean = false;
  game_is_eligible: boolean = false;
  cp_non_similac: any = [];

  expiryDate: any;
  scannedDate: any;
  gift_delivery_date: any;

  content: any = [];
  subCatCode: any;
  milkCode: any;
  is_milk: boolean = false;
  sttrData: any;
  is_eligilbe_grow: boolean;
  authToken: any;
  modalRef: BsModalRef;
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  remaining_similac_chances: any;
  showScanner: boolean = false;

  similac_array_length: any;
  grow_array_length: any;
  activeTab: any;
  showActiveGrowTab = false;
  termsContent: any;
  couponCode: any;
  expiry: any;
disabled:boolean = false
  // giftValues: any = [
  //   { id: 1, text: 'bộ bowling đà nẵng', value: 'P-005692' },
  //   { id: 2, text: 'không trúng', value: 'P-00000' },
  //   { id: 3, text: 'scooter', value: 'P-005691' },
  //   { id: 4, text: 'không trúng', value: 'P-00000' },
  //   { id: 5, text: 'Bộ thang thăng bằng', value: 'P-005831' },
  //   { id: 6, text: 'không trúng', value: 'P-00000' },
  //   { id: 7, text: 'scooter', value: 'P-005691' },
  //   { id: 8, text: 'không trúng', value: 'P-00000' },
  // ];

  giftValues: any = [
    { id: 1, text: 'E-Voucher Tokopedia', value: 'P-00001' },
    { id: 2, text: 'E-Voucher Grab food', value: 'P-00003' },
    { id: 3, text: 'E-Voucher Alfamart', value: 'P-00007' },
    { id: 4, text: 'E-Voucher Blibli', value: 'P-00002' },
    { id: 5, text: 'E-Voucher Tokopedia', value: 'P-00004' },
    { id: 6, text: 'E-Voucher Grab food', value: 'P-00006' },
    { id: 7, text: 'E-Voucher Alfamart', value: 'P-00008' },
    { id: 8, text: 'E-Voucher Blibli', value: 'P-00005' },

  ];

  @ViewChild('pointsModal', { static: true }) pointsModal: ModalDirective;
  @ViewChild('successModal', { static: true }) successModal: ModalDirective;
  @ViewChild('failedModal', { static: true }) failedModaL: ModalDirective;
  @ViewChild('notEnoughPointsModal', { static: true }) notEnoughPointsModal: ModalDirective;


  @ViewChild('rewardModal', { static: true }) rewardModal: ModalDirective;

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('invalidQrCodeModal', { static: true })
  invalidQrCodeModal: ModalDirective;
  @ViewChild('milkCodeModal', { static: true }) milkCodeModal: ModalDirective;
  @ViewChild('successQrCodeModal', { static: true })
  successQrCodeModal: ModalDirective;
  @ViewChild('betterLuckNextTime', { static: true })
  betterLuckNextTime: ModalDirective;
  @ViewChild('termsSimilac', { static: true }) termsSimilac: ModalDirective;

  config = {
    ignoreBackdropClick: true,
  };
  customerData: any;
  loyaltyPoints: any;
  rewardData: any;
  intouchPoints: any;
  showSuccessMdl: boolean = false;
  pointUpdate:boolean =false;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
    const navigation = this.router.getCurrentNavigation();
    this.customerData = navigation.extras.state.customer;
    this.loyaltyPoints = this.customerData.loyalty_points;
    this.rewardData = navigation.extras.state.id;
    this.intouchPoints = navigation.extras.state.intouchPoints
    this.authToken = sessionStorage.getItem('authToken');
    this.myDate.setDate(this.myDate.getDate() + 45);
    //this.scanCode = sessionStorage.getItem('scanCode');
    //  if (this.scanCode != 'undefined') {
    // let qrCodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //  let qrCodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    //  this.categoryCode = qrCodeData?.product?.categoryCode;
    //  this.checkQRCode2 = qrCodeData?.uaidInfo?.type;
    // this.scancodeData = qrCodeData;
    //  this.prodCategory = this.categoryCode;
    this.activeTab = this.route.snapshot.params.id;
    //   } else {
    console.log('cleared local storage---');
    //   }
    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    //  this.scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));

    //  this.prodCategory = this.scancodeData?.product?.categoryCode;

    // this.categoryCode == 'Similac'
    // ? (this.is_grow = false)
    // : (this.is_grow = true);

    // setTimeout(() => {
    //   this.remaining_grow_chances = JSON.parse(
    //     sessionStorage.getItem('grow_nonmilk_remaining_count')
    //   );
    //   this.remaining_similac_chances = JSON.parse(
    //     sessionStorage.getItem('similac_nonmilk_remaining_count')
    //   );

    //   this.similac_array_length = JSON.parse(
    //     sessionStorage.getItem('similacMilCount')
    //   );
    //   this.grow_array_length = JSON.parse(
    //     sessionStorage.getItem('growMilkCount')
    //   );

    //   if (this.similac_array_length == 0 || this.similac_array_length == null) {
    //     this.remaining_similac_chances = 5;
    //   }
    //   if (this.grow_array_length == 0 || this.similac_array_length == null) {
    //     this.remaining_grow_chances = 5;
    //   }

    //   this.remaining_grow_chances =
    //     this.remaining_grow_chances < 0
    //       ? (this.remaining_grow_chances = 0)
    //       : 0 + this.remaining_grow_chances;
    //   this.remaining_similac_chances =
    //     this.remaining_similac_chances < 0
    //       ? (this.remaining_similac_chances = 0)
    //       : 0 + this.remaining_similac_chances;
    // if(!this.authToken){
    //   this.remaining_grow_chances = 5;
    //   this.remaining_similac_chances = 5;

    // }
    // }, 2000);

    let today = new Date();
    this.scannedDate = moment(today).format('YYYY-MM-DD');

    let endDate = moment(today).add(45, 'days');
    this.gift_delivery_date = moment(endDate).format('YYYY-MM-DD');
    let expiry_date = moment(today).add(30, 'days');
    this.expiryDate = moment(expiry_date).format('YYYY-MM-DD');

    // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
    // this.scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    // this.prodCategory = this.scancodeData?.product?.categoryCode;


  }

  ngOnInit(): void {
    this.getProductDescCode();
    //this.getPreviousTranscation();
    this.getGameId();
    this.getCustomer()
    // if (this.activeTab === '11') {
    //   this.showActiveGrowTab = true;
    // } else {
    //   this.showActiveGrowTab = false;
    // }
  }

  openSuccessModal() {
    this.getCustomer()
    this.successModal.show();
    
  }

  closeNotEnoughPointsModal() {
    this.notEnoughPointsModal.hide();
  }
  openNotEnoughPointsModal() {
    this.notEnoughPointsModal.show();
  }
  onClickFailedModal() {
    this.failedModaL.show();
  }

  closeFailedModal() {
    this.failedModaL.hide();
    this.router.navigate(['/profile'])
  }
  onClickSpin() {
    this.pointsModal.show();
  }
  onClickSeeMore() {
    this.router.navigate(['/tnc']);
  }
  onClickMoreInfo() {
    this.router.navigate(['/info']);
  }

  onClickCancel(){
    this.closePointsModal()
  }

  



  ngAfterViewInit() {
    this.theWheel = new Winwheel({
      canvasId: 'canvasOne',
      responsive: true,
      numSegments: 8, // Specify number of segments.
      outerRadius: 200, // Set outer radius so wheel fits inside the background.
      drawText: true,             // Code drawn text can be used with segment images.
      textFontSize: 12,
      textOrientation: 'curved', // Note use of curved text.
      textDirection: 'reversed', // Set other text options as desired.
      textAlignment: 'outer',
      textMargin: 5,
      textFontFamily: 'monospace',
      textStrokeStyle: 'black',
      textLineWidth: 3,
      textFillStyle: 'white',
      drawMode: 'image', // Must be segmentImage to draw wheel using one image per segemnt.
      segments: this.giftValues, // Define segments.
      // Specify the animation to use.
      animation: {
        type: 'spinToStop',
        duration: 15,
        spins: 25,
        //  easing       : 'Power2.easeInOut',
        callbackFinished: this.alertPrize.bind(this),
      },
    });

    this.loadWheel(this.theWheel);
    setTimeout(() => {
      this.resetWheel();
    }, 1000);
  }

  getCustomer() {
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    let getcustresp;
    this.spinner.show();
    this.apiService
      .getCapCustomer(getMobile, getAuthKey)
      .pipe(first())
      .subscribe((res) => {
        let temp = {
          tried: '',
          time_tried: '',
        };
        getcustresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          // Block the user
          let block = res['customers']['customer'][0].custom_fields.field;
          let blockUser = block.filter(filt => {
            return filt.name == 'block_count'
          });
          console.log(blockUser, 'blockUser===');
          if (blockUser[0]?.value == '1') {
            sessionStorage.clear();
            this.router.navigate(['/blocker']);
          }
          this.customerData = res['customers']['customer'][0];
          console.log("customer data: ", this.customerData);
          if(this.loyaltyPoints != this.customerData.loyalty_points){
            this.pointUpdate = true
          }
          this.loyaltyPoints = this.customerData.loyalty_points;
        }
      }, err => {
        this.spinner.hide();
        this.bsService.notifyError(err, getAuthKey);
      });

  }
  loadWheel(wheel) {
    let loadedImg = new Image();

    // Create callback to execute once the image has finished loading.
    loadedImg.onload = function () {
      wheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
      wheel.draw(); // Also call draw function to render the wheel.
    };

    // loadedImg.src = "../../../assets/images/circle-cropped_new_grow (1).png";
    // loadedImg.src = '../../../assets/images/-e-gift-grow2.svg';
    //loadedImg.src = '../../../assets/images/gift-grow-v-1.png';
    //loadedImg.src = '../../../assets/images/newgame/spinwheel.png';
       loadedImg.src = '../../../assets/images/newgame/Spin.png';

    loadedImg.width = 250;
    loadedImg.height = 250;
  }

  startSpin(): void {
    if (this.wheelSpinning === false) {
      if (this.wheelPower === 1) {
        this.theWheel.animation.spins = 3;
      } else if (this.wheelPower === 2) {
        this.theWheel.animation.spins = 8;
      } else if (this.wheelPower === 3) {
        this.theWheel.animation.spins = 15;
      }
      //  }
      this.theWheel.startAnimation(new TweenMax(new TimelineMax()));
      this.wheelSpinning = true;
    }
  }
  resetWheel(): void {
    this.theWheel.stopAnimation(false);
    this.theWheel.rotationAngle = 0;
    this.theWheel.draw();
    this.wheelSpinning = false;
  }

  alertPrize(): void {
    this.disabled = true
    console.log(
      this.theWheel.getIndicatedSegment(),
      'this.theWheel.getIndicatedSegment()'
    );
    // this.user_gift = this.theWheel.getIndicatedSegment().value;
    if (this.user_gift == 'P-00000') {
      //alert('Ada yang salah, Silakan coba lagi')
      console.log(this.user_gift);
      setTimeout(() => {
        this.onClickFailedModal();
      }, 500);

      // this.openbetterLuckModal();
    }
    // else if (this.user_gift == 'P-00004' || this.user_gift == 'P-00005') {
    //   this.router.navigate(['/profile/redeem']);
    // }
    else if (this.user_gift != 'P-00000' && this.showSuccessMdl) {
      // setTimeout(() => {
      this.openRewardPopup();
      // }, 1000);

    }
    //this.resetWheel();
    this.disabled = false
  }

  closePointsModal() {
    this.pointsModal.hide();
  }
  getSegment(e): void {
    const clickedSegment = this.theWheel.getSegmentAt(e.clientX, e.clientY);
    console.log('Segment clicked - ', clickedSegment);
  }

  showPointsModal() {
    // this.loyaltyPoints <= 300 ? this.pointsModal.show() : this.notEnoughPointsModal.show();
    this.loyaltyPoints <= env.GAME_LOYALTY_POINTS ? this.notEnoughPointsModal.show() : this.pointsModal.show();
  }

  calculatePrize() {
    this.pointsModal.hide();
    this.disabled = true
    this.getGameId();
    console.log('ff', this.global_non_redemmed_grow.length);
    // if (this.global_non_redemmed_grow.length > 0) {
    // this.gameAllocation();
    // }
    this.gameAllocation();
    this.startSpin();
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  getProductDescCode() {
    // this.content = this.scancodeData?.product?.txt;
    // if (this.content) {
    //   this.content.forEach((item: any) => {
    //     if (this.parseFunction(item.name).en_US == 'SubCategoryCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.subCatCode = parsedPrimaryValue.value;
    //     }

    //     if (this.parseFunction(item.name).en_US == 'IsMilkCode') {
    //       let parsedPrimaryValue = this.parseFunction(item.value);
    //       this.milkCode = parsedPrimaryValue.value;
    //       this.milkCode == 0 ? (this.is_milk = false) : (this.is_milk = true);
    //     }
    //   });
    // }
  }

  // getPreviousTranscation() {
  //   let mobile_no = sessionStorage.getItem('mobileNo');
  //   let getAuthKey = sessionStorage.getItem('authToken');
  //   // let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  //   // let endOfMonth   = moment().clone().endOf('month').add(1, 'days').format('YYYY-MM-DD');

  //   let curr_date = new Date();
  //   let start_date = moment(curr_date).subtract(31, 'days');
  //   let startOfMonth = moment(start_date).format('YYYY-MM-DD');

  //   let addtoCurrDate = moment(curr_date).add(1, 'days');
  //   let endOfMonth = moment()
  //     .clone()
  //     .endOf('month')
  //     .add(1, 'day')
  //     .format('YYYY-MM-DD');

  //   this.spinner.show();
  //   this.apiService
  //     .getTransaction(mobile_no, getAuthKey, startOfMonth, endOfMonth)
  //     .pipe(first())
  //     .subscribe((res) => {
  //       console.log('ll');
  //       this.spinner.hide();
  //       let custom_fields: any;
  //       let value: any;
  //       let result = res['customer']['transactions'];
  //       let transcation = result['transaction'];
  //       if (transcation) {
  //         custom_fields = transcation.map((val) => {
  //           value = val.custom_fields['field'];
  //           value.push({ name: 'transaction_id', value: val.number });
  //           return val.custom_fields['field'];
  //         });
  //       }
  //       if (custom_fields) {
  //         this.getTransactionCount(custom_fields);
  //       }
  //     });
  // }

  global_non_redemmed_grow: any = [];
  // getTransactionCount(custom_fields1) {
  //   this.global_non_redemmed_grow = [];
  //   let non_redeemed_grow = [];
  //   let non_redeemed_similac = [];
  //   let nonmilk_similac_array = [];
  //   let nonmilk_grow_array = [];
  //   let all_nonmilk_array = [];

  //   custom_fields1.filter((custom_fields) => {
  //     let a = custom_fields.findIndex((val) => val.name == 'is_grow');
  //     let b = custom_fields.findIndex((val) => val.name == 'is_milk');
  //     if (a > b) {
  //       let temp = custom_fields[a];
  //       custom_fields[a] = custom_fields[b];
  //       custom_fields[b] = temp;
  //     }
  //     let c = custom_fields.findIndex((val) => val.name == 'is_redeemed');
  //     let d = custom_fields.findIndex((val) => val.name == 'is_milk');
  //     if (c > d) {
  //       let temp = custom_fields[c];
  //       custom_fields[c] = custom_fields[d];
  //       custom_fields[d] = temp;
  //     }

  //     custom_fields.filter((val) => {
  //       if (val.name == 'is_grow') {
  //         val.value == 'true'
  //           ? (this.custom_isGrow = true)
  //           : (this.custom_isGrow = false);
  //       }

  //       if (val.name == 'expiration_date') {
  //         this.scannedDate < val.value
  //           ? (this.is_not_expired = true)
  //           : (this.is_not_expired = false);
  //       }

  //       if (val.name == 'is_eligible_for_game') {
  //         val.value == 'true'
  //           ? (this.game_is_eligible = true)
  //           : (this.game_is_eligible = false);
  //       }

  //       if (val.name == 'is_redeemed') {
  //         val.value == 'true'
  //           ? (this.redemmed = true)
  //           : (this.redemmed = false);
  //       }

  //       if (val.name == 'is_milk' && val.value == 'false') {
  //         all_nonmilk_array.push(custom_fields);
  //         if (this.custom_isGrow) {
  //           nonmilk_grow_array.push(custom_fields);
  //         } else {
  //           nonmilk_similac_array.push(custom_fields);
  //         }
  //         if (
  //           this.custom_isGrow &&
  //           this.is_not_expired &&
  //           this.redemmed == false &&
  //           this.game_is_eligible
  //         ) {
  //           non_redeemed_grow.push(custom_fields);
  //         }
  //         if (
  //           this.custom_isGrow == false &&
  //           this.is_not_expired &&
  //           this.redemmed == false &&
  //           this.game_is_eligible
  //         ) {
  //           non_redeemed_similac.push(custom_fields);
  //         }
  //       }
  //     });

  //     let array_copy = [...non_redeemed_grow];

  //     var sortedArray = array_copy.sort(function (a, b) {
  //       if (
  //         a[5]?.name == 'expiration_date' &&
  //         b[5]?.name == 'expiration_date'
  //       ) {
  //         return +new Date(a[5].value) - +new Date(b[5].value);
  //       } else if (
  //         a[6]?.name == 'expiration_date' &&
  //         b[6]?.name == 'expiration_date'
  //       ) {
  //         return +new Date(a[6].value) - +new Date(b[6].value);
  //       } else if (
  //         a[7]?.name == 'expiration_date' &&
  //         b[7]?.name == 'expiration_date'
  //       ) {
  //         return +new Date(a[7].value) - +new Date(b[7].value);
  //       }
  //     });

  //     this.global_non_redemmed_grow = sortedArray;

  //     sessionStorage.setItem(
  //       'grow_chances',
  //       JSON.stringify(non_redeemed_grow.length)
  //     );
  //     sessionStorage.setItem(
  //       'similac_chances',
  //       JSON.stringify(non_redeemed_similac.length)
  //     );

  //     console.log(
  //       this.global_non_redemmed_grow,
  //       'this.global_non_redemmed_grow----'
  //     );
  //     console.log(nonmilk_grow_array, 'nonmilk_grow_array');
  //     console.log(nonmilk_similac_array, 'nonmilk_similac_array');

  //     let all_nonmilk_count = all_nonmilk_array.length; //given final count of non-milk(both grow and similac)
  //     let grow_nonmilk_count = nonmilk_grow_array.length; // gives final count non-milk grow
  //     let similac_nonmilk_count = nonmilk_similac_array.length; // gives final count non-milk similac

  //     // sessionStorage.setItem('similacMilCount', JSON.stringify(similac_nonmilk_count));
  //     // sessionStorage.setItem('growMilkCount', JSON.stringify(grow_nonmilk_count));

  //     let remain_nonmilk_grow_count = 5 - grow_nonmilk_count;
  //     let remain_nonmilk_similac_count = 5 - similac_nonmilk_count;

  //     // sessionStorage.setItem('grow_nonmilk_remaining_count', remain_nonmilk_grow_count.toString());
  //     // sessionStorage.setItem('similac_nonmilk_remaining_count', remain_nonmilk_similac_count.toString());

  //     all_nonmilk_count <= 5 ? (this.eligible = true) : (this.eligible = false);
  //     grow_nonmilk_count <= 5
  //       ? (this.eligible_nonmilk_grow = true)
  //       : (this.eligible_nonmilk_grow = false);
  //     similac_nonmilk_count <= 5
  //       ? (this.eligible_nonmilk_similac = true)
  //       : (this.eligible_nonmilk_similac = false);
  //   });
  // }

  updateTransactions() {
    if (this.global_non_redemmed_grow.length > 0) {
      let val = this.global_non_redemmed_grow[0].length;
      let id = this.global_non_redemmed_grow[0][val - 1].value;
      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken');
      let productName = sessionStorage.getItem('productName');
      let formData = {
        transaction_number: id,
        mobile: '84' + getMobile,
        custom_fields: [
          {
            name: 'is_redeemed',
            value: true,
          },
          {
            name: 'gift_id',
            value: this.user_gift != 'P-00000' ? this.user_gift : 'P-00000',
          },
          {
            name: 'gift_delivery_date',
            value: this.gift_delivery_date,
          },
        ],
      };
      this.spinner.show();
      console.log(formData, 'upadte form data');
      let updatetrans;
      this.apiService
        .updateTrans(formData, getAuthKey)
        .pipe(first()) //make this update transaction
        .subscribe((res) => {
          updatetrans = res;
          //this.getPreviousTranscation();
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.bsService.notifyError(err, formData);
        });
    } else {
      console.log('no trasaction available----');
    }
  }

  getGameId() {
    let gameid;
    this.apiService.sttarterLuckyDrawGameId().subscribe((res: any) => {
      gameid = res;
      let brandId = res.data.filter(
        (brandid) => brandid.gamebrand == 'BrandId'
      );
      this.gameId = brandId[0].gameid;
      console.log("game Id: ", this.gameId);
      // let grownonmilk = res.data.filter(
      //   (grownonmilk) => grownonmilk.gamebrand == 'Grow Non-Milk'
      // );
      // this.growNonMilk = grownonmilk[0].gameid;

      let pediasure = res.data.filter(
        (pediasure) => pediasure.gamebrand == 'Pediasure'
      );
      this.pediasure = pediasure[0].gameid

      // let SimilacMom = res.data.filter(
      //   (SimilacMom) => SimilacMom.gamebrand == 'Similac Mom'
      // );
      // this.SimilacMom = SimilacMom[0].gameid;

      // let SimilacS4 = res.data.filter(
      //   (SimilacS4) => SimilacS4.gamebrand == 'Similac S4'
      // );
      // this.SimilacS4 = SimilacS4[0].gameid;
    }, err => {
      this.spinner.hide();
      this.bsService.notifyError(err, gameid);
    });
  }

  user_gift: any;

  gameAllocation() {
    console.log('llll', this.giftValues);
    let mobileNo = sessionStorage.getItem('mobileNo');
    let authToken = sessionStorage.getItem('authToken');
    let formValue = {
      "root": {
        "redeem": [{
          "points_redeemed": env.POINTS_REDEEMED,
          "customer": {
            "mobile": mobileNo
          }
        }]
      }
    }
    let formdata = {
      brandId: this.gameId,
      gameId: this.pediasure,
      mobile: parseInt(mobileNo),
      redeemData: formValue
    };
    this.spinner.show();
    this.apiService.newGamePlay(formdata, mobileNo, authToken).subscribe((res: any) => {
      this.spinner.hide();
      this.pointUpdate = false
      if (res?.result?.code == 200) {

        console.log(res, 'game api response')
        let actual_gift_code = res?.result?.actual_gift_code;
        this.user_gift = actual_gift_code?.gift_id;
        this.showSuccessMdl = true;
        let giftId = this.giftValues.filter((val) => {
          if (val.value == actual_gift_code.gift_id) {
            return val.id;
          } else {
            console.log('no value matched--');
          }
        });
        this.getCustomer()
        let stopAt = this.theWheel.getRandomForSegment(giftId[0].id);
       
        
        this.theWheel.animation.stopAngle = stopAt;
        setTimeout(() => {
          this.theWheel.startAnimation();
        }, 1000)

        if (
          this.global_non_redemmed_grow.length > 0
        ) {
          this.startSpin();
        }
        this.couponCode = actual_gift_code?.couponCode
        this.expiry = actual_gift_code?.validate

        

        let gamedetails = {}
        gamedetails['productcode'] = this.user_gift
        gamedetails['name'] = actual_gift_code?.gift_name
        gamedetails['giftimage'] = actual_gift_code?.gift_image

        if (this.user_gift) {
          this.sttrData = gamedetails;
        }

      }

    }, err => {
      this.spinner.hide();
      if (err?.error?.code == 500) {
        console.log("item_status =>", err?.error?.message?.points?.item_status)
        if(err?.error?.message?.points?.item_status?.code==19223){
          this.apiService.showToasterMsg("Points cannot be redeemed as a part of the available points are not available or has been expired")
  
        }else{
          console.log("err =>", err?.error?.message?.status.message);
          let errormessage = err?.error?.message?.status?.message ? err?.error?.message?.status.message : "Something went wrong, please try again"
  
          this.apiService.showToaster(errormessage)
        }
        // if (
        //    err?.error?.errorcode == 400 ||
        //    err?.error?.errorcode == 600 ||
        //    err?.error?.errorcode == 700 ||
        //    err?.error?.errorcode == 701 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Đã xảy ra lỗi. Vui lòng thử lại sau'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 1001 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Bạn đã đạt đến giới hạn trò chơi trong tháng, vui lòng thử lại vào tháng sau.'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 401 ||
        //    err?.error?.errorcode == 801 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Vui lòng đăng nhập lại và thử lại.'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 601 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Đã xảy ra lỗi trong lúc quay, vui lòng thử lại sau.'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 802 ||
        //    err?.error?.errorcode == 803 ||
        //    err?.error?.errorcode == 804 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Bạn không đủ điều kiện để tham gia. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 900 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Vòng quay chưa sẵn sàng. Vui lòng liên hệ bộ phận hỗ trợ để biết thêm chi tiết'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 1000 || err?.error?.errorcode == 5503 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Trò chơi đã kết thúc trong tháng này. Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm chi tiết.'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 5402 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Vật phẩm trong trò chơi đã hết, trò chơi kết thúc.'
        //    );
        //  } else if (
        //    err?.error?.errorcode == 5500 
        //  ) {
        //    this.spinner.hide();
        //    this.apiService.showToaster(
        //      'Ngoại lệ trong khi đáp ứng yêu cầu Chơi trò chơi'
        //    );
        //  }else{
        //   this.apiService.showToaster('Something went wrong, please try again')
        //  }
      } else {
        this.apiService.showToaster('Something went wrong, please try again')
      }
      console.log(err, 'error')
      this.bsService.notifyError(err);
    })


  }

  openRewardPopup() {
    this.successModal.show();
  }
  rewardModalHide() {
    this.rewardModal.hide();
    this.updateTransactions();
  }

  openbetterLuckModal() {
    this.betterLuckNextTime.show();
  }

  closeSuccessModal() {
    this.successModal.hide();
    let detail = {
      expiry: this.expiry,
      coupon: this.couponCode,
      detail: this.sttrData?.name
    }
    this.router.navigate(['/e-redeem', detail])
    // //this.router.navigate(['/rewards/rewards-success']);
  }
}
