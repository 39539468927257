<app-header></app-header>
<section class="globalContainer px15 pt70 pb70">
    <section class="processValidationContainer text-center">
    <img class="mt40" src="../../../assets/images/process-validation.png">

    <h4 class="mt30">Validasi Dalam Proses</h4>
    <p class="mt10 validationContent">Terima kasih telah melakukan validasi. Mohon tunggu 2x24 jam untuk mengetahui hasilnya</p>
    </section>
    <button class="btn btnPurple br10 btn-block mt40 btn-50" routerLink="/profile">Kembali ke Profil</button>
</section>
<app-footer></app-footer>
