import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { BugsnagService } from 'src/app/service/bugsnag.service';
@Component({
  selector: 'app-my-points',
  templateUrl: './my-points.component.html',
  styleUrls: ['./my-points.component.scss'],
})
export class MyPointsComponent implements OnInit {
  @ViewChild('receiptUploadTerms', { static: true })
  receiptUploadTerms: ModalDirective;
  pointsList: any;
  findDay: string;
  lastTranasction: string;
  productList = [];
  prductCode: any;
  tableSize: any;
  totalLength: any;
  marvelRewards = []
  page: number = 1;
  giftTransactions = []
  giftCode: any;
  status: any;
  redeemRewards: any;

  pointData: any;
  rewardDetail
  voucherList
  gamePlayList: any[] = [
  ]

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService, 
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
    ) {
    window.scrollTo({ top: 0 })
  }


  ngOnInit(): void {
    this.status = this.route.snapshot.params.status;
    console.log("status: ", this.status)
    this.getAllPoints();
    this.getRedemtionDetail();
    this.getGameDetail();
    this.getRewardList();
    this.getRedemtionDetailFinal();
  }

  opencamera() {
    let data = {
      data: this.prductCode
    }
    this.router.navigate(['/camera', data]);
  }

  openReceiptUploadPopup(productCode) {
    this.receiptUploadTerms.show();
    this.prductCode = productCode;
    console.log(this.prductCode, 'this.prductCode ')
  }

  getAllPoints() {
    let mobile = sessionStorage.getItem('mobileNo')
    let getprdlist;
    this.apiService.getProductsList(mobile).pipe(first())
      .subscribe(res => {
        getprdlist = res;
        this.pointsList = res['result'];
        this.totalLength = this.pointsList.length;

        this.pointsList.forEach((product: any) => {
          let productItem = {
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format('DD-MM-YYYY'),
            points: product.points,
            color: ''
          }
          if (productItem.status == "rejected") {
            productItem.color = '#CC3300'
          } else if (productItem.status == "pending") {
            productItem.points = 'Validasi'
            productItem.color = '#5A217A'
          }
          else if (productItem.status == "success") {
            productItem.color = '#60BB55'
          }
          else if (productItem.status == "approved") {
            productItem.color = '#60BB55'
          }
          else if (productItem.status == "awaiting") {
            productItem.color = '#EFBE35'
          }
          this.productList.push(productItem)
        }
        )
        this.productList = this.productList.reverse();
      }

      ), err => {
        this.spinner.hide();
        console.log(err, '=== customer update error =======');
        this.bsService.notifyError(err, mobile);
        console.log(err);
      }

    // this.productList = this.productList.sort((a, b) => {
    //   return moment(a.submissionDate).diff(b.submissionDate)
    // })
    // let token = sessionStorage.getItem('authToken')
    // this.spinner.show();
    // this.apiService.getPointsList(mobile, token).pipe(first())
    //   .subscribe((res: any) => {
    //     this.spinner.hide();
    //     this.pointsList = res?.customer?.transactions?.transaction
    //     console.log(this.pointsList)
    //     let billingTime = moment(this.pointsList[0]?.billing_time).format('DD/MM/YYYY');
    //     let today = new Date();
    //     let todayDate = moment(today).add(2.5, 'hours').format('DD/MM/YYYY');
    //     let yesterday1 = moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('DD/MM/YYYY');
    //     let yesterday2 = moment(today).add(2.5, 'hours').subtract(1, 'day').format('DD/MM/YYYY');
    //     if (billingTime == todayDate) {
    //       this.findDay = 'TODAY'
    //     } else if (yesterday1 == yesterday2) {
    //       this.findDay = 'YESTERDAY'
    //     } else {
    //       this.findDay = 'LAST TRANSACTION DONE BY'
    //     }

    //     console.log(moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('hh:mm, DD-MM-YY'), 'pointsList[0]?.billing_time')
    //     this.lastTranasction = moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('hh:mm, DD-MM-YY');
    //     console.log(this.lastTranasction, ' this.lastTranasction')

    //   })



  }

  onTableDataChange(event) {
    this.page = event;
    this.getAllPoints();
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAllPoints();
  }


  getRedemtionDetail() {
    let mobile = sessionStorage.getItem('mobileNo')
    let token = sessionStorage.getItem('authToken')

    this.spinner.show();
    let getmarwal;
    this.apiService.getMarvalVouchers(mobile, token).subscribe((res: any) => {
      this.spinner.hide();
      getmarwal = res;
      if (res['status'].code == 200) {
        // this.productList = this.productList.reverse();
        this.redeemRewards = res.userVouchers;
        console.log(this.redeemRewards, 'this.redeemRewards')


      }

    }, err => {
      this.spinner.hide();
      console.log(err, '=== customer update error =======');
      this.bsService.notifyError(err, mobile);
      console.log(err, 'err--')
    })

  }

  getGameDetail() {
    let mobile = sessionStorage.getItem('mobileNo')
    let token = sessionStorage.getItem('authToken')

    this.spinner.show();
    let getcoupons;
    this.spinner.show()
    this.apiService.getALlsttarterGetGameplayReward().subscribe((res) => {
      this.spinner.hide()
      if (res['status'] == 200) {
        let data = res['data']
        this.gamePlayList = data
        this.apiService.getCustomerCoupons(mobile, token).subscribe((res: any) => {
          this.spinner.hide();
          getcoupons = res;
          console.log(res, 'resp')
          if (res?.status?.code == 200) {
            this.marvelRewards = res?.customers?.customer[0]?.coupons?.coupon;
            this.marvelRewards.forEach(ele => {
              let createdAt = ele.created_date;
              let seriesName = ele.series_name.split(',');
              if (seriesName.includes(' G')) {
                let gift = seriesName[3]
                this.giftCode = gift.trim();
                if (this.giftCode != 'P-00000') {
                  console.log('gift code : ', this.giftCode)
                  this.gamePlayList.forEach((gamePlay: any) => {
                    if (this.giftCode == gamePlay.productcode) {
                      let obj = {
                        giftName: gamePlay.name,
                        code: this.giftCode,
                        giftPoints: 300,
                        issuedDate: moment(createdAt).format('DD/MMM/YYYY'),
                        giftImage: gamePlay.giftimage,
                        expiryDate: ele?.valid_till,
                        couponCode: ele?.code,
                        betterluck: 'false'
                      }
                      this.giftTransactions.push(obj);
                      console.log("obj: ", obj)
                    }
                  })
                } else {
                  let obj = {
                    giftName: seriesName[0],
                    code: 'P-00000',
                    giftPoints: 300,
                    issuedDate: moment(createdAt).format('DD/MMM/YYYY'),
                    giftImage: "../../../assets/images/anguish.png",
                    expiryDate: ele?.valid_till,
                    couponCode: ele?.code,
                    betterluck: 'true'
                  }
                  this.giftTransactions.push(obj)
                }
              }
            }
            )
            let r1 = res?.customers?.customer[0]?.coupons?.coupon;
            let r2 = r1.map(res => res.series_name.split(','));
            console.log(this.giftTransactions, 'gift gtransactions')

          }

        }, err => {
          this.spinner.hide();
          console.log(err, '=== customer update error =======');
          this.bsService.notifyError(err);
          console.log(err, 'err--')
        })

      } else {
        console.log("error in retewing the gift codes")
      }
    }, err => {
      this.bsService.notifyError(err, token);
      this.spinner.hide()
      console.log(err)
    })

  }

  sortDate(dateA, dateB, direction = 'asc') {
    const formats = ['DD-MM-YYYY']; // can be several
    return (moment(dateA, formats).isBefore(moment(dateB, formats)) ? -1
      : moment(dateA, formats).isAfter(moment(dateB, formats)) ? 1 : 0) * (direction === 'asc' ? 1 : -1)
  }


  getRewardList() {
    let mobile = sessionStorage.getItem('mobileNo')
    let token = sessionStorage.getItem('authToken')
    this.spinner.show();
    let marwalreward;
    this.apiService.getMarvalRewardDetails(mobile, token).pipe(first())
      .subscribe((reward: any) => {
        marwalreward = reward
        this.spinner.hide();
        this.rewardDetail = reward.rewardList;
      }, err => {
        this.spinner.hide();
        console.log(err, '=== customer update error =======');
        this.bsService.notifyError(err, token);
      })
  }

  getRedemtionDetailFinal() {
    let mobile = sessionStorage.getItem('mobileNo')
    let token = sessionStorage.getItem('authToken')
    this.spinner.show();
    let vouchers;
    this.apiService.getMarvalVouchers(mobile, token).subscribe((res: any) => {
      this.spinner.hide();
      vouchers = res;
      if (res['status'].code == 200) {
        console.log(res, 'respons')
        this.pointsList = res?.response?.customer?.redemptions?.points?.point;
        console.log(this.pointsList, 'this.pointsList')
        this.voucherList = res?.userVouchers;
        this.voucherList = this.voucherList.filter(ress => ress.rewardDetails != null)
        let voucherFilter = this.voucherList.forEach(voucherList => {
          let tierData = voucherList.rewardDetails.tier;
          //let tierData = 'ABC123';
          let pointFilter = this.pointsList.filter(point => {
            return point.notes === tierData
          })
          let rewardFilter = null;
          if (this.rewardDetail != null || this.rewardDetail != undefined) {
            rewardFilter = this.rewardDetail.filter(reward => {
              return reward.tier === tierData;
            })
          }
          let foundReward = null;
          if (rewardFilter != null && rewardFilter.length > 0) {
            foundReward = rewardFilter[0];
          }
          let tottal_points = 0;
          let quantity = 0;
          pointFilter.forEach(pointFilter => {
            tottal_points = tottal_points + pointFilter.points_redeemed;
            if (foundReward != null) {
              quantity = quantity + (pointFilter.points_redeemed / foundReward.intouchPoints)
            } else {
              quantity = voucherList.vouchers.length;
            }

          })
          let transDate = voucherList.vouchers[0].issued;

          voucherList['total_points'] = tottal_points;
          voucherList['quantity'] = quantity;
          //voucherList['displayDate'] = moment(transDate).add(2.5, 'hours').format('DD MMM yyyy');
          voucherList['displayDate'] = transDate;



        });
        setTimeout(() => {
          this.voucherList.sort((a, b) =>
            moment(a.issuedDate, 'DD-MM-YYYY').isBefore(moment(b.issuedDate, 'DD-MM-YYYY')) ? 1 : -1,
          )
          this.spinner.hide();
        }, 1000)
        console.log(this.voucherList, 'voucher list =========== ')

        console.log(voucherFilter, 'voucherFilter =========== ')

        // this.voucherList = this.voucherList.push(this.pointsList );
        // this.pointsList.forEach((element:any) => {
        //   this.pointData = element?.points_redeemed
        //   console.log( this.pointData, 'this.pointData')
        // });
      }
    }, err => {
      console.log(err, 'err--')
      this.spinner.hide();
      console.log(err, '=== customer update error =======');
      this.bsService.notifyError(err, token);
    })
  }
  // gotoReddem(data){
  //   this.router.navigate(['/e-redeem', {state: data}])
  // }

}
