import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment as env } from 'src/environments/environment';

import jsQR from 'jsqr';
import { BugsnagService } from 'src/app/service/bugsnag.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  showScanner: boolean = false;
  showData: boolean = true
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };

  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  latitude: string;
  longitude: string;
  confidence: string;
  constructor(
    public translate: TranslateService, 
    private router: Router, 
    private spinner: NgxSpinnerService, 
    private APIService: ApiService, 
    private bsService: BugsnagService
    ) {
    translate.addLangs(['en_US', 'my_MH']);
    translate.setDefaultLang('en_US');
    window.scrollTo({ top: 0 })

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en_US|my_MH/) ? browserLang : 'en_US');
  }

  ngOnInit(): void {
    this.getPosition();
  }

  getPosition() {
    this.APIService.getPosition().then(pos => {
      let lat = pos.lat
      let lang = pos.lng
      let confidence = pos.accuracy
      this.latitude = JSON.stringify(lat)
      this.longitude = JSON.stringify(lang)
      this.confidence = JSON.stringify(confidence);
    })
  }
  onClickScanner() {
    this.showScanner = true;
    this.showData = false;

    //this.hideScanner = true
    // this.showProfile = false;

    if (this.showScanner) {
      this.startVideo();
    }
  }
  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo();
    }
  }
  onLoginBtn() {
    this.router.navigate(['/landing']);
  }
  startVideo() {
    this.spinner.show();
    this.medias.video = { facingMode: 'environment', width: 100, height: 150 };
    navigator.mediaDevices
      .getUserMedia(this.medias)
      .then((localStream: MediaStream) => {
        this.spinner.hide();
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      })
      .catch((error) => {
        console.error(error);
        this.videoStart = false;
      });
  }
  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext(
      '2d'
    ) as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT);
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT);
    const code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert',
    });
    if (code) {
      let scannedCode = code.data
      this.spinner.show();
      this.APIService.productinfo(scannedCode, this.latitude, this.longitude, this.confidence).subscribe((res: any) => {
        this.spinner.hide();
        console.log(res, 'res isprint');

        if (res.status === 'success') {
          sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
          this.router.navigate(['/landing']);
        } else {
          alert('Please scan a valid QR code.');
        }
      }, err =>{
        if(env.production){
          let payload = {
            scannedCode,
            latitude: this.latitude, 
            longitude: this.longitude, 
            confidence: this.confidence
          }
          this.bsService.notifyError(err, payload);
       }
      })

      // let scannedCode = code.data
      // let getAuthKey = sessionStorage.getItem('authToken')
      // // window.location.href = scannedCode;
      // let token =  sessionStorage.getItem('authToken')
      // let mob = sessionStorage.getItem('mobileNo')
      // let sku = sessionStorage.getItem('sku')
      // // ------------------- On password validate success call add transcation api

      // this.apiService.getTraceability(scannedCode).subscribe((res: any) => {
      //   console.log(res, 'res-')
      //   console.log(res.result.data, 'res.result.data')
      //   if(res.result.data == undefined){
      //     alert('Scanned code is not valid Abbott QR code');
      //     this.showScanner = false;
      //     // window.location.href = scannedCode;
      //   }
      //   else{
      //     sessionStorage.setItem('iSprintData', JSON.stringify(res.result.data));
      //     this.scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
      //     console.log(this.scancodeData, 'iSprintData')

      //     if(res.result.code == '0'){
      //         if(res.result.data.uaidInfo.type == 1 || res.result.data.uaidInfo.type == 7){
      //         this.router.navigate(['/traceability-home']);
      //         }else if(res.result.data.uaidInfo.type == 2){
      //           this.apiService.getPosition().then(pos=>{
      //             let lat = pos.lat
      //             let lang = pos.lng
      //             let latitude = JSON.stringify(lat)
      //             let langitude = JSON.stringify(lang)

      //             let getMobile = sessionStorage.getItem('mobileNo')
      //             let getAuthKey = sessionStorage.getItem('authToken')
      //             let productName = sessionStorage.getItem('productName');
      //             this.scanCode = sessionStorage.getItem('scanCode');
      //             let addTransformData = {
      //                 "scan_code": this.scanCode,
      //                 "mobile": mob,
      //                 "cap_device_id": env.DEVICE_ID,
      //                 "custom_fields": [
      //                   {
      //                     "name": "translat",
      //                     "value": latitude
      //                   },
      //                   {
      //                     "name": "translong",
      //                     "value":langitude
      //                  },
      //                  {
      //                     "name": "productname",
      //                     "value": productName
      //                  },
      //                  { 
      //                   "name":"category_code_trans",
      //                   "value":this.categoryCode ? this.categoryCode : ''
      //                 },
      //                 { 
      //                   "name":"sfproductcode",
      //                   "value":sku
      //                 }
      //               ]
      //             }
      //             console.log(addTransformData, 'addTransformData--')
      //             this.spinner.show();
      //             this.apiService.addTransaction(addTransformData, token).pipe(first())
      //             .subscribe(res=>{
      //               this.spinner.hide();
      //               console.log(res, 'res transaction')
      //                 this.showScanner = false;
      //                 this.toggleVideoMedia();
      //                 this.getCustomer();
      //                 //Get Points API
      //                 let mobile = sessionStorage.getItem('mobileNo')
      //                 let token = sessionStorage.getItem('authToken')
      //                 this.spinner.show();
      //                 this.apiService.getPoints(mobile,token).pipe(first()).subscribe((res:any) => {
      //                   this.spinner.hide();
      //                   // this.router.navigate(['/home']);
      //                   console.log(res?.customer?.transactions?.transaction, 'points list')
      //                   this.pointsEarned = res?.customer?.transactions?.transaction[0]?.points?.issued;
      //                   this.onClickSuccessScanModal()
      //                 })


      //             },err=>{
      //               this.showScanner = false;
      //                 this.spinner.hide();
      //                 // this.apiService.showToaster(err);
      //                 console.log('err', err);
      //                 if(err){
      //                   this.onClickFailedScanModal();
      //                 }

      //                 this.toggleVideoMedia();

      //             })
      //         // }) end position api
      //           })         
      //  }
      //       }
      //       else{
      //         alert('Scanned code is not valid Abbott QR code')
      //       }

      //     }

      // },err=>{
      //         console.log(err, 'err========')
      // })

    }
  }
  closeScanner() {
    this.showScanner = false;
    this.showData = true;
  }
}
