import { SpinWheelRoutingModule } from './spin-wheel-routing.module';
import { SpinWheelComponent } from './spin-wheel/spin-wheel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TNCComponent } from './tnc/tnc.component';
import { InfoGameComponent } from './info-game/info-game.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [SpinWheelComponent, TNCComponent, InfoGameComponent],
  imports: [
    CommonModule,
    SpinWheelRoutingModule,
    SharedModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class SpinWheelModule {}
