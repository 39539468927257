<app-header></app-header>
<section class="globalContainer">
  <section class="profileContainer pt60 pb70">
    <div class="topProfileBlock px15 pb40">
      <div class="imgBlock" *ngIf="!finalUserImage">
        <img class="" src="../../../assets/images/avataruserbgdark.png" />
      </div>
      <div class="imgBlock" *ngIf="finalUserImage">
        <img class="" [src]="finalUserImage" />
      </div>
      <h4 class="userName mt-2">👋 Hi, {{ firstName }}</h4>
      <p class="level">Level {{ labelledMetal }}</p>
      <div class="pointsBlock">
        <img class="" src="../../../assets/images/points-icon.png" />
        <!-- {{ this.points }} -->
        {{ customerData?.loyalty_points }}
        Poin
      </div>

      <span class="pointsExpiry" *ngIf="showPointsExpiry"
        >{{ pointsExpired }} Poin akan kadaluwarsa pada
        {{ pointsExpiredDate }}</span
      >

      <div class="pointsRedeemHistoryBlock mt20 mb20">
        <div class="redeem px15" (click)="onClickScanQr()">
          <div class="imgOuter">
            <img class="" src="../../../assets/images/pointsscanner.png" />
          </div>
          <p class="mb-0">Scan untuk dapatkan Poin</p>
        </div>
        <div class="history px15" routerLink="/profile/my-points">
          <div class="imgOuter">
            <img class="" src="../../../assets/images/clock.png" />
          </div>
          <p class="mb-0">Lihat riwayat poin Anda</p>
        </div>
      </div>
      <div class="rewardsRedeemBlock mt-2" routerLink="/rewards">
        <img class="" src="../../../assets/images/purchaseicon.png" />
        <p class="mb-0">Tukar Hadiah</p>
        <img class="" src="../../../assets/images/arrowRightIcon.png" />
      </div>
    </div>
    <div class="bottomBlock px20 pt-4">
      <!-- <p><img class="" src="" />⭐️ Benefit Anggota Pemula</p> -->
      <!-- <div class="progressLevelBlock-bronze" *ngIf="labelledMetal == 'Bronze'">
        <div
          class="row h-100 align-items-center no-gutters"
          routerLink="/profile/level-member"
        >
          <div class="col-3 text-center">
            <img src="../../../assets/images/bronze-icon.png" />
          </div>
          <div class="col-8 mt-3">
            <p class="content mb-0">
              Naikkan level anggota untuk mendapatkan benefit lebih
            </p>
            <div class="mt-2 progressOut">
              <progressbar
                [max]="5000"
                [min]="0"
                [value]="points"
                type=""
                [striped]="true"
                [animate]="true"
                ><i></i>
              </progressbar>
            </div>
            <span class="content ml-2">{{ labelledMetal }}</span>
          </div>
          <div class="col-1 text-center">
            <img src="../../../assets/images/arrowrightblack.png" />
          </div>
        </div>
      </div> -->
      <div class="progressLevelBlock-New">
        <div
          class="row h-100 align-items-center no-gutters"
          routerLink="/profile/level-member"
        >
          <div class="col-4 text-center">
            <img *ngIf="labelledMetal == 'Bronze'" src="../../../assets/images/profile/bronze-profile-icon.svg" />
            <img *ngIf="labelledMetal == 'Silver'" src="../../../assets/images/profile/silver-profile-icon.svg" />
            <img *ngIf="labelledMetal == 'Gold'" src="../../../assets/images/profile/gold-profile-icon.svg" />
            <img *ngIf="labelledMetal == 'Platinum'" src="../../../assets/images/profile/platinum-profile-icon.svg" />
          </div>
          <div class="col-8 mt-3">
            <div class="right-sec">
              <p class="content mb-0">Level Anda saat ini</p>
              <h3 *ngIf="labelledMetal == 'Bronze'">Bronze</h3>
              <h3 *ngIf="labelledMetal == 'Silver'">Silver</h3>
              <h3 *ngIf="labelledMetal == 'Gold'">Gold</h3>
              <h3 *ngIf="labelledMetal == 'Platinum'">Platinum</h3>
            </div>
          </div>
          <div class="progressOut">
            <progressbar
              [max]="2000"
              [value]="loyaltyPoints"
              type=""
              [striped]="true"
              [animate]="true"
              ><i></i>
            </progressbar>
            <div class="badges-sec row ">
              <div class="col-6 p-0 left-sec d-flex justify-content-between">
              <div class="completed bucket"><p>Bronze <span>0 pts</span></p></div>
              <div class="bucket" [class.completed]="loyaltyPoints >= 500" ><p>Silver <span>500 pts</span></p></div>
              <div class="bucket" [class.completed]="loyaltyPoints >= 1000" ><p>Gold <span>1000 pts</span></p></div>
              </div>
              <div class="col-6 p-0 right-sec d-flex flex-row-reverse">
                <div class="bucket " [class.completed]="loyaltyPoints >= 2000"><p>Platinum <span><a class="text-decoration-underline">></a> 2000 pts</span></p></div>
              </div>
              
              
            </div>
          </div>
          <div class="points-button d-flex"  *ngIf="labelledMetal != 'Platinum'">
            <img src="../../../assets/images/profile/points-icon.svg" />
            <span *ngIf="labelledMetal == 'Bronze'">{{500-loyaltyPoints}} pts untuk level berikutnya</span>
            <span *ngIf="labelledMetal == 'Silver'">{{1000-loyaltyPoints}} pts untuk level berikutnya</span>
            <span *ngIf="labelledMetal == 'Gold'">{{2000-loyaltyPoints}} pts untuk level berikutnya</span>
            <!-- <span *ngIf="labelledMetal == 'Platinum'">{{2000-loyaltyPoints}} pts untuk level berikutnya</span> -->

          </div>
        </div>
      </div>

      <!-- <div class="progressLevelBlock-silver" *ngIf="labelledMetal == 'Silver'">
        <div
          class="row h-100 align-items-center no-gutters"
          routerLink="/profile/level-member"
        >
          <div class="col-3 text-center">
            <img src="../../../assets/images/silver-icon.png" />
          </div>
          <div class="col-8 mt-3">
            <p class="content mb-0">
              Naikkan level anggota untuk mendapatkan benefit lebih
            </p>
            <div class="mt-2 progressOut">
              <progressbar
                [max]="5000"
                [min]="0"
                [value]="points"
                type=""
                [striped]="true"
                [animate]="true"
                ><i></i>
              </progressbar>
            </div>
            <span class="content ml-2">{{ labelledMetal }}</span>
          </div>
          <div class="col-1 text-center">
            <img src="../../../assets/images/arrowrightblack.png" />
          </div>
        </div>
      </div> -->
      <!-- <div class="progressLevelBlock-gold" *ngIf="labelledMetal == 'Gold'">
        <div
          class="row h-100 align-items-center no-gutters"
          routerLink="/profile/level-member"
        >
          <div class="col-3 text-center">
            <img src="../../../assets/images/gold-icon.png" />
          </div>
          <div class="col-8 mt-3">
            <p class="content mb-0">
              Naikkan level anggota untuk mendapatkan benefit lebih
            </p>
            <div class="mt-2 progressOut">
              <progressbar
                [max]="5000"
                [min]="0"
                [value]="points"
                type=""
                [striped]="true"
                [animate]="true"
                ><i></i>
              </progressbar>
            </div>
            <span class="content ml-2">{{ labelledMetal }}</span>
          </div>
          <div class="col-1 text-center">
            <img src="../../../assets/images/arrowrightblack.png" />
          </div>
        </div>
      </div> -->
      <!-- <div
        class="progressLevelBlock-platinum"
        *ngIf="labelledMetal == 'Platinum'"
      >
        <div
          class="row h-100 align-items-center no-gutters"
          routerLink="/profile/level-member"
        >
          <div class="col-3 text-center">
            <img src="../../../assets/images/platinum-icon.png" />
          </div>
          <div class="col-8 mt-3">
            <p class="content mb-0">
              Naikkan level anggota untuk mendapatkan benefit lebih
            </p>
            <div class="mt-2 progressOut">
              <progressbar
                [max]="5000"
                [min]="0"
                [value]="points"
                type=""
                [striped]="true"
                [animate]="true"
                ><i></i>
              </progressbar>
            </div>
            <span class="content ml-2">{{ labelledMetal }}</span>
          </div>
          <div class="col-1 text-center">
            <img src="../../../assets/images/arrowrightblack.png" />
          </div>
        </div>
      </div> -->
      <ul class="listBlock">
        <!-- <li routerLink="/rewards/wishlist-product">
          <div class="row">
            <div class="col-10">
              <p>Hadiah Anda</p>
            </div>
            <div class="col-2 text-right">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li> -->
        <!-- [routerLink]="[
        '/receipt-details',
        { id: element.id, status: element.receipt_status }
      ]" -->
        <!-- <li [routerLink]="['/my-points', { status: 'pointProfile' }]">
          <div class="row">
            <div class="col-10">
              <p>Status Poin</p>
            </div>
            <div class="col-2 text-right list-link-icon">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>

        <li [routerLink]="['/my-points', { status: 'gameProfile' }]">
          <div class="row">
            <div class="col-10">
              <p>Reward Poin</p>
            </div>
            <div class="col-2 text-right list-link-icon">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li> -->

        <li routerLink="/edit-profile">
          <div class="row">
            <div class="col-10">
              <p>Ubah Profil</p>
            </div>
            <div class="col-2 text-right list-link-icon">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
        <li routerLink="/faq/loyalty">
          <div class="row">
            <div class="col-10">
              <p>Tentang Pediasure Loyalty Program</p>
            </div>
            <div class="col-2 text-right list-link-icon">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
        <li routerLink="/faq">
          <div class="row">
            <div class="col-10">
              <p>{{ "PROFILE.FAQ" | translate }}</p>
            </div>
            <div class="col-2 text-right list-link-icon">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
        <li routerLink="/faq/terms-conditions">
          <div class="row">
            <div class="col-10">
              <p>Syarat dan ketentuan</p>
            </div>
            <div class="col-2 text-right list-link-icon">
              <img class="" src="../../../assets/images/arrowrightblack.png" />
            </div>
          </div>
        </li>
        <li>
          <a href="https://pediasure.co.id/contact">
            <div class="row">
              <div class="col-10">
                <p>Hubungi kami</p>
              </div>
              <div class="col-2 text-right list-link-icon">
                <img
                  class=""
                  src="../../../assets/images/arrowrightblack.png"
                />
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div class="row mt40 mx-0 btnPurple br10 align-items-center logout-btn" (click)="logOut()">
        <div class="col-10 pl20">
          <p>Keluar</p>
        </div>
        <div class="col-2 text-right list-link-icon">
          <img class="" src="../../../assets/images/profile/logout-icon-profile.svg" />
        </div>
      </div>
    </div>
  </section>
</section>
<app-footer></app-footer>

<div
  class="modal fade customModalContainer px30 exceedLimitModal"
  id="exceedLimit"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <span class="closeIcon"><i class="mdi mdi-close"></i></span>
      <div class="modal-body pt-3 text-center">
        <img src="../../../assets/images/prod-warn.png" alt="warning" />
        <h2 class="pointsModalTilte fW600 text-center mt-2">Maaf...</h2>
        <p>
          Pembelian Anda sudah melebihi batasan kuota konsumsi si Kecil. Untuk
          keterangan lebih lanjut Anda bisa menghubungi tim Pediasure
        </p>
        <div class="row mt20">
          <div class="col text-left pr-2">
            <button
              class="btn btn btnPurple btnOutlinePurple fW600 btn-block br10 btn-50"
            >
              Hubungi Tim
            </button>
          </div>
          <div class="col text-right pl-2">
            <button class="btn btn btnPurple btn-block fW600 btn-50 br10">
              Oke
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  #profileNotCoomplete="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto mt-25vh">
    <div class="modal-content">
      <span class="closeIcon"><i class="mdi mdi-close"></i></span>
      <div class="modal-body pt-3 text-center">
        <img class="mt40" src="../../../assets/images/bell.png" />
        <h3 class="pointsModalTilte text-center mt20">
          Ayo lengkapi <br />data profil Anda
        </h3>
        <p class="greyContentSmall mt16 mb20">
          Agar lebih mudah untuk <br />menikmati fitur di <br />Pediasure
          Loyalty Program
        </p>

        <button
          type="button"
          class="my-2 sbtn btnPurple w-100 btn-50 br10 mb-3"
          routerLink="/edit-profile"
        >
          Lengkapi Sekarang
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  #completeProfileDataModel="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mt120 mx-auto">
    <div class="modal-content">
      <span class="closeIcon" (click)="closeCompleteProfileDataModal()"
        ><i class="mdi mdi-close"></i
      ></span>
      <div class="modal-body">
        <div class="text-center">
          <!-- <img class="" src="../../../assets/images/rewardpoints.png"> -->
          <h4>🔔</h4>
        </div>
        <div class="text-center px15">
          <h5 class="fs20">Ayo lengkapi data profil Anda</h5>

          <p>
            Agar lebih mudah untuk menikmati semua fitur di Pediasure Loyalty
            Program
          </p>
        </div>
        <button
          class="btn btnPurple br10 btn-block mt40"
          routerLink="/edit-profile"
        >
          Lengkapi Data Sekarang
        </button>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  #scanQrcodeGuideModal="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body pt-3">
        <div *ngIf="showData">
          <div class="row mb-3">
            <div class="col-2 text-left">
              <img
                class="backImg"
                (click)="closeScanInstMdl()"
                src="../../../assets/images/backicon.png"
              />
            </div>
            <div class="col-8 text-center">
              <p class="textBlack mt-2 mb-0 scan-title">Scan QR code</p>
            </div>
            <div class="col-2 text-right">
              <!-- <img class="" src="../../../assets/images/flashnew.png"> -->
            </div>
          </div>
          <div class="text-center px15">
            <h5 class="fs16">Panduan scan QR Code :</h5>
            <img
              class="br10 mt-3 w-100p"
              src="../../../assets/images/img0.png"
            />
            <p class="textPureBlack mt-2 mb-0">1.Scan pada kaleng</p>
            <img
              class="br10 mt-3 w-100p"
              src="../../../assets/images/img1.png"
            />
            <p class="textPureBlack mt-2">2. Scan pada box</p>
          </div>
          <button
            class="btn btnPurple br10 btn-block mt-4"
            (click)="onClickScanner()"
          >
            Oke
          </button>
        </div>
        <div [hidden]="!showScanner">
          <div class="row mb-3">
            <div class="col-2 text-left">
              <img
                class="backImg"
                (click)="closeScanner()"
                src="../../../assets/images/backicon.png"
              />
            </div>
            <div class="col-8 text-center">
              <p class="textBlack mt-2 mb-0 scan-title">Scan QR code</p>
            </div>
            <div class="col-2 text-right">
              <!-- <img class="" src="../../../assets/images/flashnew.png"> -->
            </div>
          </div>
          <!-- <div class="video-row" fxFlex="100">
            <video
              width="100%"
              class="video"
              #video
              autoplay
              playsinline
            ></video>
          </div>
          <div fxFlex="100" style="display: none">
            <canvas class="video" id="canvas" #canvas></canvas>
          </div> -->

          <section id="demo-content" *ngIf="showScanner">
           
            <div>
              <video id="video" width="100%" height="100%"></video>
            </div>
            <!-- <label>Result:</label>
              <pre><code id="result"></code></pre> -->
          </section>

          <!-- <button class="btn d-block m-auto btnPurple" (click)="closeScanner()">back</button> -->

          <p class="textPurple mt-2 text-center">
            Arahkan kamera ke QR Code produk Pediasure, Pastikan QR Code
            terlihat dengan jelas agar dapat diproses.
          </p>
          <!-- <p class="textPurple mt-2 text-center">Sedang memproses QR Code</p> -->
          <!-- <div class="successMessage">
            <div class="row align-items-center justify-content-center h-100">
              <div class="col-8">
                <p class="mb-0 text-white"> QR Code berhasil di-scan</p>
              </div>
              <div class="col-2">
                <img class="" src="../../../assets/images/tickmarkwhite.png">
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
